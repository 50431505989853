import React, { useEffect, useRef, useState } from 'react'
import classes from './AT01.module.scss'
import mepi from '../../assets/AT01-images/Group 83.png'
import logo from '../../assets/AT01-images/Group 84.png'
import qrCode  from '../../assets/AT01-images/qrcode.png'
import singateur from '../../assets/AT01-images/signature.png'
import { Button, Input } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useSelector } from 'react-redux'
import QRCode from "react-qr-code";
import url from '../../url'
import moment from 'moment'
import urlUi from '../../urlUi'

function AT01ByBrenco(props) {

	const data = props?.data
	const isStepTwo = props?.isStepTwo
    const certficate = useSelector(state => state.certificate.certificates)
    const documentType = useSelector(state => state.certificate.documentType)
	const ref = useRef()
	

	const logoCert = certficate?.uploadedImage?.split(',')[0]
	const singateurCert = certficate?.uploadedImage?.split(' , ')[1]

	const [dataSingatire , setData ] = useState({
		nomSingataire: '' || certficate.nomSingataire,
		fonctionSignataire:'' || certficate.fonctionSignataire
	})

	console.log(dataSingatire);

	useEffect(()=>{
		if (props.isStepTwo) {
			props.dataSingatire(dataSingatire)
		}
	},[props.isStepTwo , dataSingatire])



	let time = documentType?.duree
		time = time?.split(' ')
	
	const [fileLogoUpload , setFileLogoUpload] = useState(null)
	const [fileSignateurUpload , setFileSignateurUpload] = useState(null)
	
	const [fileLogo , setFileLogo] = useState(null)
	const [fileSignateur , setFileSignateur] = useState(null)

	const handelLogo = (e)=> {
		if (isStepTwo) {
			setFileLogoUpload(e.target.files[0])
			setFileLogo(URL.createObjectURL(e.target.files[0]))
		}
		
	}

	const handelFileSignateur = (e)=> {
			if (isStepTwo) {
			setFileSignateurUpload(e.target.files[0])
			setFileSignateur(URL.createObjectURL(e.target.files[0]))
			}
	}


	useEffect(()=>{
		if (isStepTwo) {
		props.sendImages([fileLogoUpload , fileSignateurUpload])
		}
	},[ fileLogoUpload,fileSignateurUpload])


	const dataHandler = (e)=> {
		const {name , value} = e.target
		setData(oldValue => {
			return {
				...oldValue,
				[name]:value
			}
		})
	}

  return (
	  
    <div className={classes.body} >
		{isStepTwo && <div className={classes.uploadFile} >
			<div className={classes.upload}>
				<div onChange={handelLogo}>
				<label>Logo <UploadFileIcon/> </label>
					<p style={{fontSize:"0.6rem"}}> {fileLogoUpload?.name} </p>
					<label htmlFor="contained-button-file">
        			<Input className={classes.input} id="contained-button-file" name='images'  type="file" />
        			<Button variant="contained" component="span">
          			Upload
        			</Button>
      				</label>
				</div>
				<div onChange={handelFileSignateur} >
				<label>Signature <UploadFileIcon/> </label>
					<p style={{fontSize:"0.6rem"}} >{fileSignateurUpload?.name}</p>
					<label htmlFor="contained-button-file2">
        			<input className={classes.input}  id="contained-button-file2"  name='images' type="file" />
        			<Button variant="contained" component="span">
          			Upload
        			</Button>
      				</label>
				</div>
			</div>
			<div onChange={dataHandler} className={classes.inputs} >
			<div>
				<label> Nom de signataire </label>
				<Input value={dataSingatire.nomSingataire}  name='nomSingataire' className={classes.Input} />
			</div>
			<div>
				<label> Fonction du signataire + Organisation </label>
				<Input value={dataSingatire.fonctionSignataire} name='fonctionSignataire' className={classes.Input}/>
			</div>
			</div>
		</div>}
      <div className={classes.container}>
      <div ref={props.forwardedRef}  className={classes.modelContainer}>
			<div className={classes.toprightillustration}>
				<span>Attestation</span>
				<span>de formation</span>
				<div>
					<img src={logo} alt="logo" />
				</div>

				<svg
					viewBox="0 0 209 426"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M208.91 384.17L104.72 425.08L0.52002 384.17L14.93 0H194.5L208.91 384.17Z"
						fill="#9EBC1D"
					/>
				</svg>

				<svg
					viewBox="0 0 188 426"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M187.16 374.73L93.72 425.08L0.26001 374.73L8.98999 0H178.44L187.16 374.73Z"
						fill="#283379"
					/>
				</svg>
			</div>

			<header>
				<div class="logo-1-container">
					<img class="template_logo" id="template_logo" src={isStepTwo ? fileLogo ? fileLogo : mepi : (certficate?.uploadedImage && url+'/images/img/'+logoCert) || mepi} alt="Logo 1" height="100px" />
				</div>
			</header>

			<main>
				<h2><span class="date_fin">{isStepTwo ? props.data.date_fin : (documentType && moment(documentType.date_fin).format('YYYY-MM-DD')) || 'date de fin'}</span>,</h2>
				<h1><span class="nom">{isStepTwo ? data.nom ? data.nom : 'nom' : (documentType && documentType.nom) ||'nom'}</span> <span class="prenom">{isStepTwo ? data.prenom ? data.prenom :'prenom' : (documentType && documentType.prenom) || 'prenom'}</span></h1>
				<h3>A validé avec succès la formation</h3>
				<p>
					Ce certificat atteste que <span class="genre">{isStepTwo ? props.gender?.value ? props.gender?.value : 'Mr' : (documentType && documentType.genre) ||'Mr'}</span> <span class="nom">{isStepTwo ? data.nom ? data.nom : ' nom ' : (documentType && documentType.nom) || 'nom'}</span> <span class="prenom">{ isStepTwo ? data.prenom ? data.prenom :' prenom ' : (documentType && documentType.prenom) || 'prenom'}</span> 
					&nbsp; a suivi avec succès le training <span class="nom_formation"> {isStepTwo ? props.data.nom_formation ? props.data.nom_formation :'nom_formation' : (documentType && documentType.nom_formation) || 'nom formation'} </span> 
					organisé par <span class="nom_organisme">{isStepTwo ? props.data.nom_organisme ? props.data.nom_organisme : 'nom_organisme' : (documentType && documentType.nom_organisme) || 'nom organisation'}</span> pendant <span class="duree"></span> et 
					qui s'est terminée le <span class="date_fin">{isStepTwo ? data.date_fin ? data.date_fin : 'date de fin' : (documentType && moment(documentType.date_fin).format('YYYY-MM-DD')) || 'date de fin'}</span>, tel qu'enseigné par <span class="nom_formateur">{isStepTwo ? props.data.nom_formateur ? props.data.nom_formateur : 'nom_formateur' : (documentType && documentType.nom_formateur) || 'nom de formateur'}</span>. 
				</p>
				<p>
					Ce certificat indique que le training dans sa totalité 
					a été complété et validé par l'apprenant.
				</p>
			</main>

			<footer>
				<div></div>
				<div className={classes.signature}>
					<img id="template_signature" class="template_signature" src={isStepTwo ? fileSignateur ? fileSignateur : singateur : (certficate?.uploadedImage && url+'/images/img/'+singateurCert) ||  singateur} width="250px" alt="Signature"/>
					<div></div>
					<span class="template_signataire" id="template_signataire">{dataSingatire.nomSingataire || 'BROURI KARIM'}</span>
					<span class="template_role_company" id="template_role_company">{dataSingatire.fonctionSignataire || 'CEO & Founder, Brenco'} </span>
				</div>
				<div className={classes.qrcode}>
					<div>
						<QRCode  value={urlUi+'/certificates/'+certficate?.certificateId} />
					</div>
					<span>SCAN LE QR CODE POUR VÉRIFIER CETTE ATTESTATION</span>
				</div>
			</footer>

			<div className={classes.bottomillustration}>
				<svg
					viewBox="0 0 843 50"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M842.1 49.2799V43.7198L13.77 0.649902L0 49.2799H842.1Z"
						fill="#9EBC1D"
					/>
				</svg>
				<svg
					viewBox="0 0 826 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M825.1 27.2799V26.9099L10.7101 0.629883L0.350098 27.2799H825.1Z"
						fill="#283379"
					/>
				</svg>
			</div>
		</div>
      </div>
    </div>
  )
}

export default AT01ByBrenco