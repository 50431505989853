import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Background from '../../../elements/background-login/Background';
import Button from '../../../elements/button/Button';
import FullLoading from '../../../elements/full-loading-page/FullLoading';
import InputForm from '../../../elements/inputForm/InputForm';
import { authActions } from '../../../store/auth/authSlice';
import classes from './Steps.module.scss'
import { checkPassword } from '../../../helper';

function StepSeven({ password, confirmPassword , submitData , RenderToast}) {

  const dispatch = useDispatch()
  const willCreate = useSelector(state => state.auth.willCreate)
  const isLoading = useSelector(state => state.auth.isLoading)
  const safetyCheck = () => {
    if (checkPassword(password.value, confirmPassword.value)) {
      submitData()
    }
  }
  const previous = ()=>{
    if (willCreate) {
    dispatch(authActions.switchStep(6))
    } else{
    dispatch(authActions.switchStep(4))
    }
  }

  return ( <div className={classes.SignUpSteps}>
    {isLoading && <FullLoading/>}
    <Background/>
  <div className={classes.SignUp} >
    <div className={classes.SignUpBox} >
      <div className={classes.h2}  >
      <h2  >
    Veuillez choisir un mot de passe pour votre compte
  </h2>
      </div>
    <div  className={classes.signUpForm}>
    <div className={classes.inputBox} >
    <InputForm title="Mot de passe:" name='password'  {...password}  required={true} />
    <InputForm  title="Confirmer mot de passe:" name='confirmPassword' {...confirmPassword}   required={true} />
    <label>Si vous êtes déja inscrit sur cette plateforme, entrez votre mot de passe actuel</label>
  </div>
    <div className={classes.button}>
    <Button onClick={safetyCheck} textColor={'white'}  color={'#0071BC'} > Continuer</Button> 
    <Button onClick={previous} textColor={'white'}   color={'#343a40'} > Retour </Button>
    </div>
    </div>
    </div>
  </div>
  </div> );
}

export default StepSeven;
