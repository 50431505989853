import React from 'react'
import classes from './ButtonLists.module.scss'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';


function ListeDesAdminButton(props) {
  
  const deleteAdmin = ()=>{

    if (isNaN(props.id)) {
      return 
    } else{
      props.openCheckPassword(props.id)
    }

  }
  return (

    <div className={classes.button}>
       <IconButton onClick={() =>  props.OpenMenu(props.id)} >  <EditIcon  className={classes.color}/> </IconButton>
        <IconButton onClick={deleteAdmin} > <DeleteIcon className={classes.color} /> </IconButton>
    </div>
  )
}

export default ListeDesAdminButton