import { Label } from '@material-ui/icons'
import { Button, Input } from '@mui/material'
import React, { useState , useEffect } from 'react'
import { Link } from 'react-router-dom'
import classes from './Steps.module.scss'
import url from '../../../url'
import { useDispatch , useSelector} from 'react-redux'
import readXlsxFile from 'read-excel-file'
import { addMultipleCertifcates, getDocumentsData } from '../../../store/certficate/certficateActions'

let Newdata = [] 

function StepThree(props) {

  const orgInfo = useSelector(state => state.user.org)
  const typeDocument = props.typeDocument
  const ExperyDate = props.ExperyDate
  const dispatch = useDispatch()
  const data = props.data
  const [file , setFile] = useState(null)
  const [openUpload , setOpenUpload] = useState(false)
  const DocumentsData = useSelector(state => state.certificate.getDocumentsData)
  const selectedUserIds = useSelector(state => state.certificate.selectedUserIds)

  console.log(selectedUserIds);

  console.log(data);

  useEffect(()=>{

    dispatch(getDocumentsData())
    
  },[dispatch])
  const fileHandler = (e) => {
    setFile(e.target.files[0])
  }

  const downloadExcel = ()=>{
    setOpenUpload(oldValue => !oldValue)
  }

  const downloadByType = ()=> {
    switch (typeDocument) {
      case 'AT01':
        return 'model-AT01.xls';
        case 'AT02':
          return "model-AT02.xls";
          case 'AT03':
            return 'model-AT03.xls';
            case 'AT04':
              return 'model-AT04.xls';
              case 'CR01':
                return "model-CR01.xls";
                case 'IN01':
                  return 'model-IN01.xls';
                  case 'CT01':
                    return "model-AT05.xls";
                  case 'AT05':
                    return "model-CT01.xls";
                  
      default:
        break;
    }
  }

  

  console.log(typeDocument);

  useEffect(()=>{

    if(file != null){
    readXlsxFile(file).then(rows =>{
 
      const filtredDocuments = DocumentsData.find(el => el.id === typeDocument )
      console.log(typeDocument);
      console.log(filtredDocuments);
      let persone = {}

      for (let i = 1; i < rows.length; i++) {
          for (let j = 0; j < rows[i].length; j++) {
            const element = rows[j];
            for (let x = 0; x < filtredDocuments.form.length; x++) {
              if (filtredDocuments.form[x].placeholder === rows[0][j]) {
                  rows[0][j] = filtredDocuments.form[x].id
              }
              persone[rows[0][j]] = rows[i][j]
              persone = {...persone , ...data }
              
            }
          }
         Newdata.push(persone)
         persone = {}
      }
    
    })
    }
  },[file , readXlsxFile])


  console.log(Newdata);

  const createCertficiates = ()=>{
    dispatch(addMultipleCertifcates(Newdata , orgInfo.orgKey , typeDocument , selectedUserIds , ExperyDate))
  }





  return (
    <div  className={classes.add}>
      <div className={classes.excelBox}>
      <div className={classes.title} ><h3>Saisir les données</h3></div>
      <div className={classes.downloadExcel}>
          <p> 1- Veuillez télécharger le modèle Excel et copier vos données
                      (Ne modifiez pas la première ligne !) </p>
      </div>
      <a target={'_self'} href={url+'/excel/model/'+downloadByType()} className={classes.downloadBtn}>
        <Button onClick={downloadExcel} className={classes.btn} >Télécharger modéle</Button>
      </a>
    <>
      <div className={classes.downloadExcel}>
          <p> 2- Veuillez uploader le fichier Excel aprés l'avoir mis à jour</p>
      </div>
      <div className={classes.downloadBtn}>
        <label htmlFor='select'> uploader </label>
        <Input onChange={fileHandler} name='file' style={{display:"none"}} id='select' type='file' />
      </div>
      <p> {file?.name || ''}</p>
      <div className={classes.button} >
             <Button onClick={createCertficiates} className={classes.MatrialUiBtnbutton} >Suivant</Button>
      </div>
      </>
      </div>
    </div>
  )
}

export default StepThree