// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewRecu_view__xKIlP .ViewRecu_btn__QWlab {
  width: 30px;
  height: 40px;
  border-radius: 30px;
  background-color: #0071BC;
  color: white; }

.ViewRecu_view__xKIlP .ViewRecu_btn__QWlab:hover {
  background-color: #0071bcb9; }
`, "",{"version":3,"sources":["webpack://./src/elements/viewRecuImage/ViewRecu.module.scss"],"names":[],"mappings":"AAAA;EAEQ,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,YAAY,EAAA;;AANpB;EASQ,2BAA2B,EAAA","sourcesContent":[".view{\n    .btn{\n        width: 30px;\n        height: 40px;\n        border-radius: 30px;\n        background-color: #0071BC;\n        color: white;\n    }\n    .btn:hover{\n        background-color: #0071bcb9;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": `ViewRecu_view__xKIlP`,
	"btn": `ViewRecu_btn__QWlab`
};
export default ___CSS_LOADER_EXPORT___;
