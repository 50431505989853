import { Box, Button, FormControl, Input, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classes from './User.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { addUser,  } from '../../store/superAdmin/superAdminActions'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toast } from 'react-toastify'
import isEmail from 'validator/lib/isEmail';



function AddUser(props) {

    const dispatch = useDispatch()
    const [showPassword , setShowPassword] = useState(false)


    const [data , setData] = useState({
        firstName:'',
        lastName:'',
        email:'',
        password:'',
        orgKey:'',
    })

    const [roles , setRoles] = useState([])

    const [orgSelect , setSelectOrg] = useState('')

    const org = useSelector(state => state.superAdmin.allOrg)

    const dataHandler = (e) => {
        let {name , value} = e.target
        setData(oldValue => {
            return {
                ...oldValue,
                [name]:value
            }
        })

    }

    console.log(roles);
    const rolesHandler = (e)=>{
        if (!orgSelect) {
            toast.warn('Sélectionner une organisation')
        }
        const { value, checked } = e.target;
            setRoles([...roles, value]);
            if (!checked) {
                setRoles(roles.filter(item => item !== value));
            }
    }

    const handleChange = (event) => {
        setSelectOrg(event.target.value);
      };

    const submitInvite = ()=>{
        if (isEmail(data.email)) {
       
            dispatch(addUser(data , roles , orgSelect))
           
        } else{
            toast.warn('Entrer un valid e-mail')
        }
    }


  return (
    <div className={classes.background} >
        <div className={classes.addBox}>
        <IconButton onClick={props.closeMenu} className={classes.closeIconBox} ><CloseIcon className={classes.closeIcon} /></IconButton>

            <div className={classes.title}>
                <h3>Ajouter un Utilisateur</h3>
                <hr/>
            </div>
            <div className={classes.inputs} >
                <div onChange={dataHandler} className={classes.insideInputs}>
                        <div className={classes.emailInput}>
                            <label>Nom</label>
                            <Input name='firstName'  type='text'  />
                        </div>
                        <div className={classes.emailInput}>
                            <label>Prénom</label>
                            <Input name='lastName'  type='text'  />
                        </div>
                        <div className={classes.emailInput}>
                            <label>Email</label>
                            <Input name='email'  type='email'  />
                        </div>
                        <div className={classes.emailInput}>
                            <label>Password</label>
                            <div className={classes.password}>
                                <Input className={classes.input} name='password'  type={showPassword ? 'text' :'password'}  />
                                {!showPassword?<IconButton  onClick={()=> setShowPassword(oldValue => !oldValue)}> <VisibilityIcon />  </IconButton> : <IconButton onClick={()=> setShowPassword(oldValue => !oldValue)} > <VisibilityOffIcon/> </IconButton> } 
                            </div>
                        </div>
                        <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">OrgKeys</InputLabel>
                            <Select 
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={orgSelect}
                            label="OrgKeys"
                            onChange={handleChange}
                            >
                            {org?.map(org => <MenuItem key={org.name} name={org.name} value={org.orgKey}>{org.orgKey}</MenuItem> ) }
                            </Select>
                        </FormControl>
                        </Box>
            </div>
            <div onChange={rolesHandler} className={classes.rolesInput}>
                            <label>Roles(facultatif)</label>

                            <div className={classes.check} > 
                                <label> Admin </label>
                                <input name='roles' value={`${orgSelect}_admin`} type={'checkbox'} />
                            </div>
                            <div className={classes.check}>
                                <label> Member </label>
                                <input name='roles' value={`${orgSelect}_member`} type={'checkbox'} />
                            </div>
                        </div>
         
            </div>
            <div className={classes.button} >
                <Button disabled={!orgSelect ? true : false} onClick={submitInvite} > Ajouter </Button>
            </div>
        </div>
    </div>
  )
}

export default AddUser