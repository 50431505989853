import { Button, Input } from '@mui/material'
import React, { useState } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import AddAdmin from '../../elements/addAdmin/AddAdmin'
import Notify from '../../elements/notification/Notify'
import { updatePriceQrCode } from '../../store/superAdmin/superAdminActions'
import classes from './RenderParamétrage.module.scss'
import { Helmet, HelmetProvider } from "react-helmet-async";

function RenderParamétrage(props) {

  const dispatch = useDispatch()
 
  const QrCodePrice = useSelector(state => state.user.qrCodePrice)

  const [price , setPrice] = useState(QrCodePrice)

  console.log(price);

  const getPrice = (e)=>{
    setPrice(e.target.value)
  }

  const updatePrice = () => {

    dispatch(updatePriceQrCode(price))

  }

  return (
    <div className={classes.box}>
      <HelmetProvider> <Helmet> <title> Paramétrage </title> </Helmet> </HelmetProvider>
       { props.closeSettingMenuValue &&  <Notify/>}
         {props.addUserPopUpValue &&  <AddAdmin  closeMenu={props.addUserPopUpFunc} />}
        <div className={classes.paramter} >
                <div className={classes.input}>
                    <label>Prix du QrCode</label>
                  <div onChange={getPrice} className={classes.inputBox}>
                  <Input className={classes.inputValue}   value={price} />
                  <div className={classes.boxDA}>
                      DA
                    </div>
                  </div>
                    
                </div>
                <div className={classes.button} >
                  <Link to={'/Dashboard'} style={{textDecoration:'none'}}> <Button > Quitter</Button> </Link>
                    <Button onClick={updatePrice} > Sauvegarder</Button>
                </div>
        </div>
    </div>
  )

}

export default RenderParamétrage