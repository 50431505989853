import { IconButton } from '@mui/material'
import React from 'react'
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import classes from './gestion.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { deleteCertificates, getOneCertificate } from '../../store/certficate/certficateActions';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit'
import DownloadIcon from '@mui/icons-material/Download'
import url from '../../url';
import { saveAs } from 'file-saver'
import { toast } from 'react-toastify';
import AT01ByBrenco from '../AT01-template/AT01ByBrenco';
import domtoimage from 'dom-to-image';

function GestionCertficates (props) {

    console.log(props.certificate.templateId);
    const dispatch = useDispatch()
    
    const userRoles = useSelector(state => state.auth.user.roles)
    const userOrgKey = useSelector(state => state.auth.user.orgKey)
  
    console.log(props);
    const isAdmin = userRoles?.includes(`${userOrgKey}_admin`)

    const certficateId = props.id


    const viewCert = (id) => {
        dispatch(getOneCertificate(id))
    }

    const DeleteCert = (id)=> {
        if (id) {
            props.openAlertConfirm()
            props.getCertificatesId(id)
        }
    }

    const download = () => {
        if (!props.certificate.hasBaseImage && !props.uploadedImage) {
            return toast.error('Pas de Certificates uploadeé')
        }
        if (props.certificate.hasQrCode) {
        saveAs( props.certificate.hasBaseImage , props.uploadedImage )
      
        } else{
        saveAs(`${url}/images/Certificates/${props.uploadedImage}` , props.uploadedImage )
        }
     
    }

    const downloadTemplate = () => {

        dispatch(getOneCertificate(certficateId))
        console.log(props);
       if (props.certificate.certificateId) {
        if (props.forwardedRef.current === null ) {
            return 
        } 
        domtoimage.toJpeg(props.forwardedRef.current)
        .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = `Certificates-${certficateId}`;
            link.href = dataUrl;
            link.click();
            console.log(dataUrl , 'dataurl');
        });
       }
       
    }

  return (
            <div className={classes.gestion}>
            <Link to={'/Dashboard/certificates/'+props.id} >
                <IconButton onClick={()=> viewCert(certficateId)} >
                    <PreviewIcon className={classes.icon}/>
                </IconButton>
            </Link>  
            <Link to={'/Dashboard/edit/'+props.id} >
                <IconButton onClick={()=> viewCert(certficateId)} >
                    <EditIcon className={classes.icon}/>
                </IconButton>
            </Link> 
             <IconButton onClick={ props.certificate.templateId ==='T01' ? downloadTemplate : download}>  {   <DownloadIcon className={classes.icon} /> } </IconButton>
              {isAdmin && <IconButton onClick={()=> DeleteCert(certficateId)}>
                    <DeleteIcon className={classes.icon} />
              </IconButton>}
            </div>
  )
}

export default GestionCertficates