import React from "react";
import classes from './CheckBox.module.scss'

const DocumentsCheckbox = ({ id, type, name, handleClick, isChecked , disabled}) => {
  return (
    <div className={classes.inputCheck}>
    <label>{name}</label>
    <input
      id={id}
      name={name}
      type={type}
      onChange={handleClick}
      checked={isChecked}
      className={classes.input}
      disabled={disabled ? false : true}
    />
    </div>
  );
};

export default DocumentsCheckbox;