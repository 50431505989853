// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notify_NotifyBox__hB777 {
  margin: 5px 10px;
  z-index: 10000;
  top: 0;
  right: 0;
  position: absolute;
  width: 200px;
  height: 100px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly; }
  .Notify_NotifyBox__hB777 .Notify_profileBtn__503Ts {
    text-decoration: none;
    color: black;
    width: 90%;
    display: flex;
    align-items: center;
    gap: 20px; }
    .Notify_NotifyBox__hB777 .Notify_profileBtn__503Ts p {
      font-size: 1rem; }
  .Notify_NotifyBox__hB777 .Notify_logoutBtn__watIG {
    cursor: pointer;
    color: grey;
    width: 90%;
    display: flex;
    align-items: center;
    gap: 20px; }
    .Notify_NotifyBox__hB777 .Notify_logoutBtn__watIG p {
      font-size: 1rem; }
`, "",{"version":3,"sources":["webpack://./src/elements/notification/Notify.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAgB;EAChB,cAAc;EACd,MAAM;EACN,QAAQ;EACR,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAoC;EACpC,mBAAmB;EACnB,gDAA6C;EAC7C,wDAAqD;EACrD,qDAAkD;EAClD,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,6BAA6B,EAAA;EAhBjC;IAkBQ,qBAAqB;IACrB,YAAY;IACZ,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,SAAS,EAAA;IAvBjB;MAyBY,eAAe,EAAA;EAzB3B;IA6BQ,eAAe;IACf,WAAW;IACX,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,SAAS,EAAA;IAlCjB;MAoCY,eAAe,EAAA","sourcesContent":[".NotifyBox{\n    margin: 5px 10px;\n    z-index: 10000;\n    top: 0;\n    right: 0;\n    position: absolute;\n    width: 200px;\n    height: 100px;\n    background-color: rgb(255, 255, 255);\n    border-radius: 10px;\n    box-shadow: 0px 4px 14px 0px rgba(0,0,0,0.75);\n    -webkit-box-shadow: 0px 4px 14px 0px rgba(0,0,0,0.75);\n    -moz-box-shadow: 0px 4px 14px 0px rgba(0,0,0,0.75);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-evenly;\n    .profileBtn{\n        text-decoration: none;\n        color: black;\n        width: 90%;\n        display: flex;\n        align-items: center;\n        gap: 20px;\n        p{\n            font-size: 1rem;\n        }\n    }\n    .logoutBtn{\n        cursor: pointer;\n        color: grey;\n        width: 90%;\n        display: flex;\n        align-items: center;\n        gap: 20px;\n        p{\n            font-size: 1rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NotifyBox": `Notify_NotifyBox__hB777`,
	"profileBtn": `Notify_profileBtn__503Ts`,
	"logoutBtn": `Notify_logoutBtn__watIG`
};
export default ___CSS_LOADER_EXPORT___;
