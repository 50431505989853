import React from 'react';
import Background from '../../../elements/background-login/Background';
import Button from '../../../elements/button/Button';
import InputForm from '../../../elements/inputForm/InputForm';
import classes from './OrgkeyForget.module.scss'
import {Link} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { Helmet, HelmetProvider } from "react-helmet-async";


function OrgkeyForget() {

  return (<div className={classes.AuthPage}>
    <HelmetProvider> <Helmet> <title> Uphawu | forget orgKey </title> </Helmet> </HelmetProvider>
    <Background/>
<div className={classes.auth} >
    <div className={classes.authBox} >
      <div className={classes.h2} >
      <h2  >Réinitialisation du Organisation Key</h2>
      </div>
    <form  className={classes.authForm}>
    <div className={classes.authInput} >
    <InputForm title='Adresse Email:' name="email" type='email'  />
    </div>
    <div className={classes.button}>
    <Button textColor={'white'}  color={'#0071BC'} btName='Envoyer le lien' />
    <Link to='/login' ><Button textColor={'white'} color={'#343a40'} btName='Retour' /></Link> 
    </div>
    <>
    </>
    </form>
    </div>
  
  
</div>

</div>);
}

export default OrgkeyForget;
