import React, { useEffect, useRef, useState } from 'react';
import classes from './RenderInfo.module.scss'
import Notify from '../../elements/notification/Notify';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getOneOrg, updateOrgInfo } from '../../store/user/userActions';
import moment from 'moment';
import AddUserPopUp from '../../elements/addUserPopUp/AddUserPopUp';
import { Helmet, HelmetProvider } from "react-helmet-async";


function RenderInfo(props) {

    const orgInfo = useSelector(state => state.user.org)
    const [title, setTitle] = useState("");
    const dispatch = useDispatch()
    const [newTypeDoc , setNewTypeDoc] = useState(orgInfo.documentsType)
    const [canEdit , setCanEdit] = useState(false)
    const user = useSelector(state => state.auth.user)
    const [data , setData] = useState({
        name:orgInfo.name,
        linkedInId:orgInfo.linkedInId
    })

    console.log(orgInfo);
    console.log(data);
    useEffect(()=>{

        if (!props.selectValueToOrgInfo) {
          dispatch(getOneOrg(user.orgKey))
        } else{
          dispatch(getOneOrg(props.selectValueToOrgInfo.value))
        }
    
      }, [])

      useEffect(() => {
        const fetchData = async () => {
    
          // When the data "fetching" process is complete, we will set the title and content
          setTitle("Informations");
        };
    
        fetchData();
      }, []);

    const getData = (e) => {
        const {name , value} = e.target
        setData(oldValue => {
            return{
                ...oldValue, 
                [name]:value
            }
        })
    }
   


    const typeDocHendler = (e)=>{
        let {checked , value} =  e.target
        if (checked) {
            setNewTypeDoc(oldValue =>  [...oldValue , value]);
        }else{
            setNewTypeDoc(oldValue => oldValue.filter((_,i) => i !== newTypeDoc.length - 1))
        }
    }

    const canEditHandler = ()=>{
        setCanEdit(oldValue => !oldValue)
    }
    const submitHandler = ()=>{
        let info = {
            key:props.selectValueToOrgInfo,
            name : data.name,
            linkedInId: data.linkedInId,
            documentsType:newTypeDoc
        }
        dispatch(updateOrgInfo(info))
        setCanEdit(oldValue => !oldValue)
    }

  return <div className={classes.infoPage} >
      <HelmetProvider> <Helmet> <title> {title} </title> </Helmet> </HelmetProvider>
         {props.closeSettingMenuValue &&  <Notify/>}
         {props.addUserPopUpValue &&  <AddUserPopUp  closeMenu={props.addUserPopUpFunc} />}
     
      <div className={classes.infoBox} >
      <div onChange={getData} className={classes.info} >
            <div className={classes.title}> 
                <h3>Informations de l'organisation</h3>    
             </div>
             <div className={classes.orgName} >
                 <label>Organisation Key</label>
                 <input  type='text' disabled value={orgInfo?.orgKey} />
             </div>
             <div  className={classes.orgName} >
                 <label>Nom</label>
                 <input disabled={!canEdit}  type='text' name='name' value={data.name} />
             </div>
             <div  onChange={typeDocHendler} className={classes.checkbox} >
                 <label>Type de documents utilisés</label>
                 <div>
                     <input disabled={!canEdit} type='checkbox' defaultValue={'AT01'} checked={newTypeDoc?.includes('AT01') ? true : undefined}/>
                     <h3>Attestation de formation</h3>
                 </div>
                 <div>
                     <input disabled={!canEdit} type='checkbox'  defaultValue={'AT02'} checked={newTypeDoc?.includes('AT02') ? true : undefined} />
                     <h3>Attestation d'incubation</h3>
                 </div>
                 <div>
                     <input disabled={!canEdit} type='checkbox' defaultValue={'AT03'} checked={ newTypeDoc?.includes('AT03') ? true : undefined}/>
                     <h3>Attestation de participation</h3>
                 </div>
                 <div>
                     <input disabled={!canEdit} type='checkbox' defaultValue={'AT04'} checked={newTypeDoc?.includes('AT04') ? true : undefined}/>
                     <h3>Attestation de travail</h3>
                 </div>
                 <div>
                     <input disabled={!canEdit} type='checkbox' defaultValue={'CR01'} checked={ newTypeDoc?.includes('CR01') ? true : undefined} />
                     <h3> Compte Rendu d'Analyse Epidémiologique</h3>
                 </div>
                 <div>
                     <input disabled={!canEdit} type='checkbox' defaultValue={'IN01'}  checked={ newTypeDoc?.includes('IN01') ? true : undefined}/>
                     <h3> Invitation Evènement</h3>
                 </div>
                 <div>
                     <input disabled={!canEdit} type='checkbox' defaultValue={'CT01'} checked={ newTypeDoc?.includes('CT01') ? true : undefined} />
                     <h3> Certificat de Labélisation</h3>
                 </div>
                 <div>
                     <input disabled={!canEdit} type='checkbox' defaultValue={'AT05'} checked={ newTypeDoc?.includes('AT05') ? true : undefined} />
                     <h3>Attestation d'adhésion</h3>
                 </div>
               
             </div>
             <div className={classes.orgName} >
                 <label>LinkedIn ID</label>
                 <input disabled={!canEdit}  name='linkedInId' type='text' value={data.linkedInId} />
             </div>
             <div className={classes.orgName} >
                 <label>QRCodes restants</label>
                 <input disabled  type='text' value={orgInfo?.availableQrCodes} />
             </div>
             
             <div className={classes.orgName} >
                 <label>Crée le</label>
                 <input disabled  type='text' value={moment(orgInfo?.createdAt).format('YYYY-MM-DD')} />
             </div>
             <div className={classes.button} >
                   {!canEdit ? <Button onClick={canEditHandler}> Modifier</Button> : <Button onClick={submitHandler}> Submit</Button> }
            </div>
      </div>
      </div>
  </div>;
}

export default RenderInfo;
