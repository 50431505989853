import { Button, FormControl, Input, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classes from './User.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { addUser, editUser,  } from '../../store/superAdmin/superAdminActions'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toast } from 'react-toastify'
import isEmail from 'validator/lib/isEmail';
import { Box } from '@mui/system';



function EditUser(props) {

   
    

    const dispatch = useDispatch()
    const [showPassword , setShowPassword] = useState(false)

    const [enableEdit , setEnableEdit] = useState(false)
    const [orgKeys, setOrgKeys] = useState('');

    const user = useSelector(state =>  state.superAdmin.user)
    
    

    const handleChange = (event) => {
        setOrgKeys(event.target.value);
      };

    const [data , setData] = useState({
        firstName:user.firstName,
        lastName:user.lastName,
        email:user.email,
        password:user.password,
    })
    
    console.log(data);



    const [roles , setRoles] = useState([])


    const dataHandler = (e) => {
        let {name , value} = e.target
        setData(oldValue => {
            return {
                ...oldValue,
                [name]:value
            }
        })

    }

    const rolesHandler = (e)=>{
        const { value, checked } = e.target;
        if (!orgKeys) {
            toast.warn('Veuillez entrer une orgKey')
        } else{
            setRoles([...roles, value]);
            if (!checked) {
                setRoles(roles.filter(item => item !== value));
            }
        }
    }


    const submitInvite = ()=>{
     
            if (isEmail(data?.email || user.email) && orgKeys) {
                dispatch(editUser( user.id ,data , roles , user , orgKeys))  
            } else if (!orgKeys) {
                    toast.warn('sélectionner une orgKey pour faire une modification')
            }else if (!isEmail(data.email)) {
                toast.warn('Entrer un valid e-mail')
            }
        
  
    }

  return (
    <div className={classes.background} >
        <div className={classes.addBox}>
        <IconButton onClick={props.closeMenu} className={classes.closeIconBox} ><CloseIcon className={classes.closeIcon} /></IconButton>

            <div className={classes.title}>
                <h3>Modifier un Utilisateur</h3>
                <hr/>
            </div>
            <div className={classes.inputs} >
                <div onChange={dataHandler} className={classes.insideInputs}>
                        <div className={classes.emailInput}>
                            <label>Nom</label>
                            <Input name='firstName' disabled={enableEdit ? false : true} value={enableEdit ? null : user.lastName}  type='text'  />
                        </div>
                        <div className={classes.emailInput}>
                            <label>Prénom</label>
                            <Input name='lastName' disabled={enableEdit ? false : true} value={enableEdit ? null : user.firstName } type='text'  />
                        </div>
                        <div className={classes.emailInput}>
                            <label>Email</label>
                            <Input name='email' disabled={enableEdit ? false : true} required value={enableEdit ? null : user.email} type='email'  />
                        </div>
                        <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">OrgKeys</InputLabel>
                            <Select 
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={orgKeys}
                            label="OrgKeys"
                            onChange={handleChange}
                            >
                            {user.orgKeys?.map(org => <MenuItem key={org} value={org}>{org}</MenuItem> ) }
                            </Select>
                        </FormControl>
                        </Box>
                        <div className={classes.emailInput}>
                            <label>Password</label>
                            <div className={classes.password}>
                                <Input className={classes.input} name='password'  type={showPassword ? 'text' :'password'}  />
                                {!showPassword?<IconButton  onClick={()=> setShowPassword(oldValue => !oldValue)}> <VisibilityIcon />  </IconButton> : <IconButton onClick={()=> setShowPassword(oldValue => !oldValue)} > <VisibilityOffIcon/> </IconButton> } 
                            </div>
                        </div>
            </div>
            <div onChange={rolesHandler} className={classes.rolesInput}>
                            <label>Roles</label>

                            <div className={classes.check} > 
                                <label> Admin </label>
                                <input required name='roles' disabled={enableEdit && orgKeys ? false : true} checked={enableEdit ? null : user.roles?.includes(`${orgKeys}_admin`) ? true :false} value={`${orgKeys}_admin`} type={'checkbox'} />
                            </div>
                            <div className={classes.check}>
                                <label> Member </label>
                                <input required name='roles' disabled={enableEdit && orgKeys ? false : true} checked={enableEdit ? null : user.roles?.includes(`${orgKeys}_member`) ? true :false}  value={`${orgKeys}_member`} type={'checkbox'} />
                            </div>
                        </div>
         
            </div>
            <div className={classes.button} >
                {!enableEdit ? <Button onClick={()=> setEnableEdit(oldValue => !oldValue)} > Modifier </Button> : <Button onClick={submitInvite} > envoyer </Button>}
            </div>
        </div>
    </div>
  )
}

export default EditUser