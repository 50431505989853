
import instance from "../../axios";
import { authActions } from "./authSlice";
import { stepActions } from "./stepsSlice";

import {  toast } from 'react-toastify';
import { superAdminActions } from "../superAdmin/superAdminSlice";


/*-------------------- Sign up ---------------------------- */
export const sendCode = (email)=> async (dispatch) =>{
    console.log(email);
    try {
        const res = await instance.post('/auth/send-verification-code' , {email})
        if (res.data.success) {
            dispatch(stepActions.setStepOneSuccess(res.data.success))
            toast.success(res.data.message)
            toast.success('Si vous ne pouvez pas trouver le code, vérifiez le dossier spam.')
            setTimeout(()=>{
                dispatch(authActions.switchStep(2))
                dispatch(stepActions.setStepOneSuccess(false))
            }, 3000)
        }
    } catch (error) {
        console.log(error.response.data);
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
            dispatch(stepActions.setStepOneSuccess(error.response.data.success))
        }

    }
 }


export const verifyCode = (email , code)=> async (dispatch) =>{
    try {
        const res = await instance.post('/auth/check-verification-code' , {email , code})
        console.log('THIS IS ', res);
        if (res.data.success) {
            dispatch(stepActions.setStepTwoSuccess(res.data.success))
            setTimeout(()=>{
                dispatch(authActions.switchStep(3))
                dispatch(stepActions.setStepTwoSuccess(false))
            },3000)
        }
    } catch (error) {
        console.log(error.response.data);
        if (!error.response.data.success) {
            dispatch(stepActions.setStepTwoSuccess(error.response.data.success))
            toast.warn(error.response.data.message)
        }
    }
 }

export const verifyOrgKey = (orgKey) => async (dispatch) =>{
    try {
        const res = await instance.get(`/auth/check-orgKey/${orgKey}`)
        console.log(res);
        if (res.data.success) {
            toast.success('OrgKey Trouver')
            dispatch(authActions.setIsOrgKeyValid(res.data.success))
            dispatch(authActions.setOrgName(res.data.orgName))
        }
    } catch (error) {
        console.log(error.response.data);
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
            dispatch(authActions.setIsOrgKeyValid(error.response.data.success))
        }
    }

}

export const signup = (info)=> async (dispatch) =>{
    dispatch(authActions.setIsLoading(true))
   try {
       let res = await instance.post('/auth/register' , info)
       console.log(res);
       if (res.data.success) {
        dispatch(authActions.setIsLoading(false))
           toast.success(
             `${res.data.message}, verifier votre boite email pour les identifiants de connexion`
           );
           
           dispatch(authActions.setIsSignUp(res.data.success))
       }
   } catch (error) { 
           if (!error.response.data.success) {
            dispatch(authActions.setIsLoading(false))
            toast.warning(error.response.data.message)
        }
   }
}

/*--------------------------------------- Login --------------------------------------------------- */ 

export const login = (info) => async (dispatch)=>{
    dispatch(authActions.setIsLoading(true))

    try {
        let {email ,password ,orgKey} = info
        const res = await instance.post('/auth/login' , {email ,password ,orgKey})
        
        if (res.data.success) {
            toast.success(res.data.message)
            localStorage.setItem('token' , res.data.token)  
            dispatch(authActions.setIsLoading(false))
            dispatch(authActions.setIsAuth(res.data.success))
        }
         if (res.data.user.roles.includes('ADM001_super')) {
            dispatch(authActions.setIsLoading(false))
            }
    } catch (error) {

        dispatch(authActions.setIsLoading(false))
         if (!error.response.data.success) {
            toast.warn(error.response.data.message)
            dispatch(authActions.setIsLoading(false))
            
        }
    }
}

export const checkToken = (token) => async (dispatch)=>{
    dispatch(authActions.setIsLoading(true))
    try {
        if (token) {
            const res = await instance.get('/auth/check-token' , {headers : {Authorization : localStorage.getItem('token')}})
            console.log(res.data);
            if (res.data.success) {
                dispatch(authActions.isTokenValid(res.data.success))
                dispatch(authActions.setIsAuth(res.data.success))
                dispatch(authActions.setUserInfo(res.data.user))
            } 
            if (res.data.user.roles.includes('ADM001_super')) {
                dispatch(superAdminActions.setIsSuperAdmin(true))
            }
        }
        dispatch(authActions.setIsLoading(false))
    } catch (error) {
        dispatch(authActions.setIsLoading(false))
        if (!error.response.data.message) {
            dispatch(authActions.isTokenValid(error.response.data.message))
            dispatch(authActions.setIsAuth(error.response.data.message))
        }
    }
}

/*------------------------------------ forgetPassword ------------------------------------- */

export const forgetPassword = (info)=> async (dispatch)=>{
    try {
        const res = await instance.post('/auth/send-reset-password-link' , info)
        console.log(res);
        if (res.data.success) {
            toast.success(res.data.message)
            dispatch(authActions.setStepsFailed(res.data.success))
        }   
    } catch (error) {
        console.log(error.response.data);
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
        }   
    }
}


/*----------------------------------------- RestLink -------------------------------------------*/

export const resetPassword = (info)=> async (dispatch)=>{
    try {
        const res = await instance.post('/auth/reset-password' , info)    
        console.log(res);
        if (res.data.success) {
            toast.success(res.data.message)
            dispatch(authActions.setRestPasswordSuccess(res.data.success))
            dispatch(authActions.setEmailResetPassword(res.data.email))
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
        }   
    }
}

/*------------------------------------------- Logout -------------------------------------------------- */

export const logout = (token)=> async (dispatch) =>{
    dispatch(authActions.setIsLogout(true))
    if (token) {
        try {
            const res = await instance.get('/auth/logout' , {headers : {Authorization : localStorage.getItem('token')}})
            if (res.data.success) {
                toast.success(res.data.message)
            }
        } catch (error) {
                toast.warning(error.response.data.message)
        }
    }
}