import React from 'react';
import Background from '../../../elements/background-login/Background';
import InputForm from '../../../elements/inputForm/InputForm';
import classes from './Steps.module.scss'
import { useDispatch } from 'react-redux';
import { authActions } from '../../../store/auth/authSlice';
import Button from '../../../elements/button/Button';




function StepSix({ emailInviteOne, emailInviteTwo }) {

  const dispatch = useDispatch()
  
  const next = ()=>{
    dispatch(authActions.switchStep(7))
  }

  const previous = ()=>{

    dispatch(authActions.switchStep(5))

  }

  return ( <div className={classes.SignUpSteps}>
    <Background/>
  <div className={classes.SignUp} >
    <div className={classes.SignUpBox} >
      <div className={classes.h2} > <h2  >
        Inscrivez-vous maintenant et commencer à utiliser UPHAWU 
  </h2></div>
    <div  className={classes.signUpForm}>
        <div className={classes.inputBox} >
        <p>Emails des utilisateurs à inviter: (Facultatif)</p>
        <InputForm {...emailInviteOne} name='emailInviteOne'  />
        <InputForm {...emailInviteTwo} name='emailInviteTwo'  />
        </div>
    <div className={classes.button}>
    <Button onClick={next} textColor={'white'}  color={'#0071BC'} > Continuer</Button> 
    <Button  onClick={previous} textColor={'white'}  color={'#343a40'} > Retour</Button>
    </div>
    </div>
    </div>
  </div>
  </div> );
}

export default StepSix;
