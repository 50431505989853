import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./ProfileElement.module.scss";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  getUserOne,
  updateOneUser,
  updateUserPassword,
  updateUserRoles,
} from "../../store/user/userActions";
import { Button } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { checkPassword } from "../../helper";

function ProfileElement() {
  let url = window.location.href;
  url = url.split("/");
  const history = useHistory();
  const dispatch = useDispatch();
  const [newDataUser, setNewDataUser] = useState([]);
  const [newRoles, setNewRoles] = useState([]);
  const user = useSelector((state) => state.user.user);
  const userAuthId = useSelector((state) => state.auth.user.id);
  const userOrgKey = useSelector((state) => state.auth.user.orgKey);
  const userRoles = useSelector((state) => state.auth.user.roles);

  console.log(userAuthId);

  useEffect(() => {
    if (user.id) {
      setNewDataUser(user);
      setNewRoles(user.roles);
    } else {
      if (userAuthId) {
        history.push("/Dashboard/profile");
        dispatch(getUserOne(userAuthId));
      }
    }
  }, [user.id, user.roles, userAuthId, dispatch]);

  console.log(newRoles);

  const [newUpdateUserInfo, setNewUserInfo] = useState({
    firstName: newDataUser?.firstName,
    lastName: newDataUser?.lastName,
    username: newDataUser?.username,
    phone: newDataUser?.phone,
    id: newDataUser?.id,
  });
  const [newPassword, setNewPassword] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const [changeUserInfo, setChangeUserInfo] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const updateRoleHandler = (e) => {
    let { checked, value } = e.target;

    if (checked) {
      setNewRoles((oldValue) => [...oldValue, value]);
    } else {
      setNewRoles((oldValue) =>
        oldValue.filter((_, i) => i !== oldValue.length - 1)
      );
    }
  };

  console.log(newRoles);

  const handelUserInfo = (e) => {
    let { name, value } = e.target;
    setNewUserInfo((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
  };

  const handelNewPassword = (e) => {
    let { name, value } = e.target;
    setNewPassword((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
  };

  const updateUserInfo = (e) => {
    dispatch(updateOneUser(newDataUser.id, newUpdateUserInfo));
    setChangeUserInfo((oldValue) => !oldValue);
  };

  const updateUserRole = (e) => {
    dispatch(updateUserRoles(newDataUser.id, newRoles));
    setChangeUserInfo((oldValue) => !oldValue);
  };
  const updatePassword = (e) => {
    if (checkPassword(newPassword.newPassword, newPassword.newPassword)) {
      dispatch(updateUserPassword(newPassword));
      setChangePassword((oldValue) => !oldValue);
      setNewPassword({
        oldPassword: "",
        newPassword: "",
      });
    }
  };

  const isAdmin = userRoles?.includes(`${userOrgKey}_admin`);

  return (
    <div className={classes.profile}>
      <HelmetProvider>
        {" "}
        <Helmet>
          {" "}
          <title> Détails Utilisateur </title>{" "}
        </Helmet>{" "}
      </HelmetProvider>
      <div className={classes.profileInfo}>
        <form onChange={handelUserInfo} className={classes.info}>
          <div className={classes.title}>
            <h3>Détails Utilisateur</h3>
          </div>
          <div className={classes.orgName}>
            <label>ID</label>
            <input
              type='text'
              disabled
              defaultValue={newDataUser?.id}
              name='id'
            />
          </div>
          <div className={classes.orgName}>
            <label>Nom</label>
            <input
              type='text'
              disabled={!changeUserInfo || !isAdmin}
              name='lastName'
              defaultValue={newDataUser?.lastName}
            />
          </div>
          <div className={classes.orgName}>
            <label>Prénom</label>
            <input
              type='text'
              disabled={!changeUserInfo || !isAdmin}
              name='firstName'
              defaultValue={newDataUser?.firstName}
            />
          </div>
          <div className={classes.orgName}>
            <label>Email</label>
            <input type='text' disabled defaultValue={user.email} />
          </div>
          <div className={classes.orgName}>
            <label>Numéro de téléphone </label>
            <input
              type='number'
              name='phone'
              disabled={!changeUserInfo || !isAdmin}
              defaultValue={newDataUser?.phone}
            />
          </div>
          <div className={classes.orgName}>
            <label> Nom d'utilisateur </label>
            <input
              type='text'
              name='username'
              disabled={!changeUserInfo || !isAdmin}
              defaultValue={newDataUser?.username}
            />
          </div>
          <div className={classes.orgName}>
            <label>Dernière Connexion </label>
            <input
              type='text'
              disabled
              defaultValue={moment(newDataUser?.lastLogin).format(
                "MMMM Do YYYY, h:mm"
              )}
            />
          </div>
          <div className={classes.orgName}>
            <label>Date de création</label>
            <input
              type='text'
              disabled
              defaultValue={moment(newDataUser?.createdAt).format(
                "MMMM Do YYYY, h:mm"
              )}
            />
          </div>
          {isAdmin && (
            <div onChange={updateRoleHandler} className={classes.checkbox}>
              <label>Roles</label>
              <div>
                <input
                  type='checkbox'
                  disabled={isAdmin ? !changeUserInfo : true}
                  checked={newRoles?.includes(`${userOrgKey}_admin`)}
                  value={`${userOrgKey}_admin`}
                />
                <h3>admin</h3>
              </div>
              <div>
                <input
                  type='checkbox'
                  disabled={isAdmin ? !changeUserInfo : true}
                  checked={newRoles?.includes(`${userOrgKey}_member`)}
                  value={`${userOrgKey}_member`}
                />
                <h3>member</h3>
              </div>
            </div>
          )}
          <div className={classes.button}>
            {isAdmin ? (
              !changeUserInfo ? (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setChangeUserInfo((oldValue) => !oldValue);
                  }}
                >
                  {" "}
                  Modifier
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    updateUserInfo();
                    updateUserRole();
                    setChangeUserInfo((oldValue) => !oldValue);
                  }}
                >
                  {" "}
                  Submit
                </Button>
              )
            ) : (
              ""
            )}
          </div>
        </form>
        {!url[5] && (
          <form  className={classes.info}>
            <div className={classes.title}>
              <h3>Changer le mot de passe</h3>
            </div>
            <div className={classes.orgName}>
              <label>Ancien mot de passe</label>
              <div className={classes.password}>
                <input
                  name='oldPassword'
                  disabled={!changePassword}
                  type={showPassword ? "text" : "password"}
                  value={newPassword.oldPassword}
                  onChange={handelNewPassword}
                />
                <IconButton
                  onClick={() => setShowPassword((oldValue) => !oldValue)}
                >
                  {" "}
                  {showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}{" "}
                </IconButton>
              </div>
            </div>
            <div className={classes.orgName}>
              <label>Nouveau mot de passe</label>
              <div className={classes.password}>
                <input
                  name='newPassword'
                  disabled={!changePassword}
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword.newPassword}
                  onChange={handelNewPassword}
                />
                <IconButton
                  onClick={() => setShowNewPassword((oldValue) => !oldValue)}
                >
                  {" "}
                  {showNewPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}{" "}
                </IconButton>
              </div>
            </div>
            <div className={classes.button}>
              {!changePassword ? (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setChangePassword((oldValue) => !oldValue);
                  }}
                >
                  {" "}
                  Modifier{" "}
                </Button>
              ) : (
                <Button onClick={updatePassword}> Submit</Button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default ProfileElement;
