import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { signup } from "../../store/auth/authActions";
import { authActions } from "../../store/auth/authSlice";
import StepFive from "./multiSteps/StepFive";
import StepFour from "./multiSteps/StepFour";
import StepOne from "./multiSteps/StepOne";
import StepSix from "./multiSteps/StepSix";
import StepSeven from "./multiSteps/StepSeven";
import StepThree from "./multiSteps/StepThree";
import StepTwo from "./multiSteps/StepTwo";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useField } from "../../custom hooks";

const MultiStepForm = () => {
  const email = useField("email");
  const emailInviteOne = useField("email");
  const emailInviteTwo = useField("email");
  const emailInviteThree = useField("email");
  const emailInviteFour = useField("email");
  const codeVerification = useField("text");
  const createOrJoin = useField("text");
  const documentType = useField("text");
  const orgName = useField("text");
  const linkIn = useField("text");
  const orgKey = useField("text");
  const joinOrg = useField("text");
  const password = useField("password");
  const confirmPassword = useField("password");

  const history = useHistory();
  const dispatch = useDispatch();

  const isSignup = useSelector((state) => state.auth.isSignup);
  const setSteps = useSelector((state) => state.auth.currentStep);
  const willCreate = useSelector((state) => state.auth.willCreate);
  const [checkBox, SetCheckBoxId] = useState([]);

  const submitData = () => {
    let signupInfo;
    if (willCreate) {
      signupInfo = {
        willCreate: willCreate,
        user: {
          email: email.value,
          password: password.value,
        },
        org: {
          documentsType: checkBox,
          name: orgName.value,
          linkedInId: linkIn.value,
        },
      };
    } else {
      signupInfo = {
        willCreate: willCreate,
        user: {
          email: email.value,
          password: password.value,
        },
        org: {
          orgKey: orgKey.value.toUpperCase(),
          linkedInId: linkIn.value,
        },
      };
    }
    dispatch(signup(signupInfo));
    history.push("/login");
    dispatch(authActions.switchStep(1));
  };

  useEffect(() => {
    if (isSignup) {
      setTimeout(() => {
        dispatch(authActions.setIsLoading(false));
        history.push("/login");
      }, 3000);
    }
  }, [isSignup, history, dispatch]);

  const [radioBox, setRadioBox] = useState("Créer une organisation");

  const props = {
    setRadioBox,
    radioBox,
    submitData,
    SetCheckBoxId,
  };

  switch (setSteps) {
    case 1:
      return (
        <>
          {" "}
          <HelmetProvider>
            {" "}
            <Helmet>
              {" "}
              <title>
                {" "}
                Uphawu | Inscrivez-vous maintenant et commencer à utiliser
                UPHAWU{" "}
              </title>{" "}
            </Helmet>
          </HelmetProvider>{" "}
          <StepOne email={email} />
        </>
      );
    case 2:
      return (
        <>
          {" "}
          <HelmetProvider>
            {" "}
            <Helmet>
              {" "}
              <title>
                {" "}
                Uphawu | Vérifier le code contenu dans votre E-mail{" "}
              </title>{" "}
            </Helmet>
          </HelmetProvider>{" "}
          <StepTwo codeVerfication={codeVerification} email={email} />
        </>
      );
    case 3:
      return (
        <>
          {" "}
          <HelmetProvider>
            {" "}
            <Helmet>
              {" "}
              <title> Uphawu | Que souhaitez vous faire ? </title>{" "}
            </Helmet>
          </HelmetProvider>{" "}
          <StepThree {...props} createOrJoin={createOrJoin} />
        </>
      );
    case 4:
      return (
        <>
          {" "}
          <HelmetProvider>
            {" "}
            <Helmet>
              {" "}
              <title> Uphawu | Informations sur votre organisation </title>{" "}
            </Helmet>
          </HelmetProvider>{" "}
          <StepFour
            {...props}
            createOrJoin={createOrJoin}
            linkIn={linkIn}
            orgKey={orgKey}
            orgName={orgName}
          />
        </>
      );
    case 5:
      return (
        <>
          {" "}
          <HelmetProvider>
            {" "}
            <Helmet>
              {" "}
              <title>
                {" "}
                Uphawu | Quel type de document voulez vous signez actuellement?
              </title>{" "}
            </Helmet>
          </HelmetProvider>{" "}
          <StepFive {...props} />
        </>
      );
    case 6:
      return (
        <>
          {" "}
          <HelmetProvider>
            {" "}
            <Helmet>
              {" "}
              <title> Uphawu | Ajouter des utilisateurs</title>{" "}
            </Helmet>
          </HelmetProvider>{" "}
          <StepSix
            {...props}
            emailInviteOne={emailInviteOne}
            emailInviteTwo={emailInviteTwo}
          />{" "}
        </>
      );
    case 7:
      return (
        <>
          {" "}
          <HelmetProvider>
            {" "}
            <Helmet>
              {" "}
              <title> Uphawu | Ajouter des utilisateurs</title>{" "}
            </Helmet>
          </HelmetProvider>{" "}
          <StepSeven
            {...props}
            password={password}
            confirmPassword={confirmPassword}
          />{" "}
        </>
      );
    default:
      return null;
  }
};

export default MultiStepForm;
