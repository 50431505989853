import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    user:[],
    userByEmail:[],
    allUsers:[],
    order:[],
    allOrder:[],
    org:[],
    satimUrl:'',
    orderId:'',
    qrCodePrice:'',
    selectOrgKey:'',
    inviteAccepted:false,
    inviteSent:false,
    stepOrderCommand:1
}

const userSlice = createSlice({
    name:'user' ,
    initialState ,
    reducers : {
      
        // ------- user
        getUser(state , action){
            state.user = action.payload
        },
        //--------- user by email
        getUserByEmail(state , action){
            state.userByEmail = action.payload
        },
        // ------- users
        getAllUsers(state , action){
            state.allUsers = action.payload
        },
        // ------- order
        getOneOrder(state , action){
            state.order = action.payload
        },
        // ------- allOrder
        getAllOrder(state , action){
            state.allOrder = action.payload
        },
        // -------- get one Org
        getOrg(state , action){
            state.org = action.payload
        },
        // ------- select org key
        setSelectOrgKey(state , action){
            state.selectOrgKey = action.payload
        },
        //-------- Settings QrCode -------
        setPrice(state , action){
            state.qrCodePrice = action.payload
        },
        //- ------ accept Invite ------------
        setInvitaion(state , action){
            state.inviteAccepted = action.payload
        },
        // ------------ ssend invite --------
        setInviteSent(state , action){
            state.inviteSent = action.payload
        },
        getSatimUrl(state , action){
            state.satimUrl = action.payload
        },
        getOrderId(state , action){
            state.orderId = action.payload
        },
        setSwitchStep(state , action){
            state.stepOrderCommand = action.payload
        }
    }
})

export const userActions = userSlice.actions
export default userSlice