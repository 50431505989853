import React from 'react'
import { Link } from 'react-router-dom'
import classes from './InValidPayments.module.scss'
import ErrorIcon from '@mui/icons-material/Error';
import logo  from '../../assets/logo.png'

function InValidPayments() {
  return (
    <div className={classes.body}>
           <nav className={classes.headerNav}>
            <div className={classes.headerLogo}>
                <Link to={"/Dashboard"} >
                 <img src={logo}  alt='logo'  width="175px" />
                </Link>
    </div>
        </nav>
    <div className={classes.box}>

    <div className={classes.container}>
 
 <ErrorIcon style={{color:'#0071BC'}} className={classes.errorIcone} />
   <div className={classes.boxOne}>
     <i ></i>
     <div class="content">
       <div class="header">
       Oups! Quelque chose s'est mal passé.
       </div>
       <p>En essayant de réserver de l'argent à partir de votre compte</p>
     </div>
     
  </div>

   <Link to={'/Dashboard/renew'} className={classes.link} >Essai a nouveaux</Link>
 


</div>
    </div>
    </div>
  )
}

export default InValidPayments