import { Button, IconButton, Input } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import classes from './CheckPassword.module.scss'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { checkPassword, deleteOragnisation } from '../../store/superAdmin/superAdminActions';


function CheckPasswordifTrue(props) {

    const dispatch = useDispatch()
    const isCheckedPassword = useSelector(state => state.superAdmin.passwordIsChecked)
    const [showPassword , setShowPassword] = useState(false)
    const [password , setPassword] = useState('')
   
    console.log(isCheckedPassword);

    const passwordHandler = (e)=>{
        setPassword(e.target.value)
    }

    useEffect(()=>{

        if (isCheckedPassword) {
            dispatch(deleteOragnisation(props.orgKey))
            props.ClosePop()
        }

    },[isCheckedPassword , props , dispatch])

    const submitPassword = () => {

        dispatch(checkPassword(password))

    }

  return (

    <div className={classes.checkPassword}>
        <div className={classes.pass}>
        <IconButton onClick={props.ClosePop} className={classes.closeIconBox} ><CloseIcon className={classes.closeIcon} /></IconButton>

            <div className={classes.title}>
                <h3>Confirm le mot pass</h3>
                <hr/>
            </div>
            <div className={classes.emailInput}>
                            <label>Password</label>
                            <div onChange={passwordHandler} className={classes.password}>
                                <Input className={classes.input} name='password' value={password}   type={showPassword ? 'text' :'password'}  />
                                {!showPassword?<IconButton  onClick={()=> setShowPassword(oldValue => !oldValue)}> <VisibilityIcon />  </IconButton> : <IconButton onClick={()=> setShowPassword(oldValue => !oldValue)} > <VisibilityOffIcon/> </IconButton> } 
                            </div>
            </div>
            <div className={classes.button} >
              <Button onClick={submitPassword} > Confirm </Button> 
            </div>
        </div>
    </div>
  )
}

export default CheckPasswordifTrue