import React, { useEffect, useRef }  from 'react';
import Background from '../../../elements/background-login/Background';
import Button from '../../../elements/button/Button';
import InputForm from '../../../elements/inputForm/InputForm';
import classes from './ResetPassword.module.scss'
import {Link, useHistory} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../../store/auth/authActions';
import { toast } from 'react-toastify';



function ResetPassword() {

  const dispatch = useDispatch()
  const history = useHistory()
  const isRestPasswordSuccess = useSelector(state => state.auth.isRestPasswordSuccess)


  const NewPassword = useRef()
  const ConfirmNewPassword = useRef()
  
  let url = window.location.href
  url = url.split('/')



  const submitRestPassword = (e)=>{
    e.preventDefault()
    let info = {
        newPassword : NewPassword.current.value,
        resetPasswordToken:url[4],
    }
    if (NewPassword.current.value === ConfirmNewPassword.current.value) {
    dispatch(resetPassword(info))
    } else{
      toast.warn('Veuillez confirmer votre mot de passe!')
    }
  }

  useEffect(()=>{
    if (isRestPasswordSuccess) {
      setTimeout(() => {
        history.push('/login')
      }, 3000);
    }
  }, [isRestPasswordSuccess , history])


  return <div className={classes.AuthPage}>
  <Background/>
<div className={classes.auth} >
  <div className={classes.authBox} >
  <div className={classes.h2} >
  <h2  >Réinitialisation du mot de passe oublié</h2>
  </div>
  <form onSubmit={submitRestPassword}  className={classes.authForm}>
  <div className={classes.authInput} >
  <InputForm title='Nouveau Mot de passe:' inRef={NewPassword} name='NewPassword' type='password' />
  <InputForm title='Confirmer mot de pass:' inRef={ConfirmNewPassword} name='ConfirmNewPassword' type='password' />
  </div>
  <div className={classes.button}>
  <Button textColor={'white'}  color={'#0071BC'} btName='Confirmer' />
  <Link className={classes.link} to='/login' ><Button textColor={'white'} color={'#343a40'} btName="Retour" /></Link> 
  </div>
  <>
  </>
  </form>
  </div>


</div>

</div> 
}

export default ResetPassword;