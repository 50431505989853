import React, { useEffect, useRef, useState } from 'react';
import classes from './QrCodePopup.module.scss'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useDispatch, useSelector } from 'react-redux';
import { buyQrCode } from '../../store/user/userActions';
import Notify from '../notification/Notify';
import { Button } from '@mui/material';
import cheque from '../../assets/cheque.png'
import cib from '../../assets/cib.png'
import baridimob from '../../assets/gold-card.png'
import ccp from '../../assets/ccp.png'
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import { userActions } from '../../store/user/userSlice';



function QrCodePopup(props) {

    const dispatch = useDispatch()

    const userOrgKey = useSelector(state => state.auth.user.orgKey)
    const priceQrCode = useSelector(state => state.user.qrCodePrice)
    const satimUrl = useSelector(state => state.user.satimUrl)
    const [paymentTypeValue , setPayType] = useState('')
    const [CAPTCHA ,setValueCaptcha] = useState('')
    const [QrCode , setQrCodeValue] = useState(0)
    const certificatsNumber = useRef()

    const onChangeHandler = (value) => {
        setValueCaptcha(value)
    }

    const paymentHandler = (e)=>{
        if (e.target.checked) {
            setPayType(e.target.value)
        }
    }

    props.CAPTCHA(CAPTCHA)
    
    console.log(satimUrl);
    const inputHandler = ()=>{
        setQrCodeValue(certificatsNumber.current.value * priceQrCode)
    }

    let info = {
        orgKey:props.orgKey ? props.orgKey.value : userOrgKey,
        number:certificatsNumber.current?.value,
        description:'Buy QrCode',
        paymentType:paymentTypeValue
    }
   
    const sumbitQrCode = ()=>{
    
//        if (!CAPTCHA) {
//            return toast.warn('Vérifier votre captcha')
//        }

        if (paymentTypeValue === 'CIB' || paymentTypeValue === 'EDAHABIA') {
            dispatch(buyQrCode(info))
        }else{
            return toast.warn('Ajouter vous information de ccp ou cheque')
        }

        
    }
   console.log(paymentTypeValue);

    useEffect(()=>{

        if (paymentTypeValue === 'CIB' || paymentTypeValue === 'EDAHABIA') {
        if (satimUrl) {
                var link = document.createElement('a');
                link.href = satimUrl;
                link.click();
        }
    }
    },[satimUrl , paymentTypeValue])


    useEffect(()=> {
      
            props.getData(info)
        
    },[info.number , info.paymentType])

  return <div className={props.notPop ? classes.Demander : classes.notPopUp} > 
        
       {props.closeSettingMenuValue &&  <Notify/>}
       <div className={classes.DemanderFirstBox} >
       <div className={classes.DemanderBox} >
                <div className={classes.DemanderInput} >
                    <div className={classes.title} >
                        <p>Demander des codes QR</p>
                    </div>
                    <div  className={classes.input} > 
                    <AddBoxIcon sx={{color:'#0071BC' , fontSize:"45px"}} />
                        <input onChange={inputHandler} min='0'  type='number' ref={certificatsNumber} name={"certificatsNumber"} placeholder='Nombre de certificats'  />
                    </div>
                    <div  > 
                        <AttachMoneyIcon sx={{color:'#0071BC' , fontSize:"45px"}} />
                        <div className={classes.price} >
                            <h3>{QrCode}</h3>
                        </div>
                        <span>DA</span>
                      
                    </div>
                </div >
               {/* <ReCAPTCHA
                    sitekey="6LfijqwfAAAAAExZKsYAJXr21PTJfBMqSiemWz9z"
                    onChange={onChangeHandler}
                />*/}
                <div className={classes.PaymentMode} >
                        <div className={classes.title} >
                            <CreditCardIcon sx={{color:'#0071BC' , fontSize:"45px"}} />
                            <p>Mode de payment</p>
                        </div>
                        <div onChange={paymentHandler} className={classes.checkbox} >
                            <div className={classes.box1}>
                            <div>
                                <input id='cheque' type='radio' readOnly checked={paymentTypeValue === 'CHEQUE' ? true : false} defaultValue={'CHEQUE'} />
                                <label onClick={() =>  props.OpenCcpFunc(false)} className={paymentTypeValue === "CHEQUE" ? classes.activeLabel : classes.label} htmlFor='cheque' > <img src={cheque} alt='cheque' /> <p>CHEQUE</p> </label>
                            </div>
                            <div>
                                <input id='CIB' type='radio' readOnly checked={paymentTypeValue === 'CIB' ? true : false} defaultValue={'CIB'} />
                                <label className={paymentTypeValue === "CIB" ? classes.activeLabel : classes.label} htmlFor='CIB'> <img src={cib} alt='cheque' /> <p>CIB</p> </label>
                            </div>
                            </div>
                            <div className={classes.box2}>
                            <div>
                                <input id='EDAHABIA' type='radio' readOnly checked={paymentTypeValue === 'EDAHABIA' ? true : false} defaultValue={'EDAHABIA'} />
                                <label className={paymentTypeValue === "EDAHABIA" ? classes.activeLabel : classes.label} htmlFor='EDAHABIA' > <img src={baridimob} alt='cheque' /> <p>EDAHABIA</p></label>
                            </div>
                            <div >
                                <input id='CCP' type='radio' readOnly checked={paymentTypeValue === 'CCP' ? true : false} defaultValue={'CCP'} />
                                <label onClick={() =>  props.OpenCcpFunc(true)} className={paymentTypeValue === "CCP" ? classes.activeLabel : classes.label} htmlFor='CCP' > <img src={ccp} alt='cheque' /> <p>CCP</p></label>
                            </div>
                            </div>
                        </div>
                </div>
                <div className={classes.button} >
                    {!props.notPop && <Button onClick={props.closeMenu} > Annuler</Button>}
                    <Button onClick={sumbitQrCode}> Demander</Button>
                </div>
        </div>
       </div>
   </div>;
}

export default QrCodePopup;
