// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonLists_button__Bu4\\+5 {
  display: flex;
  align-items: center;
  justify-content: space-evenly; }
  .ButtonLists_button__Bu4\\+5 .ButtonLists_color__z5\\+dc {
    color: #0071BC; }
`, "",{"version":3,"sources":["webpack://./src/elements/TablesButton/ButtonLists.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,6BAA6B,EAAA;EAHjC;IAKQ,cAAe,EAAA","sourcesContent":[".button{\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    .color{\n        color: #0071BC ;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ButtonLists_button__Bu4+5`,
	"color": `ButtonLists_color__z5+dc`
};
export default ___CSS_LOADER_EXPORT___;
