import React , {useEffect, useMemo, useState} from 'react';
import classes from './RenderListeDesDemandes.module.scss'
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { useTable , useSortBy ,useGlobalFilter, usePagination } from 'react-table';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import QrCodePopup from '../../elements/QrCodePopup/QrCodePopup';
import Notify from '../../elements/notification/Notify';
import Button from '../../elements/button/Button';
import AddUserPopUp from '../../elements/addUserPopUp/AddUserPopUp';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ValidPayments from '../../elements/validPayment/ValidPayments';
import AddAdmin from '../../elements/addAdmin/AddAdmin';
import CheckValidPayments from '../../elements/checkValidPayment/CheckValidPayments';
import { Helmet, HelmetProvider } from "react-helmet-async";
import ViewRecu from '../../elements/viewRecuImage/ViewRecu';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {Buffer} from 'buffer'
import notFound from '../../assets/imageNotFound.jpg'


function RenderListeDesDemandes(props) {

  window.Buffer = Buffer


  const order = useSelector(state => state.user.order)
  const [base64image , setBase64] = useState('')
  const allOrders = useSelector(state => state.superAdmin.allOrders)
  const [title, setTitle] = useState("");
  const [openRecuImages , setOpenRecuImage] = useState(false)
  const openRecuFunc =()=>{
    setOpenRecuImage(oldValue => !oldValue)
  }

  useEffect(()=>{
    if (order.recu) {
      const buff = new Buffer(order.recu?.data)
      let base64 = buff.toString('base64')
      setBase64(base64)
    }
  },[order.recu])


  console.log(base64image);

const Column = [
  
  {
    Header : 'ID',
    accessor : order => {
       return <CheckValidPayments order={order}/>
    }
  },
  
  {
    Header : 'Utilisateur',
    accessor : data =>{
      return <p> {data.firstName} {data.lastName} </p>
    }
  },
  {
    Header : 'Organisation',
    accessor : 'orgKey'
  },
  {
    Header : 'Date de demande',
    accessor : date => {
        return moment(date.createdAt).format('YYYY MM DD H:MM')
    }
  },
  {
    Header : 'Nomber',
    accessor : 'number'
  },
  {
    Header : 'Mode de paiement',
    accessor : 'paymentType'
  },
  {
    Header : 'Prix Total',
    accessor : 'totalPrice'
  },
  {
    Header:'Recu',
    accessor: data => { 
      return <ViewRecu id={data.orderId} data={data} openRecuFunc={openRecuFunc} />
    }
  },
  {
    Header : 'Paiment',
    accessor : pay => {
      return <ValidPayments pay={pay} />
    }
  },
 
]

console.log(order);


  const data = useMemo(()=> allOrders , [allOrders])
  const columns = useMemo(()=> Column ,[])

  const tableInstance = useTable({
    columns ,
    data
  }, useGlobalFilter , useSortBy , usePagination)



  const {getTableProps , getTableBodyProps , headerGroups , page , nextPage , previousPage  , setPageSize ,canNextPage , gotoPage , pageCount , canPreviousPage , prepareRow , state , setGlobalFilter } = tableInstance
  const {globalFilter , pageIndex , pageSize} = state 

  useEffect(() => {
    const fetchData = async () => {

      // When the data "fetching" process is complete, we will set the title and content
      setTitle("Liste des demandes");
    };

    fetchData();
  }, []);


  return <div className={classes.renderPage}>
   {openRecuImages && <div className={classes.recuImage}>
   <IconButton onClick={openRecuFunc} className={classes.closeIconBox} ><CloseIcon className={classes.closeIcon} /></IconButton>
      <img src={order.recu ? `data:image/png;base64,${base64image}` : notFound} alt={'recu'} />
    </div>}
    <HelmetProvider> <Helmet> <title> {title} </title> </Helmet> </HelmetProvider>
    {props.closeSettingMenuValue && <Notify/>}
    {props.addUserPopUpValue &&  <AddAdmin  closeMenu={props.addUserPopUpFunc} />}
    {props.closeMenuValue &&  <QrCodePopup  closeMenu={props.closeMenu} />}
    <div className={classes.static} >
    <div className={classes.top} >
          <div> <p>Statistiques</p> </div>
    </div>
      <div className={classes.insideStatic} >
          <div>
            <p>NOMBRE DE DEMANDES</p>
            <h3>{allOrders.length}</h3>
          </div>
          <EqualizerIcon className={classes.icone} sx={{width:'100px' , height:'100px' , color:'#DDDFEB'}} />
      </div>
    </div>
    <div className={classes.table} >
    <div className={classes.top} >
          <div> <p> Les demandes traitées </p> </div>
          <div></div>
    </div>
    <div className={classes.insideTable} >
    <div className={classes.userlist} >
    
    <div className={classes.tableBox}>

      <div  className={classes.search} >
            <div className={classes.select} >
                <p>Afficher</p>
                <select value={pageSize} onChange={((e)=>setPageSize(Number(e.target.value)))} >
                {[15 , 30 ,45 , 60 ].map(page => (
                  <option key={page} value={page} > {page} </option>
                ))}
                </select>
                <p>éléments</p>
            </div>
            <div className={classes.searchBar} >
                <p>Rechercher:</p>
                <input value={globalFilter} onChange={((e)=> setGlobalFilter(e.target.value))} />
            </div>
      </div>
    <div className={classes.wrapTable} >
    <table {...getTableProps}  className={classes.tb}>
            <thead  className={classes.head} >
              {headerGroups.map(head => (
                 <tr {...head.getHeaderGroupProps()} >
                      {head.headers.map(colum => (
                            <th className={classes.Trhead} {...colum.getHeaderProps(colum.getSortByToggleProps())} > {colum.render('Header')} 
                              <span>{colum.isSorted ? (colum.isSortedDesc ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> ) : '' }</span>
                            </th>
                      ))}
                </tr>
              ))}
             
            </thead>
          {allOrders.length ?  <tbody className={classes.body} {...getTableBodyProps} >
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr className={classes.Trbody} {...row.getRowProps()} >
                      {
                        row.cells.map(cell => {
                     return   <td className={classes.Tdbody} {...cell.getCellProps()} > {cell.render('Cell')} </td>
                        } )
                      }
                  </tr>
                )
              } )}
              
            </tbody> : <tbody className={classes.message} >
                <tr  >
                    <td>
                         Pas encore de demande
                    </td>
              </tr>
            </tbody>
            }
      </table>

    </div>
      


      <div  className={classes.button}>
          <div className={classes.page} >
            <p>Affichage de l'élement {pageIndex + 1} à {pageSize} sur {pageCount} éléments</p>
          </div>
          <div className={classes.pagination} >
            <Button onClick={(()=> gotoPage(0))} disabled={!canPreviousPage} >Premier</Button>
            <Button disabled={!canPreviousPage}  onClick={(()=> previousPage())} >Précédent</Button>
            <Button>{pageIndex + 1} </Button>
            <Button disabled={!canNextPage}   onClick={(()=> nextPage() )} >Suivant</Button>
            <Button onClick={(()=> gotoPage(pageCount - 1))} disabled={!canNextPage}  >Dernier</Button>
          </div>
      </div>

    </div>
    
    </div>
    </div>
    </div>
  </div>;
}

export default RenderListeDesDemandes;
