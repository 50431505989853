// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ValidPayemnts_icon__JiQEL {
  color: #0071BC; }

.ValidPayemnts_div__dVkEC {
  display: flex;
  align-items: center;
  justify-content: center; }

.ValidPayemnts_invalid__eJWTK {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 40px;
  background-color: #F5222E;
  border-radius: 30px;
  font-weight: bold;
  color: white; }

.ValidPayemnts_valid__BPT7- {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 40px;
  font-weight: bold;
  background-color: #41B883;
  border-radius: 30px;
  color: white; }
`, "",{"version":3,"sources":["webpack://./src/elements/validPayment/ValidPayemnts.module.scss"],"names":[],"mappings":"AACA;EACI,cAAc,EAAA;;AAElB;EACI,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAA;;AAG3B;EACI,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;EACjB,YAAY,EAAA;;AAGhB;EACI,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,YAAY,EAAA","sourcesContent":["\n.icon{\n    color: #0071BC;\n}\n.div{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n}\n.invalid{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 70px;\n    height: 40px;\n    background-color: #F5222E;\n    border-radius: 30px;\n    font-weight: bold;\n    color: white;\n}\n\n.valid{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 70px;\n    height: 40px;\n    font-weight: bold;\n    background-color: #41B883;\n    border-radius: 30px;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `ValidPayemnts_icon__JiQEL`,
	"div": `ValidPayemnts_div__dVkEC`,
	"invalid": `ValidPayemnts_invalid__eJWTK`,
	"valid": `ValidPayemnts_valid__BPT7-`
};
export default ___CSS_LOADER_EXPORT___;
