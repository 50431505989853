// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Alert_Link__S0ps7 {
  text-decoration: none;
  color: #1976D2; }
`, "",{"version":3,"sources":["webpack://./src/elements/dialogsAlert/Alert.module.scss"],"names":[],"mappings":"AAAA;EAEI,qBAAqB;EACrB,cAAe,EAAA","sourcesContent":[".Link{\n\n    text-decoration: none;\n    color: #1976D2 ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Link": `Alert_Link__S0ps7`
};
export default ___CSS_LOADER_EXPORT___;
