import { Button, Input } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserOne, InviteUserOrg } from '../../store/user/userActions'
import classes from './AddAdmin.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { updateAdmin } from '../../store/superAdmin/superAdminActions'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


function EditAdmin(props) {

    const dispatch = useDispatch()
    const id = props.adminId

    const [showPassword , setShowPassword] = useState(false)
    const [canEdit , setCanEdit] = useState(false)

    useEffect(()=>{
        if (id) {
            dispatch(getUserOne(id))            
        }
    },[dispatch , id])

    const canEditFunc = ()=>{
        setCanEdit(oldValue => !oldValue)
    }

    
    const admin = useSelector(state => state.user.user)

    console.log(admin);

    const [data , setData] = useState({
        id,
        email:admin.email,
        nom:admin.lastName,
        prenom:admin.firstName,
        password:admin.password
    })

    console.log(data);

    const dataHandler = (e) => {
        let {name , value} = e.target
        setData(oldValue => {
            return {
                ...oldValue,
                [name]:value
            }
        })

    }
    console.log(data);


    const submitEdit = ()=>{
        
        if (isNaN(id)) {
            return 
        }else{
            dispatch(updateAdmin(data))
        }
    }

  return (
    <div className={classes.background} >
        <div className={classes.addBox}>
        <IconButton onClick={props.closeMenu} className={classes.closeIconBox} ><CloseIcon className={classes.closeIcon} /></IconButton>

            <div className={classes.title}>
                <h3>Modifier un super admin</h3>
                <hr/>
            </div>
            <div className={classes.inputs} >
                <div onChange={dataHandler} className={classes.insideInputs}>
                        <div className={classes.emailInput}>
                            <label>Prénom</label>
                            <Input name='prenom' disabled={canEdit ? false : true} type='text' value={canEdit ? null : admin.firstName} />
                        </div>
                        <div className={classes.emailInput}>
                            <label>Nom</label>
                            <Input name='nom'  disabled={canEdit ? false : true}  type='text'  value={canEdit ? null : admin.lastName} />
                        </div>
                        <div className={classes.emailInput}>
                            <label>E-mail</label>
                            <Input name='email'  disabled={canEdit ? false : true} type='email' value={canEdit ? null :  admin.email}  />
                        </div>
                        <div className={classes.emailInput}>
                            <label>Mot de passe</label>
                            <div className={classes.password}>
                                <Input className={classes.input}  disabled={canEdit ? false : true} name='password'  type={showPassword ? 'text' :'password'}  />
                                {!showPassword?<IconButton  onClick={()=> setShowPassword(oldValue => !oldValue)}> <VisibilityIcon />  </IconButton> : <IconButton onClick={()=> setShowPassword(oldValue => !oldValue)} > <VisibilityOffIcon/> </IconButton> } 
                            </div>
                        </div>
                </div>
                
            </div>
            <div className={classes.button} >
             { canEdit ? <Button onClick={submitEdit} > Envoyer </Button> :  <Button onClick={canEditFunc} > Modifier </Button>}
            </div>
        </div>
    </div>
  )
}

export default EditAdmin