import { Button, IconButton } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react'
import DatePicker from 'react-date-picker';
import classes from './Export.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { exportOrgToExcel, exportUserToExcel } from '../../store/superAdmin/superAdminActions';
import { toast } from 'react-toastify';




function ExportPopUp(props) {

  const dispatch = useDispatch()

  const [value, setValue] = useState(null);
  const [valueEnd, setValueEnd] = useState(null);

  const dateDebut = moment(value).format('yyyy-MM-DD')
  const dateFin = moment(valueEnd).format('yyyy-MM-DD')

  
  const Export = ()=>{
    if (props.isOrg) {
      if (dateDebut !== 'Invalid date' && dateFin !== 'Invalid date') {
      dispatch(exportOrgToExcel(dateDebut , dateFin))
      }else{
        return toast.warn('Selectionner une date')
      }
    }
    if (props.isUsers) {
      if (dateDebut !== 'Invalid date' && dateFin !== 'Invalid date') {

          dispatch(exportUserToExcel(dateDebut , dateFin))
   
         }else{
           return  toast.warn('Selectionner une date')
         }
      
    }
  }
  
  return (

 
      
      <div className={classes.export}>

        <div className={classes.exportbox}>
        <IconButton onClick={props.openExportFunc} className={classes.closeIconBox} ><CloseIcon className={classes.closeIcon} /></IconButton>
            <div className={classes.date}>
            <label> Debut:</label>
            <DatePicker className={classes.picker} onChange={setValue}  format="y-MM-dd" value={value} />
            </div>
            <div className={classes.date}> 
              <label>Fin:</label>
            <DatePicker className={classes.picker} onChange={setValueEnd} format="y-MM-dd" value={valueEnd} />
            </div>
            <div className={classes.button}>
            <Button  onClick={Export} >
              Export
            </Button>
            </div>
          
        </div>
      </div>

  )
}

export default ExportPopUp