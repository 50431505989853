import React , { useMemo, useState} from 'react';
import { useTable  , useGlobalFilter , useSortBy , usePagination } from 'react-table';
import classes from './RenderListeDesOrg.module.scss'
import { IconButton, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '../../elements/button/Button';
import { useSelector } from 'react-redux';
import moment from 'moment';
import BusinessIcon from '@mui/icons-material/Business';
import ListeDesOrgButton from '../../elements/TablesButton/ListeDesOrgButton';
import AddOrg from '../../elements/addOrg(superAdmin)/AddOrg';
import EditOrg from '../../elements/addOrg(superAdmin)/EditOrg';
import CheckPasswordifTrue from '../../elements/checkPassword/CheckPassword';
import Notify from '../../elements/notification/Notify';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExportPopUp from '../../elements/exportPopUp/ExportPopUp';
import { Helmet, HelmetProvider } from "react-helmet-async";







function RenderListDesOrg(props) {

  const [orgKey , setAdminId] = useState()
  const [closePopUp , setclosePopUp] = useState(false)
  const [openExport , setOpenExport] = useState(false)
  const [openOrgAdmin , setOpenOrgAdmin] = useState(false)
  const [openEditOrg , setEditOrg] = useState(false)



  const openExportFunc = ()=>{
    setOpenExport(oldValue => !oldValue)
  }
  const openMenu = ()=>{
    setOpenOrgAdmin(oldValue => !oldValue)
  }
  const openEditMenu = (Key)=> {
    setAdminId(Key)
    setEditOrg(oldValue => !oldValue)
  }

  const closePopUpFunc = (key) => {
    setAdminId(key)
    setclosePopUp(oldValue => !oldValue)
  }

  const allOrg = useSelector(state => state.superAdmin.allOrg)



  const Column = [
    {
      Header : 'Organisation Key',
      accessor : 'orgKey'
    },
    {
      Header : 'Nom',
      accessor : 'name'
  
    },
    {
      Header : 'LinkedIn ID',
      accessor : 'linkedInId'
    },
    {
      Header : 'Nombre QRCodes',
      accessor : 'availableQrCodes',
    },
    {
      Header : 'Date de Création',
      accessor : user => {
        return moment(user.createdAt).format('YYYY MM DD H:MM')
      },
    },
    {
      Header : 'Modifier',
      accessor : user => {
        return <ListeDesOrgButton openPop={closePopUpFunc} orgKey={user.orgKey} OpenMenu={openEditMenu}/>
      },
    },
  
  ]

  const data = useMemo(()=> allOrg , [allOrg])
  const columns = useMemo(()=> Column ,[])

  const tableInstance = useTable({
    columns ,
    data
  } , useGlobalFilter  , useSortBy ,usePagination  ) 

  const {getTableProps , getTableBodyProps , headerGroups , page , nextPage , previousPage , pageOptions , setPageSize ,canNextPage , gotoPage , pageCount , canPreviousPage , prepareRow , state , setGlobalFilter  } = tableInstance


  console.log(orgKey);
 

  const {globalFilter , pageIndex , pageSize} = state 

  return <div className={classes.userlist} >
    <HelmetProvider> <Helmet> <title> Liste des organisations </title> </Helmet> </HelmetProvider>
        {openExport && <ExportPopUp openExportFunc={openExportFunc} isOrg={true} />}
        {props.closeSettingMenuValue && <Notify/>}
        {closePopUp && <CheckPasswordifTrue orgKey={orgKey} ClosePop={closePopUpFunc}/>}
        {openOrgAdmin &&  <AddOrg  orgKey={orgKey} closeMenu={openMenu} />}
        {openEditOrg && <EditOrg orgKey={orgKey} closeMenu={openEditMenu} /> }

    
    <div className={classes.tableBox}>
    <div className={classes.AddButton} >
                  <label> Ajouter Organisation </label>
                  <IconButton onClick={openMenu} className={classes.button}> <BusinessIcon className={classes.color} />  </IconButton>
    </div>
    <IconButton onClick={openExportFunc} className={classes.export} >   <Typography> Export </Typography> <FileDownloadIcon/>  </IconButton> 

      <div  className={classes.search} >
            <div className={classes.select} >
                <p>Afficher</p>
                <select value={pageSize} onChange={((e)=> setPageSize(Number(e.target.value)))} >
                {[15 , 30 ,45 , 60 ].map(page => (
                  <option key={page} value={page} > {page} </option>
                ))}
                </select>
                <p>éléments</p>
            </div>
            <div className={classes.searchBar} >
                <p>Rechercher:</p>
                <input value={globalFilter || ''} onChange={(e => setGlobalFilter(e.target.value))} />
            </div>
      </div>
    <div className={classes.wrapTable} >
    <table id='stat' {...getTableProps}  className={classes.table}>
            <thead  className={classes.head} >
              {headerGroups.map(head => (
                 <tr {...head.getHeaderGroupProps()} >
                      {head.headers.map(colum => (
                            <th className={classes.Trhead} {...colum.getFooterProps(colum.getSortByToggleProps())} > {colum.render('Header')}
                              <span>{colum.isSorted ? (colum.isSortedDesc ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> ) : '' }</span>
                             </th>
                             
                      ))}
                </tr>
              ))}
             
            </thead>
           {allOrg.length > 0 ? <tbody className={classes.body} {...getTableBodyProps} >
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr className={classes.Trbody} {...row.getRowProps()} >
                      {
                        row.cells.map(cell => {
                     return   <td className={classes.Tdbody} {...cell.getCellProps()} > {cell.render('Cell')} </td>
                        } )
                      }
                  </tr>
                )
              } )}
              
            </tbody> :  <tbody className={classes.message} >
                <tr  >
                    <td>
                         Pas encore de organisation
                    </td>
              </tr>
            </tbody>}
      </table>
      
              
    </div>
      <div>

      </div>
      <div  className={classes.button}>
          <div className={classes.page} >
              <p>Affichage de l'élement {pageIndex + 1} à {pageSize} sur {pageOptions.length} éléments</p>
          </div>
          <div className={classes.pagination} >
            <Button onClick={(()=> gotoPage(0))} className={!canPreviousPage && classes.onClick} disabled={!canPreviousPage} >Premier</Button>
            <Button disabled={!canPreviousPage} className={!canPreviousPage && classes.onClick} onClick={(()=> previousPage())} >Précédent</Button>
            <Button>{pageIndex + 1} </Button>
            <Button disabled={!canNextPage} className={!canNextPage && classes.onClick}  onClick={(()=> nextPage() )} >Suivant</Button>
            <Button onClick={(()=> gotoPage(pageCount - 1))} disabled={!canNextPage} className={!canNextPage && classes.onClick} >Dernier</Button>
          </div>
      </div>

    </div>
    
    </div>;
}

export default RenderListDesOrg;
