import React, { useEffect, useState } from 'react';
import NavBar from '../../components/navBar/NavBar';
import SideBar from '../../components/sideBar/SideBar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import classes from './Dashboard.module.scss'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Link, Route, Switch } from 'react-router-dom';
import RenderProfile from '../../components/profile/RenderProfile';
import { useSelector } from 'react-redux';
import FullLoading from '../../elements/full-loading-page/FullLoading';
import RenderAdminDashboard from '../../components/adminDashboard/RenderAdminDashboard';
import RenderListAdmin from '../../components/listeAdmins/RenderListeAdmin';
import RenderListDesOrg from '../../components/listedesorg(superAdmin)/RenderListeDesOrg';
import RenderListeDesDemandes from '../../components/listedesdemandes(superAdmin)/RenderListeDesDemandes';
import RenderParamétrage from '../../components/parametre/RenderParamétrage';
import Page404 from '../404Page/404';
import RenderListDesUsers from '../../components/listeUsers(superAdmin)/RenderListeUsers';
import RenderListeUsers from '../../components/listeUsers(superAdmin)/RenderListeUsers';


export default function SuperAdminDashboard(props) {


  const isLogout = useSelector(state => state.auth.isLogout)


  const [closeMenu , setCloseMenu] = useState(false)
  const [selectValueDashboard , setSelectValue] = useState()
  const [closeSettingMenu , setCloseSettingMenu] =useState(false)


  useEffect(()=>{
    props.selectValueFunc(selectValueDashboard);
  },[selectValueDashboard ,props])

  function close() {
    setCloseMenu(oldValue => !oldValue)
  }

  function SettingsClose() {
    setCloseSettingMenu(oldValue => !oldValue)
  }



  return <div className={classes.Page} >
      
      {isLogout && <FullLoading/>}
        <div className={closeMenu ? classes.menu : classes.hide} >
         <IconButton onClick={close} className={classes.closeMenu} ><CloseIcon className={classes.closecon} sx={{color:'white'}}/></IconButton> 
              <ul>
                <Link to="/Dashboard" onClick={close} className={classes.link} >
                <div >
                <li>Dashboard</li>
                <DashboardIcon/>
                </div>
                </Link>
               <Link to='/Dashboard/admin-list'  onClick={close} className={classes.link} >
               <div>
                <li>Liste des admins</li>
                <CreateNewFolderIcon/>
                </div>
               </Link>
                <Link to='/Dashboard/organisation-list' onClick={close} className={classes.link}>
                <div>
                <li>Liste des org</li>
                <FactCheckIcon/>
                </div>
                </Link>
                <Link to='/Dashboard/users-list' onClick={close} className={classes.link}>
                <div>
                <li>Liste des users</li>
                <FactCheckIcon/>
                </div>
                </Link>
                <Link to='/Dashboard/demandes-list' onClick={close} className={classes.link}>
                <div>
                <li>Liste des demandes</li>
                  <ShoppingCartIcon/>
                </div>
                </Link>
                <Link to='/Dashboard/Paramétrage' onClick={close} className={classes.link} >
                <div>
                <li>Paramétrage</li>
                <InfoIcon/>
                </div>
                </Link>
              </ul>
        </div>
        <SideBar isSuperAdmin={props.isSuperAdmin} closeMenu={close}/>
        <div className={classes.SecondPage}>
          
          <Switch>
            <Route exact path={'/Dashboard'} >
              <NavBar isSuperAdmin={true} page={"Dashboard"} selectValue={setSelectValue}  closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu}/>
              <RenderAdminDashboard  closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu} />
            </Route>
            <Route   path={'/Dashboard/admin-list'} >
              <NavBar isSuperAdmin={true}  page={"Liste des admins"} selectValue={setSelectValue} closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu}   />
              <RenderListAdmin selectValueToAdd={selectValueDashboard} closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu}/>
            </Route>
            <Route path={'/Dashboard/organisation-list'} >
              <NavBar isSuperAdmin={true}  page={"Liste des organisations"} selectValue={setSelectValue} closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu} />
              <RenderListDesOrg selectValueToList={selectValueDashboard} closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu} />
            </Route>
            <Route path={'/Dashboard/demandes-list'} >
              <NavBar isSuperAdmin={true}  page={"Liste des demandes"} selectValue={setSelectValue} closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu} />
              <RenderListeDesDemandes selectValueToOrgInfo={selectValueDashboard} closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu} />
            </Route>
            <Route path={'/Dashboard/Paramétrage'} >
              <NavBar isSuperAdmin={true}  page={"Paramétrage"} selectValue={setSelectValue}  closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu} />
              <RenderParamétrage selectValueToOrgInfo={selectValueDashboard} closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu} />
            </Route>
            <Route path={'/Dashboard/profile'} >
              <NavBar isSuperAdmin={true}  page={"Profile"} selectValue={setSelectValue} closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu} />
              <RenderProfile isSuperAdmin={true} closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu} />
            </Route>
            <Route path={'/Dashboard/users-list'} >
              <NavBar isSuperAdmin={true}  page={"Liste des Utilisateur"} selectValue={setSelectValue} closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu}   />
              <RenderListeUsers closeSettingMenu={SettingsClose} closeSettingMenuValue={closeSettingMenu} />
            </Route>
            <Route path={'*'} >
              <Page404/>
            </Route>
          </Switch>
        </div>
    </div>;
}
