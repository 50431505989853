import React, { useEffect, useState } from 'react'
import { Rings } from 'react-loader-spinner'
import { useSelector } from 'react-redux'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

function LoadingRing(props) {
  
    const haveImages = props.certificates.hasImages
    const emailStatus = props.certificates.emailStatus
    const isLoading = props.certificates.imageIsUploading


  return (
    <div style={{display:'flex' , alignItems:"center", justifyContent:'center'}} >

  { haveImages  ? <CheckCircleIcon  style={{color:'#07bc0c'}} />
            : isLoading  ? <LoadingRing /> : <ErrorIcon style={{color:'#e74c3c'}} />}
</div>
  )
}

export default LoadingRing