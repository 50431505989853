import React, { useEffect, useState } from "react";
import classes from "./Steps.module.scss";
import MatrialUiBtn from "@mui/material/Button";
import Notify from "../../../elements/notification/Notify";
import AddUserPopUp from "../../../elements/addUserPopUp/AddUserPopUp";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import InputsAddCert from "../../../elements/addInputs/InputsAddCert";
import AT01ByBrenco from "../../../elements/AT01-template/AT01ByBrenco";
import {
  addCertficates,
  addImages,
  uploadImages,
  notifySignataires
} from "../../../store/certficate/certficateActions";
import { certificateActions } from "../../../store/certficate/certficateSlice";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

function StepTwoAdd(props) {
  
  const orgInfo = useSelector((state) => state.user.org);
  const selectedUserIds = useSelector(
    (state) => state.certificate.selectedUserIds
  );
  const certficate = useSelector((state) => state.certificate.certificates);

  // console.log(certficate);
  console.log(selectedUserIds);

  const dispatch = useDispatch();

  const orgKey = props.selectValueToAdd?.value;

  const addOne = useSelector((state) => state.certificate.addOne);
  const [selectOptionRadio, setSelectOptionRadio] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [data, setData] = useState({});
  const [getGender, setGender] = useState("");
  const [getSingatiare, setSingatire] = useState();
  const [ExperyDate, setCheckHasExperyDate] = useState();
  const [images, setImages] = useState(null);
  const [selectTypeProject, setSelectType] = useState(null);
  const [selectTypeOrganisme, setSelectTypeOrganisme] = useState(null);
  const [selectTypeContribution, setSelectTypeContribution] = useState(null);

  console.log(getSingatiare);

  const radioHandler = (e) => {
    setSelectOptionRadio(e.target.value);
  };

  let formData = new FormData();
  formData.append("images", images && images[0]);
  formData.append("images", images && images[1]);

  const sumbitCertficate = () => {
    if (!selectedOption) {
      return toast.warn("Choisir le type du document");
    }
    if (!selectOptionRadio) {
      return toast.warn("Choisir une option");
    }

    dispatch(
      addCertficates(
        data,
        getGender,
        orgKey,
        selectedUserIds,
        ExperyDate,
        selectedOption.value,
        selectOptionRadio,
        selectTypeProject?.value,
        selectTypeOrganisme?.value,
        selectTypeContribution?.value,
        getSingatiare
      )
    );
    
  };

  useEffect(() => {
    if (certficate?.certificateId) {
      dispatch(notifySignataires(certficate.certificateId));
      window.location.href = `certificates/${certficate.certificateId}`;
    }
  }, [dispatch, certficate?.certificateId]);

  const labelFunc = (orgKey) => {
    switch (orgKey) {
      case "AT01":
        return "Attestation de formation";
      case "AT02":
        return "Attestation d'incubation";
      case "AT03":
        return "Attestation de participation";
      case "AT04":
        return "Attestation de travail";
      case "CR01":
        return "Compte Rendu d'Analyse Epidémiologique";
      case "IN01":
        return "Invitation Evènement";
      case "CT01":
        return "Attestation d'adhésion";
      case "AT05":
        return "Certificat de Labélisation";
      default:
        break;
    }
  };
  const SwitchFunc = (orgKey) => {
    switch (orgKey) {
      case "AT01":
        return (
          <InputsAddCert
            expiryDate={setCheckHasExperyDate}
            gender={setGender}
            getData={setData}
            typeDoc={selectedOption.value}
            orgKey={props.selectValueToAdd}
            inputOne={"Nom"}
            inputTwo={"Prénom"}
            inputThree={"Email"}
            inputFour={"Nom de la formation"}
            inputFive={"Nom du formateur"}
            inputSix={"Nom de l'organisme de formation"}
            inputSeven={"Date de la fin de formation"}
            inputNine={"Date de fin de validité du document"}
            AT01={true}
          />
        );
      case "AT02":
        return (
          <InputsAddCert
            expiryDate={setCheckHasExperyDate}
            gender={setGender}
            typeDoc={selectedOption.value}
            getData={setData}
            orgKey={props.selectValueToAdd}
            inputOne={"Nom du CEO"}
            inputTwo={"Nom de la startup"}
            inputThree={"Email"}
            inputFour={"Nom de l'incubateur"}
            inputFive={"Numéro du label de l'incubateur"}
            inputSeven={"Date du début de l'incubation"}
            inputNine={"Date de fin de validité du document"}
            AT02={true}
          />
        );
      case "AT03":
        return (
          <InputsAddCert
            expiryDate={setCheckHasExperyDate}
            gender={setGender}
            typeDoc={selectedOption.value}
            getData={setData}
            orgKey={props.selectValueToAdd}
            inputOne={"Nom"}
            inputTwo={"Prénom"}
            inputThree={"Email"}
            inputFour={"Nom de l'evenement"}
            inputFive={"Lieu de l'evenement"}
            inputSix={"Nom de l'organisateur"}
            inputSeven={"Date de début l'evenement"}
            inputNine={"Date de fin de validité du document"}
            participant={"Type de participation (participant)"}
            AT03={true}
          />
        );
      case "AT04":
        return (
          <InputsAddCert
            expiryDate={setCheckHasExperyDate}
            gender={setGender}
            typeDoc={selectedOption.value}
            getData={setData}
            orgKey={props.selectValueToAdd}
            inputOne={"Nom du salarié/collaborateur"}
            inputTwo={"Prénom du salarié/collaborateur"}
            inputThree={"Email"}
            inputFour={"Poste de travail"}
            inputFive={"Nom de la societé"}
            inputSeven={"Date du début du contrat de travail"}
            inputNine={"Date de fin de validité du document"}
            emailContact={"Email contact de la societé"}
            NumberTelephone={"Numéro de téléphone de la societé"}
            NumberCNAS={"Numero CNAS de l'employeur"}
            BrithDay={"Date de naissance"}
            AT04={true}
          />
        );
      case "CR01":
        return (
          <InputsAddCert
            expiryDate={setCheckHasExperyDate}
            gender={setGender}
            typeDoc={selectedOption.value}
            getData={setData}
            orgKey={props.selectValueToAdd}
            inputOne={"Nom"}
            inputTwo={"Prénom"}
            inputThree={"Age"}
            inputFour={"Email"}
            inputFive={"Nom du laboratoire"}
            inputSix={"Numéro de l'agréement du ministère de la santé"}
            inputSeven={"Date du prélèvement"}
            inputNine={"Date de fin de validité du document"}
            emailContact={"Nom du médecin traitant"}
            NumberTelephone={"Numéro téléphone du laboratoire"}
            NumberCNAS={"Email contact du laboratoire"}
            BrithDay={"Date de naissance"}
            Typedutest={"Type du test"}
            Maladie={"Maladie testee Epidémiologique"}
            Résultat={"Résultat"}
            CR01={true}
          />
        );
      case "IN01":
        return (
          <InputsAddCert
            expiryDate={setCheckHasExperyDate}
            gender={setGender}
            getData={setData}
            typeDoc={selectedOption.value}
            orgKey={props.selectValueToAdd}
            inputOne={"Nom"}
            inputTwo={"Prénom"}
            inputThree={"Email"}
            inputFour={"Nom de l'evenement"}
            inputFive={"Lieu de l'evenement"}
            inputSix={"Nom de l'organisateur"}
            inputSeven={"Date de l'evenement"}
            hours={"Heure de début de l'evenement"}
            inputNine={"Date de fin de validité du document"}
            participant={"Type de participation (participant)"}
            IN01={true}
          />
        );
      case "CT01":
        return (
          <InputsAddCert
            typeContribution={setSelectTypeContribution}
            typeOrgisme={setSelectTypeOrganisme}
            expiryDate={setCheckHasExperyDate}
            gender={setGender}
            getData={setData}
            typeDoc={selectedOption.value}
            orgKey={props.selectValueToAdd}
            inputOne={"Nom"}
            inputTwo={"Prénom"}
            inputThree={"Email"}
            inputFour={"Type de membre"}
            inputFive={"Nom de l'organisme d'adhésion"}
            inputSeven={"Date d'adhésion"}
            selectTypeOrganisme={"Type d'organisme"}
            selectContribution={"Type de contribution"}
            inputNine={"Date de fin de validité du document"}
            AT05={true}
          />
        );
      case "AT05":
        return (
          <InputsAddCert
            typeProject={setSelectType}
            expiryDate={setCheckHasExperyDate}
            gender={setGender}
            getData={setData}
            typeDoc={selectedOption.value}
            orgKey={props.selectValueToAdd}
            inputOne={"Nom du CEO"}
            inputTwo={"Email"}
            inputThree={"Type de projet à labéliser"}
            inputFour={"Nom du projet"}
            inputFive={"Numéro du label"}
            inputSeven={"Date d'obtention du label"}
            inputNine={"Date de fin de validité du document"}
            CT01={true}
          />
        );

      default:
        break;
    }
  };

  return (
    <div className={classes.add}>
      {props.closeSettingMenuValue && <Notify />}
      {props.addUserPopUpValue && (
        <AddUserPopUp closeMenu={props.addUserPopUpFunc} />
      )}
      <div className={classes.addBox}>
        <div className={classes.title}>
          <h3>
            Choisir le type du document 1/{orgInfo?.documentsType?.length}
          </h3>
        </div>

        <div className={classes.option}>
          <div className={classes.boxOne}>
            <div className={classes.title}>
              <h3>1- Saisir les données du document</h3>
            </div>
            <div className={classes.select}>
              <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={orgInfo?.documentsType?.map((orgKey) => ({
                  value: orgKey,
                  label: labelFunc(orgKey),
                }))}
              />
            </div>
          </div>
          {SwitchFunc(selectedOption?.value)}
          {addOne && (
            <div onChange={radioHandler} className={classes.boxTwo}>
              <div className={classes.title}>
                <h3>2- Veuillez faire un choix</h3>
              </div>
              {selectedOption?.value === "AT01" && (
                <div>
                  <input
                    type='radio'
                    checked={selectOptionRadio === "AT01" ? true : false}
                    value='AT01'
                  />
                  <label>
                    {" "}
                    Utiliser un model/template de document UPHAWU (Seulement
                    attes. de formation)
                  </label>
                </div>
              )}
              <div>
                <input
                  type='radio'
                  checked={selectOptionRadio === "upload" ? true : false}
                  value='upload'
                />
                <label> Uploader vos propres images de document </label>
              </div>
            </div>
          )}
          {selectOptionRadio === "AT01" && (
            <AT01ByBrenco
              isStepTwo={true}
              dataSingatire={setSingatire}
              sendImages={setImages}
              data={data}
              gender={getGender}
            />
          )}
        </div>

        <div className={classes.button}>
          <MatrialUiBtn
            onClick={sumbitCertficate}
            className={classes.MatrialUiBtnbutton}
          >
            Suivant
          </MatrialUiBtn>
        </div>
      </div>
    </div>
  );
}

export default StepTwoAdd;
