// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gestion_gestion__i0YJq {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around; }
  .gestion_gestion__i0YJq .gestion_icon__9BCmQ {
    color: #0071BC; }
`, "",{"version":3,"sources":["webpack://./src/elements/GestionCertficites/gestion.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,6BAA6B,EAAA;EAJjC;IAMQ,cAAc,EAAA","sourcesContent":[".gestion{\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: space-around;\n    .icon{\n        color: #0071BC;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gestion": `gestion_gestion__i0YJq`,
	"icon": `gestion_icon__9BCmQ`
};
export default ___CSS_LOADER_EXPORT___;
