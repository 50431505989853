// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/homepage_image.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Background_bg__jUDU1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover; }
  .Background_bg__jUDU1 .Background_logo__-ecZ1 {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.781);
    border-radius: 30px;
    width: 90%; }

@media (max-width: 768px) {
  .Background_bg__jUDU1 {
    height: 200px; } }
`, "",{"version":3,"sources":["webpack://./src/elements/background-login/Background.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,gBAAgB;EAChB,yDAAyD;EACzD,sBAAsB,EAAA;EAP1B;IASQ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,4CAA4C;IAC5C,mBAAmB;IACnB,UAAU,EAAA;;AAKlB;EACI;IACI,aAAa,EAAA,EAChB","sourcesContent":[".bg{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    min-height: 100%;\n    background-image: url('../../assets/homepage_image.jpeg');\n    background-size: cover;\n    .logo{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        border: 1px solid white;\n        background-color: rgba(255, 255, 255, 0.781);\n        border-radius: 30px;\n        width: 90%;\n    \n    }   \n}\n\n@media (max-width:768px) {\n    .bg{\n        height: 200px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": `Background_bg__jUDU1`,
	"logo": `Background_logo__-ecZ1`
};
export default ___CSS_LOADER_EXPORT___;
