
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import classes from './App.module.scss';
import InValidPayments from './components/InValidPayments/InValidPayments';
import ValidPayments from './components/validPayment/ValidPayments';
import PublicCert from './components/viewCert/PublicCert';
import DownloadCertficates from './elements/downloadCertfiactes/DownloadCertficates';
import Page404 from './pages/404Page/404';
import Dashboard from './pages/Dashboard/Dashboard';
import SuperAdminDashboard from "./pages/isSuperAdmin/SuperAdminDashboard";
import JoinOrg from './pages/Login_Page/joinOrg/JoinOrg';
import Login from './pages/Login_Page/Login';
import OrgkeyForget from './pages/Login_Page/orgKeyForget/OrgkeyForget';
import PasswordForget from './pages/Login_Page/passwordForget/PasswordForget';
import ResetPassword from './pages/Login_Page/resetPassword/ResetPassword';
import MultiStepForm from './pages/Signup_Page/SignUp';
import { checkToken } from './store/auth/authActions';
import { getAllCertficates, getOneCertificate } from './store/certficate/certficateActions';
import { createSuperAdmin, getAllOrg, getAllUsers , getAllOrders, getAllCertficates as getAllCertificates } from './store/superAdmin/superAdminActions';
import { getAllUser, getUserOne , getQrCodePrice, getOneOrg } from './store/user/userActions';


function App() {

  const dispatch = useDispatch()
  const isAuth = useSelector(state => state.auth.isAuth)
  const user = useSelector(state => state.auth.user)
  const imagesUploadSuccess = useSelector(state => state.certificate.imagesUploadSuccess)
  const isSuperAdmin = useSelector(state => state.superAdmin.isSuperAdmin)
  let localStorageToken = localStorage.getItem('token')
  const [selectValue , setSelectValue] = useState()
  const [openCert , setOpenCert] = useState(false)
  const [isPublic , setIsPublic] = useState()

  const openDownLoadCert = () => {
    setOpenCert(oldValue => !oldValue)
}

console.log(imagesUploadSuccess);

  useEffect(()=>{
    if (localStorageToken) {
      dispatch(checkToken(localStorageToken))
    } 


  }, [localStorageToken , dispatch])

  console.log(selectValue);
  useEffect(()=>{

    dispatch(getAllCertficates(selectValue?.value))

  },[ imagesUploadSuccess,selectValue?.value,dispatch])



  useEffect(()=>{

    if (selectValue) {
      dispatch(getOneOrg(user?.orgKey))
    } else{
      dispatch(getOneOrg(selectValue?.value))
    }

  }, [selectValue?.value , user?.orgKey , dispatch])

  let url = window.location.href
    url = url.split('/')

  useEffect(()=>{

    
    if (url[4] === 'certificates') {
      dispatch(getOneCertificate(url[5]))
    } 
    if (url[4] === 'edit') {
      dispatch(getOneCertificate(url[5]))
    }
  },[dispatch , url])

  useEffect(()=>{

    if (url[3] === 'certificates') {
      dispatch(getOneCertificate(url[4]))
    }
  },[dispatch , url])

  useEffect(()=>{

    if (isSuperAdmin) {
      dispatch(getAllUsers())
    }
    if (isAuth) {
      dispatch(getQrCodePrice())
      dispatch(getAllOrg())
    dispatch(getAllOrders())
    dispatch(getAllCertificates())
    } 
  },[dispatch , isSuperAdmin , isAuth ])


  useEffect(()=>{

    if (!selectValue) {
      dispatch(getAllUser(user.orgKey))
    } else{
      dispatch(getAllUser(selectValue.value))
    }

  }, [selectValue?.value , user, dispatch])
  


 useEffect(()=>{

    dispatch(createSuperAdmin())

 },[dispatch])

  return (
  <div className={classes.app}>
    {openCert &&  <DownloadCertficates isPublic={isPublic} justDownload={true} openDownLoadCert={openDownLoadCert} />}
    
   <ToastContainer/>
      <Switch>
          <Route exact path={'/'}>
            {isAuth ? <Redirect to={'/Dashboard'} /> : <Login/> }
          </Route>
          <Route path={'/Dashboard'}>
          {isAuth ? isSuperAdmin ? <SuperAdminDashboard selectValueFunc={setSelectValue} isSuperAdmin={true} />  : <Dashboard selectValueFunc={setSelectValue} /> : <Login/> }
          </Route>
          <Route   path={'/certificates/:certId'}>
              <PublicCert isPublic={setIsPublic} openDownLoadCert={openDownLoadCert} />
          </Route>
          <Route path={'/join/:email/:orgKey/:role'} >
              <JoinOrg />
          </Route>
          <Route  path={"/payments/success"}>
            {isAuth && <ValidPayments />}
          </Route>
          <Route  path={"/payments/failed"}>
            {isAuth && <InValidPayments />}
          </Route>
          <Route   path={'/login'}>
            {isAuth ?  <Redirect to={'/'} />  : <Login/> }
          </Route>
          <Route   path={'/forget-password'}>
           {isAuth ?<Redirect to={'/'} /> : <PasswordForget/>}
          </Route>
          <Route   path={'/forget-orgKey'}>
            {isAuth ? <Redirect to={'/'} /> :<OrgkeyForget/>}
          </Route>
          <Route   path={'/reset-password'}>
            {isAuth ? <Redirect to={'/'} /> :<ResetPassword/>}
          </Route>
          <Route   path={'/signup'} >
           {isAuth ? <Redirect to={'/'}/> : <MultiStepForm/>}
          </Route>
          <Route   path={'*'} >
           <Page404/> 
          </Route>
         
         
      </Switch>
  </div>
    
  );
}

export default App;
