import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token:'' ,
    user:[],
    willCreate:null,
    currentStep: 1,
    isAuth : false,
    isSignup:false,
    isLogout:false,
    isRestPasswordSuccess:false,
    isLoading : false,
    isOrgKeyValid:false,
    isTokenValid : false,
    orgName:'',
    resetEmail:''
}

const authSlice = createSlice({
    name:'auth' ,
    initialState,
    reducers : { 

        // Signup ---- Steps
        switchStep(state , action){
            state.currentStep = action.payload
        } ,
        willCreate(state , action){
            state.willCreate = action.payload
        }
        ,setIsOrgKeyValid(state , action){
            state.isOrgKeyValid = action.payload
        }
        ,setOrgName(state , action){
            state.orgName = action.payload
        }
        ,setIsSignUp(state , action){
            state.isSignup = action.payload
        },

        // Login ---- Steps 
        setIsAuth(state , action){
            state.isAuth = action.payload
        }
        ,setToken(state , action){
            state.token = action.payload    
        }
        ,
        setIsLoading(state , action){
            state.isLoading = action.payload
        },
        isTokenValid(state , action){
            state.isTokenValid = action.payload
        },
        
        // RestPassword ---- State
        setRestPasswordSuccess(state  , action){
            state.isRestPasswordSuccess = action.payload
        },
        setEmailResetPassword(state , action){
            state.resetEmail = action.payload
        },
        // Logout ----- 
        setIsLogout(state , action){
            state.isLogout = action.payload
        }
        ,
        // User -----
        setUserInfo(state , action){
            state.user = action.payload
        }

    }

})


export const authActions = authSlice.actions
export default authSlice