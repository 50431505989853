// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckBox_checkBox__SvFbh {
  display: flex;
  align-items: center;
  gap: 10px; }
  .CheckBox_checkBox__SvFbh input {
    padding: 10px; }

.CheckBox_inputCheck__0bAxk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  padding-left: 10px;
  border: 1px solid rgba(0, 0, 0, 0.39);
  font-size: 0.8rem;
  height: 30px; }
  .CheckBox_inputCheck__0bAxk input[type=checkbox] {
    width: 1.5em !important;
    font-size: inherit;
    margin: 0; }
`, "",{"version":3,"sources":["webpack://./src/elements/checkbox/CheckBox.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,SAAS,EAAA;EAHb;IAMO,aAAa,EAAA;;AAIpB;EACI,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,SAAS;EACT,kBAAkB;EAClB,qCAAqC;EACrC,iBAAiB;EACjB,YAAY,EAAA;EAThB;IAWQ,uBAAuB;IACvB,kBAAkB;IAClB,SAAS,EAAA","sourcesContent":[".checkBox{\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    input{\n        \n       padding: 10px;\n    }\n}\n\n.inputCheck{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    gap: 30px;\n    padding-left: 10px;\n    border: 1px solid rgba(0, 0, 0, 0.39);\n    font-size: 0.8rem;\n    height: 30px;\n    input[type=checkbox]{\n        width: 1.5em !important;\n        font-size: inherit;\n        margin: 0;\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkBox": `CheckBox_checkBox__SvFbh`,
	"inputCheck": `CheckBox_inputCheck__0bAxk`
};
export default ___CSS_LOADER_EXPORT___;
