import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./Download.module.scss";
import notFound from "../../assets/imageNotFound.jpg";
import QRCode from "react-qr-code";
import Draggable from "react-draggable";
import {
  saveBase64Image,
  saveQrCodePos,
} from "../../store/certficate/certficateActions";
import { Button, IconButton, Slider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AT01ByBrenco from "../AT01-template/AT01ByBrenco";
import domtoimage from "dom-to-image";
import url from "../../url";
import urlUi from "../../urlUi";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DownloadCertficates(props) {
  const justDownload = props.justDownload;
  const isAddMoreOption = props.isAddMoreOption;
  const ref = useRef();
  console.log(props);
  const dispatch = useDispatch();

  const certficate = useSelector((state) => state.certificate.certificates);
  const getCertificates = useSelector(
    (state) => state.certificate.getCertificates
  );
  const certIds = getCertificates.map((cert) => cert.certificateId);

  console.log(certficate, "ids");

  const [position, setPositions] = useState({
    x: certficate.qrCodePosRight ? certficate.qrCodePosRight : 0,
    y: certficate.qrCodePosTop ? certficate.qrCodePosTop : 0,
  });
  const [qrCode, setSize] = useState(certficate.qrCodeSize || 100);

  console.log(position);

  const hendleStop = (e, data) => {
    console.log(e);
    setPositions((oldValue) => {
      return {
        ...oldValue,
        x: Math.round(data.lastX),
        y: Math.round(data.lastY),
      };
    });
  };

  const saveNewQrCodePostion = async () => {
    const dataBase64 = await domtoimage.toJpeg(ref.current);
    if (isAddMoreOption) {
      dispatch(
        saveQrCodePos(certIds, position, qrCode, isAddMoreOption, dataBase64)
      );
    } else {
      dispatch(
        saveQrCodePos(
          certficate.id,
          position,
          qrCode,
          isAddMoreOption,
          dataBase64
        )
      );
    }
  };

  const getSizeValue = (e) => {
    setSize(e.target.value);
  };

  const downloadCertficate = async () => {
    console.log(ref.current);
    if (ref.current === null) {
      return;
    }
    domtoimage.toJpeg(ref.current).then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = `Certificates-${certficate.certificateId}`;
      link.href = dataUrl;
      link.click();
      console.log(dataUrl, "dataurl");
    });
  };

  return (
    <div className={classes.backDrop} >
      {isAddMoreOption && (
        <div className={classes.QRCodeMsg}> Ajouter Votre QrCode </div>
      )}
      <div className={classes.btn}>
        <IconButton onClick={props.openDownLoadCert}>
          {" "}
          <CloseIcon className={classes.closeIcone} />{" "}
        </IconButton>
      </div>

      <div className={justDownload ? classes.buttonDownLoad : classes.button}>
        {!justDownload && (
          <Button onClick={saveNewQrCodePostion} className={classes.btn}>
            {" "}
            Sauvgarder Position QrCode{" "}
          </Button>
        )}
        {certficate.uploadedImage && (
          <Button onClick={downloadCertficate} className={classes.btn}>
            {" "}
            Telecharger Certificat{" "}
          </Button>
        )}
        {!justDownload && (
          <div className={classes.Slider}>
            <p>QrCode</p>
            <Slider
              size='small'
              defaultValue={qrCode}
              aria-label='Small'
              valueLabelDisplay={qrCode}
              min={50}
              max={150}
              onChange={getSizeValue}
            />
          </div>
        )}
      </div>

      {certficate?.uploadedImage &&
      certficate.uploadedImage?.endsWith(".pdf") ? (
        <Document
          inputRef={ref}
          className={classes.pdf}
          onLoadError={(err) => console.log(err)}
          file={url + "/images/Certificates/" + certficate.uploadedImage}
        >
          <Page className={classes.pdfFile} pageNumber={1} scale={0.8} />

          <Draggable
            disabled={props.isPublic}
            onStop={hendleStop}
            bounds='parent'
            defaultPosition={{
              x: position.x,
              y: position.y,
            }}
          >
            <QRCode
              size={qrCode}
              className={classes.QRCode}
              value={urlUi + "/certificates/" + certficate.certificateId}
            />
          </Draggable>
          <p className={classes.idCertificates}>
            Certificat numero : {certficate.certificateNumber}
          </p>
        </Document>
      ) : certficate.templateId ? (
        <AT01ByBrenco />
      ) : (
        <div ref={ref} className={classes.box}>
          <img
            src={
              certficate.uploadedImage
                ? url + "/images/Certificates/" + certficate.uploadedImage
                : notFound
            }
            alt='certficate'
            className={classes.certImages}
          />
          <Draggable
            disabled={props.isPublic}
            onStop={hendleStop}
            bounds='parent'
            defaultPosition={{
              x: position.x,
              y: position.y,
            }}
          >
            <QRCode
              size={qrCode}
              className={classes.QRCode}
              value={urlUi + "/certificates/" + certficate.certificateId}
            />
          </Draggable>

          <p className={classes.idCertificates}>
            Certificat numero : {certficate.certificateNumber}
          </p>
        </div>
      )}

      
    </div>
  );
}

export default DownloadCertficates;
