import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import logo  from '../../assets/logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { getOneOrders, getUserOne } from '../../store/user/userActions'
import moment from 'moment'
import { Button } from '@mui/material'
import ReactToPrint from 'react-to-print';
import "./style.css"


function ValidPayments() {

    const dispatch =useDispatch()
    const search = window.location.search
    const params = new URLSearchParams(search)
    const orderId = params.get('orderId')
    const ref = useRef()
    const order = useSelector(state => state.user.order)
    const user = useSelector(state => state.auth.user)
    const qrCodePrice = useSelector(state => state.user.qrCodePrice)

    console.log(order.paymentType);

    useEffect(()=>{
       
        if (orderId) {
            dispatch(getOneOrders(orderId))
            
        }

    },[orderId  , dispatch])

    useEffect(()=>{
        if (order.userId) {

        dispatch(getUserOne(order.userId))
            
        }
    },[order.userId , dispatch])
  return (
    <div className={'validBox'}>
        <nav className='headerNav'>
            <div className='headerNavlinks' >
                <Link to={"/Dashboard"} >
                 <img src={logo}  alt='logo'  width="175px" />
                </Link>
            </div>
            <ReactToPrint  content={()=> ref.current} trigger={() => <Button> Print a pdf </Button>} />
        </nav>
      <div>
      {order.paymentType === 'CIB' || order.paymentType === 'EDAHABIA' ?  <body className='body' ref={ref}>
    <header id='header' class="clearfix">
      <div id="logo">
        <img src={logo} alt='logo'/>
      </div>
      <div id="company">
        <h2 class="name">UPHAWU</h2>
        <div>Bureaux: 29 Rue Souidani Boudjemaa, Cheraga Alger, 16014.</div>
        <div>+213 023 480 675/+213 551 125 481</div>
        <div><a className='emailto' href="mailto:contact@brenco-algerie.com">
        contact@brenco-algerie.com</a></div>
      </div>
    </header>
    <main className='main'>
      <div id="details" class="clearfix">
        <div id="client">
          <div class="to">FACTURE POUR:</div>
          <h2 class="name">{user.firstName} {user.lastName}</h2>
          <div class="email"><a href={"mailto:"+user.email}>{user.email}</a></div>
        </div>
        <div id="invoice">
          <h1>FACTURE</h1>
          <div class="date">DATE DE FACTURE: {moment(order.createdAt).format("DD-MM-YYYY")}</div>
        </div>
      </div>
      <table className='table' border="0" cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <th class="no">#</th>
            <th class="desc">DESCRIPTION</th>
            <th class="unit">UNIT PRICE</th>
            <th class="qty">QUANTITY</th>
            <th class="total">TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="no">{order.orderId}</td>
            <td class="desc"><h3>QrCode</h3>{order.description}</td>
            <td class="unit">{qrCodePrice+"DA"}</td>
            <td class="qty">{order.number}</td>
            <td class="total">{order.totalPrice+'DA'}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2"></td>
            <td colspan="2">SUBTOTAL</td>
            <td>{order.totalPrice+'DA'}</td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td colspan="2">GRAND TOTAL</td>
            <td>{order.totalPrice+'DA'}</td>
          </tr>
        </tfoot>
      </table>
      <div id="thanks">Thank you!</div>
      <div id="notices">
        <div>NOTICE:</div>
        <div class="notice">Telecharger cet page ou print a pdf</div>
      </div>
    </main>
  </body>
:
  <body className='body' ref={ref}>
    <header id='header' class="clearfix">
      <div id="logo">
        <img src={logo} alt='logo'/>
      </div>
      <div id="company">
        <h2 class="name">UPHAWU</h2>
        <div>Bureaux: 29 Rue Souidani Boudjemaa, Cheraga Alger, 16014.</div>
        <div>+213 023 480 675/+213 551 125 481</div>
        <div><a className='emailto' href="mailto:contact@brenco-algerie.com">
        contact@brenco-algerie.com</a></div>
      
      </div>
    </header>
    <main className='main'>
      <div id="details" class="clearfix">
        <div id="client">
          <div class="to">BON DE COMMANDE POUR:</div>
          <h2 class="name">{order.firstName} {order.lastName}</h2>
          <div class="email"><a href={"mailto:"+user.email}>{user.email}</a></div>
        </div>
        <div id="invoice">
          <h1>FACTURE</h1>
          <div class="date">DATE DE FACTURE: {moment(order.createdAt).format("DD-MM-YYYY")}</div>
          <div> Notre Cheque Numero: 12345678 </div>
          <div> Notre Cheque Clé: 62 </div>
        </div>
      </div>
      <table className='table' border="0" cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <th class="no">#</th>
            <th class="desc">DESCRIPTION</th>
            <th class="unit">UNIT PRICE</th>
            <th class="qty">QUANTITY</th>
            <th class="total">TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="no">{order.orderId}</td>
            <td class="desc"><h3>QrCode</h3>{order.description}</td>
            <td class="unit">{qrCodePrice+"DA"}</td>
            <td class="qty">{order.number}</td>
            <td class="total">{order.totalPrice+'DA'}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2"></td>
            <td colspan="2">SUBTOTAL</td>
            <td>{order.totalPrice+'DA'}</td>
          </tr>
       
          <tr>
            <td colspan="2"></td>
            <td colspan="2">GRAND TOTAL</td>
            <td>{order.totalPrice+'DA'}</td>
          </tr>
        </tfoot>
      </table>
      <div id="thanks">Thank you!</div>
      <div id="notices">
        <div>NOTICE:</div>
        <div class="notice">Telecharger cet page ou print a pdf</div>
      </div>
    </main>
  </body>
  }
      </div>
    </div>
    
  )
}

export default ValidPayments