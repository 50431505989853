import React from 'react';
import AccordionBox from '../../elements/sideBar accordion/Accordion';
import classes from './SideBar.module.scss'
import Logo from '../../assets/logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';

function SideBar(props) {


  return <div className={classes.sidebar}>
      <div className={classes.logo} >
      <Link to={"/"} > <img src={Logo} alt="logo" /> </Link>  
      </div>
      <AccordionBox isSuperAdmin={props.isSuperAdmin} className={classes.accordian}/>
     <IconButton onClick={props.closeMenu} > <MenuIcon className={classes.menu}  /></IconButton>
  </div>;
}

export default SideBar;
