// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.Inputs_boxInputs__YSFtm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1rem; }
  .Inputs_boxInputs__YSFtm .Inputs_select__ksfQ9 {
    gap: 10px;
    display: flex;
    flex-direction: column; }
    .Inputs_boxInputs__YSFtm .Inputs_select__ksfQ9 .Inputs_Input__L2vau {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      width: 100%; }
      .Inputs_boxInputs__YSFtm .Inputs_select__ksfQ9 .Inputs_Input__L2vau .Inputs_inputDurée__Qoq3c {
        border: 1px solid rgba(0, 0, 0, 0.212);
        width: 100px; }
    .Inputs_boxInputs__YSFtm .Inputs_select__ksfQ9 .Inputs_checkBox__tJweu {
      display: flex;
      align-items: center;
      gap: 10px; }
`, "",{"version":3,"sources":["webpack://./src/elements/addInputs/Inputs.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,eAAe,EAAA;EALnB;IAOQ,SAAS;IACT,aAAa;IACb,sBAAsB,EAAA;IAT9B;MAWY,aAAa;MACb,mBAAmB;MACnB,uBAAuB;MACvB,SAAS;MACT,WAAW,EAAA;MAfvB;QAiBgB,sCAAsC;QACtC,YAAY,EAAA;IAlB5B;MAsBY,aAAa;MACb,mBAAmB;MACnB,SAAS,EAAA","sourcesContent":[".boxInputs{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    font-size: 1rem;\n    .select{\n        gap: 10px;\n        display: flex;\n        flex-direction: column;\n        .Input{\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            gap: 30px;\n            width: 100%;\n            .inputDurée{\n                border: 1px solid rgba(0, 0, 0, 0.212);\n                width: 100px;\n            }\n        }\n        .checkBox{\n            display: flex;\n            align-items: center;\n            gap: 10px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxInputs": `Inputs_boxInputs__YSFtm`,
	"select": `Inputs_select__ksfQ9`,
	"Input": `Inputs_Input__L2vau`,
	"inputDurée": `Inputs_inputDurée__Qoq3c`,
	"checkBox": `Inputs_checkBox__tJweu`
};
export default ___CSS_LOADER_EXPORT___;
