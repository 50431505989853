import React, { useEffect, useState } from 'react';
import classes from './NavBar.module.scss'
import avatar from '../../assets/default_avatar.png'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link } from 'react-router-dom';


function NavBar(props) {

  const user = useSelector(state => state.user.user)
  const [selectedOption, setSelectedOption] = useState(null);


  useEffect(()=>{
  props.selectValue(selectedOption)
  }, [selectedOption , props]) 


  return <div className={classes.NavBar}>

      <div className={classes.title} >
        <h3>{props.page}</h3>
      </div>

      <div>
     {!props.isSuperAdmin && <div> <p>Organisation Key</p>  <Select className={classes.select}
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={user.orgKeys?.map(orgKey => ({value:orgKey , label:orgKey}))}
      /></div>}
      </div>
      <div className={classes.avatar} >

        {!props.notPop &&  !props.isSuperAdmin  ? <IconButton onClick={props.addUserPopUpFunc} className={classes.addUser}  >  <PersonAddIcon/> </IconButton> : ''}
        {!props.notPop && !props.isSuperAdmin ? <Link to={"/Dashboard/renew"} className={classes.buyQrCode}  >

         <IconButton className={classes.cartIcone} ><ShoppingCartIcon sx={{color:'white'}} /></IconButton>
          <p  >QrCode</p>
        </Link> : ''}

        <img onClick={props.closeSettingMenu}  src={avatar} alt="logo" />

      </div>
  </div>;
}

export default NavBar;
