import { Button, Input } from '@mui/material'
import React , {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { buyQrCode } from '../../store/user/userActions'
import classes from './AddInfoCCP.module.scss'

function AddInfoCCP(props) {

    const dispatch = useDispatch()
    const history = useHistory()
    const CAPTCHA = props.CAPTCHA
    const order = useSelector(state => state.user.order)
    const stepOrderCommand = useSelector(state => state.user.stepOrderCommand)

    const [data , setData] = useState({
        firstName:'',
        lastName:'',
        ccpOrChequeNumber:'',
        cleCcp:'',
        idNumber:'',
        address:''
    })

    const paymentData = props.data
    const isCcp = props.isCcp

    const dataHandler = (e) => {
        let {name , value} = e.target
        setData(oldValue => {
            return {
                ...oldValue,
                [name]:value
            }
        })    
    }

    const sendChequeInfo = () =>{

        let info ={
            ...paymentData,
            ...data
        }
        dispatch(buyQrCode(info))

    }

    
    console.log(order , 'order');

    useEffect(()=>{
        if (stepOrderCommand === 2) {
            history.push('/payments/success')
        }
    },[stepOrderCommand , history])

  return (
    <div className={classes.infoBox}>
      <div onChange={dataHandler} className={classes.box}>
        <div className={classes.title}>
          <h2>Ajouter les information de votre payment </h2>
        </div>
        <div>
          <Input
            placeholder='Prenom'
            value={data.firstName}
            className={classes.input}
            name='firstName'
            type='text'
          />
        </div>
        <div>
          <Input
            placeholder='Nom'
            value={data.lastName}
            className={classes.input}
            name='lastName'
            type='text'
          />
        </div>
        {isCcp ? (
          <div>
            <Input
              placeholder='numero d`identité nationale'
              type='number'
              value={data.idNumber}
              className={classes.input}
              name='idNumber'
            />
          </div>
        ) : (
          <div>
            <Input
              placeholder='Numero ccp/cheque'
              value={data.ccpOrChequeNumber}
              className={classes.input}
              name='ccpOrChequeNumber'
            />
          </div>
        )}
        {isCcp ? (
          <div>
            <Input
              placeholder='address'
              value={data.address}
              className={classes.input}
              name='address'
            />
          </div>
        ) : (
          <div>
            <Input
              placeholder='Clé ccp/cheque'
              value={data.cleCcp}
              className={classes.input}
              name='cleCcp'
            />
          </div>
        )}
        <div className={classes.button}>
          <Button
            onClick={props.closeCcpFunc}
            className={classes.MatrialUiBtnbutton}
          >
            {" "}
            Annuler
          </Button>
          <Button
            onClick={sendChequeInfo}
            className={classes.MatrialUiBtnbutton}
          >
            {" "}
            commander{" "}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddInfoCCP
