import { IconButton } from '@mui/material'
import React from 'react'
import classes from './ButtonLists.module.scss'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


function ListeDesOrgButton(props) {


  const deleteOrg = ()=>{
    props.openPop(props.orgKey)
  }

  return (
    <div className={classes.button}>
       <IconButton onClick={() => props.OpenMenu(props.orgKey)} >  <EditIcon className={classes.color}/> </IconButton>
        <IconButton onClick={deleteOrg} > <DeleteIcon className={classes.color} /> </IconButton>
    </div>
  )
}

export default ListeDesOrgButton