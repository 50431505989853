import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import instance from "../../axios";
import { authActions } from "../auth/authSlice";
import { certificateActions } from "./certficateSlice";

export const addCertficates = (data , gender , orgKey , selectedUserIds ,ExperyDate ,typeDocuments , optionSelected , typeProject , typeOrganisme , typeContribution , getSingatiare) => async (dispatch) => {
    dispatch(authActions.setIsLoading(true))
    const config = {
        headers:{
           'Authorization' : localStorage.getItem('token')
        }
    }
    
    let allData = {
        data,
        gender,
        orgKey,
        selectedUserIds,
        ExperyDate,
        typeDocuments,
        optionSelected ,
        typeProject,
        typeOrganisme ,
        typeContribution,
        Singatiare:getSingatiare
    }
    console.log(data);

    try {
      

        const res = await instance.post('/certificates' , allData , config)
        console.log(res);
        if (res.data.success) {
            toast.success(res.data.message)
            dispatch(certificateActions.setCertifictes(res.data.certificate))
            dispatch(certificateActions.setDocuments(res.data.doc)) 
            dispatch(authActions.setIsLoading(false))
        }
    } catch (error) {
          if (!error.response.data.success) {
            toast.warning(error.response.data.message)
            console.log(error.response.data.message);
            dispatch(authActions.setIsLoading(false))
          }
    }

}


export const getOneCertificate = (id) => async(dispatch) => {

    const config = {
        headers:{
           'Authorization' : localStorage.getItem('token')
        }
    }

    try {
        const res = await instance.get('/certificates/'+id , config )
        console.log(res);
        if (res.data.success) {
            dispatch(certificateActions.setCertifictes(res.data.certificate))
            dispatch(certificateActions.setDocuments(res.data.docCert[0]))
            
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
            console.log(error.response.data.message);
        }
    }

}


export const getAllCertficates = (orgKey) => async(dispatch) => {

    const config = {
        headers:{
           'Authorization' : localStorage.getItem('token')
        }
    }
    try {
        const res = await instance.get('/certificates/all/'+orgKey , config)
        console.log(res);
        if (res.data.success) {
            dispatch(certificateActions.setGetAll(res.data.certificates))
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
            console.log(error.response.data.message);
        }
    }

}


export const uploadImages = (formData , id)=> async (dispatch) => {
    dispatch(authActions.setIsLoading(true))
    console.log(formData , id);
    const config = {
        headers:{
            'Content-Type': 'multipart/form-data',
           'Authorization' : localStorage.getItem('token')
        }
    }
        try {
           
        
            const res = await instance.post('/certificates/upload/'+id, formData , config)
            console.log(res);
            if (res.data.success) {
                dispatch(authActions.setIsLoading(false))
                toast.success(res.data.message)
                setTimeout(()=>{
                    window.location.reload()
                },3000)
            }
        } catch (error) {
            console.log(error.response.data.message);
            console.log(error);
        }

}



export const uploadFile = (formData , id) => async (dispatch) => {
    dispatch(authActions.setIsLoading(true))
    const config = {
        headers:{
            'Content-Type': 'multipart/form-data',
           'Authorization' : localStorage.getItem('token')
        }
    }

    try {
        const res = await instance.post('/certificates/upload/files/'+id , formData , config)
        console.log(res);
        if (res.data.success) {
            dispatch(authActions.setIsLoading(false))
            toast.success(res.data.message)
            dispatch(certificateActions.setCertifictes(res.data.certificate))
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
        
    } catch (error) {

        if (!error.response.data.success) {
            console.log(error.response.data.message);
        }

    }    
}


export const deleteCertificates = (id) => async (dispatch) => {
    dispatch(authActions.setIsLoading(true))
    const config = {
        headers:{
           'Authorization' : localStorage.getItem('token')
        }
    }


    try {
     
        const res = await instance.delete('/certificates/'+id , config)
        if (res.data.success) {
            dispatch(authActions.setIsLoading(false))
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
    } 
    catch (error) {
        if (!error.response.data.success) {
            console.log(error.response.data.message);
            dispatch(authActions.setIsLoading(false))
        }
    }
}

export const saveQrCodePos = (id , position , size ,isAddMoreOption , dataBase64)=> async (dispatch) => {

    console.log(position);

    let data = {
        certificateIds:id,
        position,
        size,
        isAddMoreOption,
        dataBase64
    }
    console.log(data);
    
    const config = {
        headers:{
           'Authorization' : localStorage.getItem('token')
        }
    }


    try {
        const res = await instance.post('/certificates/qrCode' , data, config)
        console.log(res);
        if (res.data.success) {
            toast.success(res.data.message)
        }
    } catch (error) {
        if (!error.response.data.success) {
            console.log(error.response.data.message);
        }
    }

}



export const modifierCertficates = (data , gender , orgKey , selectedUserIds ,ExperyDate , id , optionSelected , typeProject , typeOrganisme , typeContribution , getSingatiare,noImages) => async (dispatch) => {

    const config = {
        headers:{
           'Authorization' : localStorage.getItem('token')
        }
    }
    let allData = {
        data,
        gender,
        orgKey,
        selectedUserIds,
        ExperyDate,
        optionSelected ,
        typeProject,
        typeOrganisme ,
        typeContribution,
        Singatiare:getSingatiare,
        noImages
    }
    try {
        const res = await instance.put('/certificates/'+id , allData , config)
        console.log('HEREEEEE',res);
        if (res.data.success) {
            toast.success(res.data.message)
        }
    } catch (error) {
          if (!error.response.data.success) {
            console.log('Here', error.response.data.message);
            toast.warn(error.response.data.message)
          }
    }

}


export const addMultipleCertifcates = (data , orgKey , typeDocument , selectedUserIds , ExperyDate) => async (dispatch) => {
    dispatch(authActions.setIsLoading(true))
    const allData = {
        data , orgKey , typeDocument , selectedUserIds , ExperyDate
    }
    const config = {
        headers:{
           'Authorization' : localStorage.getItem('token')
        }
    }
    try {
        const res = await instance.post('/certificates/multiple' , allData , config)
        if (res.data.success) {
            dispatch(certificateActions.setCertifictesAddMultiple(res.data.certificate))
            dispatch(certificateActions.setTypeDocuments(res.data.TypeAttesation))
            dispatch(certificateActions.switchSteps(4))
            dispatch(authActions.setIsLoading(false))
        }
    } catch (error) {
        console.log(error.response.data.message);
        if (!error.response.data.message) {
            dispatch(authActions.setIsLoading(false))
        }
    }

}

export const uploaderLesImages = (file ,id , certId) => async(dispatch) => {

    dispatch(certificateActions.setImageIsUploading(true))

    const formData = new FormData()
    formData.append('images' , file)
    formData.append('array' , certId)

    console.log(id);
    const config = {
        headers:{
           'Content-Type': 'multipart/form-data',
           'Authorization' : localStorage.getItem('token')
        }
    }

    try {
        const res = await instance.post('/certificates/upload/multiple/'+id , formData  , config)
            console.log(res);
        if (res.data.success) {
            dispatch(certificateActions.setTypeDocuments(res.data.TypeAttesation[0]))
            dispatch(certificateActions.setCertifictes(res.data.certificate))
            dispatch(certificateActions.setImageIsUploading(false))
        }

    } catch (error) {
        console.log(error.response.data.message);
    }
}

export const sendCertificateToEmail = ( certficateIds , email , orgKey) => async(dispatch) => {

    const isMultiple = true

    let data = {
        orgKey ,email , certficateIds , isMultiple
    }
    const config = {
        headers:{
           'Authorization' : localStorage.getItem('token')
        }
    }

    try {
        certficateIds.forEach(cert =>  instance.post(`/certificates/send_to_email/${cert}` , data , config).then(res =>{
            if (res.data.success) {
                dispatch(certificateActions.setEmailSentSuccessfully(res.data.success))
                dispatch(certificateActions.setTypeDocuments(res.data.certificate[0]))

            }
            console.log(res);
        }))
    } catch (error) {
        console.log(error.response.data.message);
    }
}

export const sendCertificateToEmailForOne = (certficateIds ,certficateId , email , orgKey) => async(dispatch) => {
        const isMultiple = false
        let data = {
            orgKey , certficateIds , isMultiple, emailStatus:'Envoyé'
        }
        const config = {
            headers:{
               'Authorization' : localStorage.getItem('token')
            }
        }
        try {
            const res = await instance.post(`/certificates/send_to_email/${certficateId}` , data , config)
            console.log(res);
            if (res.data.success) {
                dispatch(certificateActions.setEmailSentSuccessfully(res.data.success))
                dispatch(certificateActions.setTypeDocuments(res.data.certificate[0]))
                toast.success(res.data.message)
            }
        } catch (error) {
            console.log(error.response.data.message);
        }
}

export const getDocumentsData = ()=> async(dispatch) => {
    try {
        const res = await instance.get('/json/documentsData.json')
        if (res) {
            dispatch(certificateActions.setDocumentsData(res.data))
        }
    } catch (error) {
        console.log(error);
    }
} 


export const exportCertificatesToExcel = (typeAttestation,debut , fin) => async(dispatch) => {

    const config = {
        responseType: 'blob',
        headers:{
           'Authorization' : localStorage.getItem('token')
        }
    }

    try {
        const res = await instance.get(`/certificates/export/${typeAttestation}/${debut}/${fin}` , config)
        console.log(res);

        if (res) {
            fileDownload(res.data , 'Certificates.xlsx')
        }
    } catch (error) {
        console.log(error);
        toast.warn(error.response.data.message)
    }
    
}

export const notifySignataires = ( id) => async(dispatch) => {
    
    let data ={
        emailStatus:'Envoyé'
    }
    const config = {
  
        headers:{
           'Authorization' : localStorage.getItem('token')
        }
    }

    try {

        const res = await instance.put('/certificates/notify_sinagtaires/'+id , data , config )
        if (res.data.success) {
            toast.success(res.data.message)
        }
        console.log(res);
    } catch (error) {
        console.log(error);
        toast.warn(error.response.data.message)
    }
}