import React from 'react';
import classes from './Notify.module.scss'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/auth/authActions';
import { getUserOne } from '../../store/user/userActions';

function Notify(props) {

  const dispatch = useDispatch()
  const userAuthId = useSelector(state => state.auth.user.id)  

  const logoutHandler = ()=>{
    dispatch(logout(localStorage.getItem('token')))
    setTimeout(() => {
      localStorage.removeItem('token')
      window.location.replace('/login')
    }, 1000);
  }
  const sendUserId = () => {
    // props.setCloseSettingMenu();
    dispatch(getUserOne(userAuthId))
  }

  return <div className={classes.NotifyBox} >
           <Link onClick={sendUserId} to={"/Dashboard/profile"} className={classes.profileBtn} >
                <AccountCircleIcon/>
                <p>Profile</p>
          </Link> 
            <div className={classes.logoutBtn} >
                <LogoutIcon/>
                <p onClick={logoutHandler} >Deconnecter</p>
            </div>
  </div>;
}

export default Notify;
