// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckValid_checkIf__diGbA {
  display: flex;
  align-items: center;
  justify-content: center; }
  .CheckValid_checkIf__diGbA .CheckValid_box__IA2G\\+ {
    cursor: pointer;
    position: relative;
    background-color: 'primary.dark'; }
    .CheckValid_checkIf__diGbA .CheckValid_box__IA2G\\+ .CheckValid_notify__-Wqns {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 120px;
      height: 50px;
      bottom: 100%;
      left: 0px;
      background-color: rgba(255, 255, 255, 0.801);
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.363);
      -moz-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.39);
      border-radius: 30px; }
`, "",{"version":3,"sources":["webpack://./src/elements/checkValidPayment/CheckValid.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAA;EAH3B;IAKQ,eAAe;IACf,kBAAkB;IAClB,gCAAgC,EAAA;IAPxC;MASY,aAAa;MACb,mBAAmB;MACnB,uBAAuB;MACvB,kBAAkB;MAClB,YAAY;MACZ,YAAY;MACZ,YAAY;MACZ,SAAS;MACT,4CAA4C;MAC5C,gDAA6C;MAC7C,yDAAyD;MACzD,qDAAqD;MACrD,mBAAmB,EAAA","sourcesContent":[".checkIf{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    .box{\n        cursor: pointer;\n        position: relative;\n        background-color: 'primary.dark';\n        .notify{\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            position: absolute;\n            width: 120px;\n            height: 50px;\n            bottom: 100%;\n            left: 0px;\n            background-color: rgba(255, 255, 255, 0.801);\n            box-shadow: 0px 4px 14px 0px rgba(0,0,0,0.75);\n            -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.363);\n            -moz-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.39);\n            border-radius: 30px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkIf": `CheckValid_checkIf__diGbA`,
	"box": `CheckValid_box__IA2G+`,
	"notify": `CheckValid_notify__-Wqns`
};
export default ___CSS_LOADER_EXPORT___;
