import React, { useEffect, useRef, useState } from 'react'
import classes from './PublicCert.module.scss'
import logo from '../../assets/logo.png'
import companyLogo from '../../assets/company-logo.png'
import { Link, useHistory } from 'react-router-dom'
import AT01ByBrenco from '../../elements/AT01-template/AT01ByBrenco'
import { useSelector } from 'react-redux'
import notFound from '../../assets/imageNotFound.jpg'
import { Button } from '@mui/material'
import AlertDialog from '../../elements/dialogsAlert/DialogsAlert'
import domtoimage from 'dom-to-image';
import moment from 'moment'
import url from '../../url'
import { Document, Page, pdfjs } from 'react-pdf';
import Draggable from 'react-draggable'
import QRCode from 'react-qr-code'
import urlUi from '../../urlUi'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { saveAs } from 'file-saver'
import { Integer } from 'read-excel-file'


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PublicCert(props) {

  const certficate = useSelector(state => state.certificate.certificates)
  const certificate = useSelector(state => state.certificate.documentType)
  const [open, setOpenDialogs] = useState(false)

  const forwardedRef = useRef(null)

  useEffect(() => {
    console.log(!certficate.uploadedImage && !certficate.templateId);
    console.log("the certificate values")
    console.log(certificate)
    console.log("the certificate values")
    if (!certficate.uploadedImage && !certficate.templateId) {
      setOpenDialogs(true)
    } else {
      setOpenDialogs(false)
    }
  }, [certficate.uploadedImage, certficate.templateId])


  const downloadCertficate = async () => {

    if (!forwardedRef.current) {
      return
    }
    domtoimage.toJpeg(forwardedRef.current)
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `Certificates-${certficate.id}`;
        link.href = dataUrl;
        link.click();
      });
  }
  console.log(url);
  props.isPublic(true)

  const directDownload = () => {

    if (certficate.hasQrCode) {
      let link = document.createElement('a')
      link.download = `Certificates-${certficate.id}`
      link.href = certficate.hasBaseImage;
      link.click();
    }

  }

  const downloadCert = () => {

    if (certificate.hasQrCode) {
      saveAs(certificate.hasBaseImage, certficate.uploadedImage)
    } else {
      saveAs(`${url}/images/Certificates/${certficate.uploadedImage}`, certficate.uploadedImage)
    }

  }

  const downloadPdf = () => {

    console.log('download');
    saveAs(`${url}/images/Certificates/${certficate.uploadedImage}`, certficate.uploadedImage)
  }







  const swithAT = () => {

    switch (certficate.typeDeDocument) {
      case 'AT01':
        return 'Attestation de formation';
      case 'AT02':
        return "Attestation d'incubation";
      case 'AT03':
        return 'Attestation de participation';
      case 'AT04':
        return 'Attestation de travail';
      case 'CR01':
        return "Compte Rendu d'Analyse Epidémiologique";
      case 'IN01':
        return 'Invitation Evènement';
      case 'CT01':
        return "Certificat de Labélisation ";
      case 'AT05':
        return "Attestation d'adhésion";
      default:
        break;
    }
  }


  const swithMessage = () => {
    console.log(certificate)
    let duree_message = ''
    if(certificate.duree){
    let duree = certificate.duree.split(' ');
    let month = parseInt(duree[0].split('-'));
    let day = parseInt(duree[1].split('-'));
    let hour = parseInt(duree[2].split('-'));
    if(month > 0)
      duree_message += duree[0]
    if(day > 0)
      duree_message += ' ' + duree[1]
    if(hour > 0)
      duree_message += ' ' + duree[2]}
    switch (certficate.typeDeDocument) {
      case 'AT01':
        return (
          <>
            {`Ce certificat atteste que ${certificate.genre ? `${certificate.genre}` : ''} ${certificate.nom ? `${certificate.nom}` : ''}
                    ${certificate.prenom ? `${certificate.prenom}` : ''} 
                    a suivi avec succès le training ${certificate.nom_formation}
                    ${certificate.nom_organisme ? `organisé par ${certificate.nom_organisme}` : ''} 
                    ${certificate.duree ? `, qui à durée ${duree_message}` : ''} 
                    ${certificate.date_fin ? `et qui s'est terminée le ${moment(certificate.date_fin).format('YYYY-MM-DD')} ` : ''}
                    ${certificate.nom_formateur ? `, tel qu enseigné par ${certificate.nom_formateur}` : ''}`}
          </>
        );
      case 'AT02':
        return (
          <>
            {`Cette attestation d'incubation atteste que la startup ${certificate.nom_startup ? `${certificate.nom_startup}` : ''}
                    ${certificate.prenom ? `${certificate.prenom}` : ''} 
                    a été incubé par l'incubateur ${certificate.nom_incubateur}
                    ${certificate.num_incubateur ? `dont le numéro est ${certificate.num_incubateur}` : ''}
                    ${certificate.nom_ceo ? `sous le patronage de  ${certificate.nom_ceo}` : ''}
                    `}
          </>
        );
      case 'AT03':
        return (
          <>
            {`Le certificat ci-dessus atteste que ${certificate.genre ? `${certificate.genre}` : ''}  
                    ${certificate.nom ? `${certificate.nom}` : ''}
                    ${certificate.prenom ? `${certificate.prenom}` : ''}  a participé à l'évènement
                    ${certificate.nom_evenement} ${certificate.nom_organisateur ? `organisé par ${certificate.nom_organisateur}` : ''}  
                    ${certificate.lieu_evenement ? ` et qui s'est déroulé au ${certificate.lieu_evenement}` : ''}
                    ${certificate.date_evenement ? ` le ${moment(certificate.date_evenement).format('YYYY-MM-DD')}` : ''}
                    ${certificate.date_fin ? ` , et a pris fin le ${moment(certificate.date_fin).format('YYYY-MM-DD')},` : ''}
                    en sa qualité de Brenco . Nous vous remercions infiniment pour votre participation!` }
          </>
        );
      case 'AT04':
        return (
          <>
            {`L'attestation ci-dessus atteste que ${certificate.genre ? `${certificate.genre}` : ''} 
                  ${certificate.nom ? `${certificate.nom}` : ''} ${certificate.prenom ? `${certificate.prenom}` : ''}, 
                  ${certificate.date_naissance ? `né(e) le ${moment(certificate.date_naissance).format('YYYY-MM-DD')}` : ''}  
                  ${certificate.matricule ? `dont le matricule est ${certificate.matricule} ` : ''} 
                  ${certificate.date_debut ? `est employé depuis le  ${moment(certificate.date_debut).format('YYYY-MM-DD')}` : ''}
                  ${certificate.poste ? `en qualité de ${certificate.poste} ` : ''} ,
                  ${certificate.nom_societe ? ` au sein de notre société ${certificate.nom_societe} dont le numero employeur CNAS est` : ''}  
                  ${certificate.num_employeur ? ` ${certificate.num_employeur}` : ''} 
                  ${certificate.num_tel_societe ? `joignable sur l'email  ou par téléphone au ${certificate.num_tel_societe}` : ''} 
                  , Cette attestation est délivrée pour valoir et prouver ce que de droit et est valide jusqu’au 24-04-2022` }
          </>
        );
      case 'CR01':
        return (
          <>
            {`Le compte rendu d'analyses médicales visé par le docteur ${certificate.nom_medecin ? `${certificate.nom_medecin}` : ''}  , ci-dessus atteste que 
                ${certificate.genre ? `${certificate.genre}` : ''}  ${certificate.nom ? `${certificate.nom}` : ''}
                ${certificate.prenom ? `${certificate.prenom}` : ''}  , né(e) le ${moment(certificate.date_naissance).format('YYYY-MM-DD')}, 
                ${certificate.age ? `agé(e) de ${certificate.age} ans ` : ''}
                a été testé ${certificate.type_test ? `${certificate.type_test}` : ''} ${certificate.date_prelevement ? `la date du ${moment(certificate.date_prelevement).format('YYYY-MM-DD')}` : ''}
                ${certificate.nom_labo ? ` via un test au sein du laboratoire ${certificate.nom_labo} ` : ''} dont
                ${certificate.num_agreement ? `  le numéro d'agrément du ministère de la sante est ${certificate.num_agreement}` : ''}
                ${certificate.email_labo ? `joignable sur l email ${certificate.email_labo}` : ''} 
                `}
          </>
        );
      case 'IN01':
        return (
          <>
            {`L'invitation ci-dessus atteste que   ${certificate.genre ? `${certificate.genre}` : ''}  ${certificate.nom ? `${certificate.nom}` : ''}
                  ${certificate.prenom ? `${certificate.prenom}` : ''}   
                  ${certificate.nom_evenement ? `est invité(e) à l'évènement ${certificate.nom_evenement}  ` : ''}     
                  ${certificate.nom_organisateur ? `organisé par ${certificate.nom_organisateur}` : ''} 
                  ${certificate.lieu_evenement ? `et qui aura lieu au/a ${certificate.lieu_evenement},` : ''} 
                  , en sa qualité de brecno ! Cette invitation est individuelle et est valide jusqu’au 16-04-2022`}

          </>
        );
      case 'CT01':
        return (
          <>
            {`L'attestation de Membership ci-dessus certifie que ${certificate.genre ? `${certificate.genre}` : ''}  ${certificate.nom ? `${certificate.nom}` : ''}
                  ${certificate.prenom ? `${certificate.prenom}` : ''} 
                  est Membre du ${certificate.typeDeMembre} au sein de notre organisation ${certificate.nom_oragnsime} à 
                  compter du ${moment(certificate.date_adhesion).format('YYYY-MM-DD')} en sa qualité de ${certificate.type_contribution} 
                  . Cette attestation de membership est individuelle et est valide jusqu’au 16-04-2022` }
          </>
        );
      case 'AT05':
        return (
          <>
            {`Le certificat ci-dessus atteste que le label Startup, numéro ${certificate.num_label},
                      de la commission nationale de labélisation des startups,
                      projets innovants et incubateurs a été attribué à la/au Startup, 
                      Mehdi Yanat portée par Mr Mehdi Yanat , 
                      en date du 16-04-2022. Ce certificat est valide jusqu'au 15-04-2022.. 
                      Cette attestation est délivrée pour valoir ce que de droit.`}
          </>
        );
      default:
        break;
    }
  }

  return (


    <div className={classes.documentContainer}>
      <HelmetProvider> <Helmet><title> Uphawu | {certficate?.certificateId || 'Certificates'}  </title> </Helmet></HelmetProvider>

      <AlertDialog open={open} messageOne="Certificate pas trouver" messageTwo="Cet Certficate que vous rechercher elle n'pas disponible" messageThree='Retrun' close={setOpenDialogs} />

      <nav className={classes.headerNav}>
        <div className={classes.headerLogo}>
          <Link to={"/Dashboard"} >
            <img src={logo} alt='logo' width="175px" />
          </Link>
        </div>
        <div className={classes.headerNavlinks}>
          <Link to={'/signup'} target="_blank" className={classes.btn}>
            S'inscrire et commencer à utiliser Uphawu !
          </Link>
          <Link to={'/'} target="_blank" className={classes.btn}>
            Vister le site web Uphawu.com
          </Link>
        </div>
      </nav>


      <div className={classes.documentCertified}>

        <div className={classes.certificateDetails}>
          <div className={classes.detailsCard}>
            <div>
              <img src={companyLogo} alt="Org Logo" />
            </div>
            <h2> {swithAT()}</h2>
          </div>

          <div className={classes.detailsButtons}>
            <Button onClick={!certficate.hasQrCode && certficate.uploadedImage?.endsWith('.pdf') ? downloadPdf : window.matchMedia("(max-width: 768px)").matches ? downloadCert : certficate.hasQrCode ? directDownload : forwardedRef.current ? downloadCertficate : props.openDownLoadCert} className={classes.Link} >
              Télécharger le certificat
            </Button>
          </div>

        </div>

        {certficate.hasQrCode ? <div className={classes.certficate}>
          <div>
            <img alt='certWithQrCode' src={certficate.hasBaseImage} />
          </div>
        </div>
          : (certficate?.uploadedImage && certficate.uploadedImage?.endsWith('.pdf')) ? <Document className={classes.pdf} onLoadError={err => console.log(err)} file={url + '/images/Certificates/' + certficate.uploadedImage}>
            <Page className={classes.pdfFile}
              pageNumber={1} scale={0.60} />


          </Document> : certficate.templateId ? <AT01ByBrenco forwardedRef={forwardedRef} /> : <div className={classes.certficate}>
            <div>
              <img src={certficate.uploadedImage ? url + '/images/Certificates/' + certficate.uploadedImage : notFound} alt='cert' />
            </div>
          </div>
        }
      </div>

      <div className={classes.message} >
        <h2>Félicitations!</h2>
        <p>
          {swithMessage()}
        </p>
      </div>



      <footer className={classes.footer}>
        <p><a href="https://uphawu.com">www.uphawu.com</a> - Solution Authentification de Document - Copyright Uphawu - #Tek2hub Incubated Project</p>
      </footer>
    </div>


  )
}

export default PublicCert