import React from 'react';
import classes from './CheckBox.module.scss'

function CheckBox(props) {
  return <div onChange={props.onChange} className={classes.checkBox} >
  <input  name={props.name} checked={props.onChecked ? props.onChecked : null}  value={props.value} type="checkbox" />
  <p>{props.children}</p>
  </div>
}

export default CheckBox;
