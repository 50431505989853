import { userActions } from './userSlice'
import instance  from "../../axios";
import { toast } from 'react-toastify';
import axios from 'axios';
import { authActions } from '../auth/authSlice';


// -------------- get one user --------------
export const getUserOne = (id ) => async (dispatch)=>{

            try {
                const res = await instance.get(`/users/${id}` , {headers : {Authorization : localStorage.getItem('token')}})
                console.log(res , 'response');
                if (res.data.success) {
                    dispatch(userActions.getUser(res.data.user))
                }
            } catch (error) {
                if (!error.response.data.success) {
                    toast.warn(error.response.data.message)
                }
            }

}
// --------------- get all user -------------------

export const getAllUser = (orgKey)=> async (dispatch)=>{


        try {
            const res = await instance.get('/users/all/'+orgKey , {headers : {Authorization : localStorage.getItem('token')}})
            console.log(res.data);
            if (res.data.success) {
                dispatch(userActions.getAllUsers(res.data.users))
            }
        } catch (error) {
            if (!error.response.data.success) {
                toast.warn(error.response.data.message)
            }
        }
    
    

}

// -------------- update one user -------------------

export const updateOneUser = (id , info)=> async (dispatch) =>{
    console.log(info);
    
    try {
        const res = await instance.put('/users/'+id  ,  info , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
        if (res.data.success) {
            toast.success(res.data.message)
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
        }
    }
}

// --------------- updateUserPassword ------------------

export const updateUserPassword = (info) => async (dispatch)=>{

    try {
        const res = await instance.post('/users/update-password' , info , {headers : {Authorization : localStorage.getItem('token')}})
        if (res.data.success) {
            toast.success(res.data.message)
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
        }
    }
}

// ----------------- updateUserRoles ----------------

export const updateUserRoles = (id,roles) => async (dispatch)=>{
    let role = {
        roles
    }
    try {
        const res = await instance.put('/users/update-roles/'+id , role , {headers : {Authorization : localStorage.getItem('token')}})
        if (res.data.success) {
            toast.success(res.data.message)
        }
    } catch (error) {
        if (!error.response.data.success) {
            console.log(error.response.data.message);
            toast.warn(error.response.data.message)
        }
    }

}


// ----------------- delete User -------------------

export const deleteUserFromOrg = (id)=> async (dispatch)=>{

    try {
        const res = await instance.delete('/users/'+id , {headers : {Authorization : localStorage.getItem('token')}} )
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.href = '/Dashboard/users'
            },3000)
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
        }
    }
}
// ------------- Select and delete user -------------


export const deleteMultiple = (ids)=> async (dispatch)=> {


    try {
        const res = await instance.post('/users/delete-multiple' , ids , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
    } catch (error) {
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
            console.log(error.response.data.message);
        }
    }

}



// ------------------- Buy QrCode ------------------- 


export const buyQrCode = (info)=> async (dispatch) => {
    dispatch(authActions.setIsLoading(true))
    dispatch(userActions.setSwitchStep(1))
    console.log(info);

    try {
        const res = await instance.post('/orders' , info , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res , 'res');

        if (res.data.success) {
            dispatch(authActions.setIsLoading(false))
            toast.success(res.data.message)
            toast.warn('tu dois maintenant fournir un recu dans l`onglet liste de demandes');
            if (res.data.order.paymentType === "CIB") {
                dispatch(userActions.getOrderId(res.data.data.orderId))
                dispatch(userActions.getSatimUrl(res.data.data.formUrl))
                dispatch(authActions.setIsLoading(false))
            }
            if (res.data.order.paymentType === "EDAHABIA") {
                console.log('edhabaia');
                dispatch(userActions.getOrderId(res.data.data.orderId))
                dispatch(userActions.getSatimUrl(res.data.data.checkout_url))
                dispatch(authActions.setIsLoading(false))
            } 
               
                dispatch(userActions.getOneOrder(res.data.order))
                dispatch(userActions.setSwitchStep(2))
        }
        dispatch(userActions.setSwitchStep(1))
    } catch (error) {
        console.log(error.response.data.message);
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
            dispatch(authActions.setIsLoading(false))
            dispatch(userActions.setSwitchStep(1))
        }
    }

}



// ------------------- get all orders -----------------

export const getAllOrders = (orgKey)=> async (dispatch)=>{

    console.log(orgKey);
    try {
        const res = await instance.get('/orders/all/'+orgKey , {headers : {Authorization : localStorage.getItem('token')}})
       
        if (res.data.success) {
            dispatch(userActions.getAllOrder(res.data.orders.reverse()))
        }
    } catch (error) {

        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
        }

    }

}

// ------------------ get one order --------------------

export const getOneOrders = (orderId)=> async (dispatch)=>{


    try {
        const res = await instance.get('/orders/'+orderId , {headers : {Authorization : localStorage.getItem('token')}})
       
        if (res.data.success) {
            dispatch(userActions.getOneOrder(res.data.order))
        }
    } catch (error) {

        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
        }

    }

}

// ------------------ get Org Info ---------------------

export const getOneOrg = (orgKey)=> async (dispatch)=>{
    
    try {
        const res = await instance.get('/organisation/'+orgKey , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res );
        if (res.data.success) {
            dispatch(userActions.getOrg(res.data.org))
        }
    } catch (error) {

        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
            console.log(error.response.data);
        }

    }

}


// ------------------- update org info ------------------

export const updateOrgInfo = (info)=> async (dispatch) =>{

    try {
        const res = await instance.post('/organisation' , info , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
        if (res.data.success) {
            toast.success(res.data.message)
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
        }

    }

}

// ---------------------- Invite User ----------------------

export const InviteUserOrg = (info)=> async (dispatch)=>{

    console.log(info);
    try {
        const res = await instance.post('/users/inviteUser' , info , {headers : {Authorization : localStorage.getItem('token')}} )
        if (res.data.success) {
            toast.success(res.data.message)
            dispatch(userActions.getUserByEmail(res.data.user))
            dispatch(userActions.setInviteSent(true))
        }
    } catch (error) {
        console.log(error.response.data.message);
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
        }
    }
}

//----------------------- accept User invitation --------------

export const acceptInvitation = (data) => async(dispatch) => {

    try {
        const res = await instance.post('/users/acceptInvite' , data , {headers : {Authorization : localStorage.getItem('token')}} )
        if (res.data.success) {
            toast.success(res.data.message)
            dispatch(userActions.setInvitaion(true))
        }
    } catch (error) {
        console.log(error.response.data.message);
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
        }
    }
} 


// -------------------------- getPrice ---------------------

export const getQrCodePrice = () => async (dispatch) => {

    try {
        const res = await instance.get('/orders/getPrice' , {headers : {Authorization : localStorage.getItem('token')}})
        if (res.data.success) {
            dispatch(userActions.setPrice(res.data.price))
        }
    } catch (error) {
        console.log(error.response.data.message);
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
        }
    }

}


// ------------------------- upload recu -------------------

export const uploadRecu = (formData , id) => async(dispatch) => {

    console.log(id);
    
    const config = {
        headers:{
            'Content-Type': 'multipart/form-data',
           'Authorization' : localStorage.getItem('token')
        }
    }

    dispatch(authActions.setIsLoading(true))

    try {

        const res = await instance.post('/orders/upload/recu/'+id , formData , config )
        if (res.data.success) {
            toast.success(res.data.message)
            dispatch(authActions.setIsLoading(false))
        }
        console.log(res);
    } catch (error) {
        console.log(error.response.data);
        if (!error.response.data.success) {
            toast.warn(error.response.data.message)
            dispatch(authActions.setIsLoading(false))
        }
    }
}