import React, { useEffect, useMemo, useRef, useState } from 'react';
import classes from './RenderPage.module.scss'
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Notify from '../../elements/notification/Notify';
import Button from '../../elements/button/Button';
import MaterialBtn from '@mui/material/Button';
import AddUserPopUp from '../../elements/addUserPopUp/AddUserPopUp';
import { useDispatch, useSelector } from 'react-redux';
import { getOneOrg } from '../../store/user/userActions';
import { deleteCertificates, exportCertificatesToExcel, getAllCertficates, notifySignataires, sendCertificateToEmailForOne } from '../../store/certficate/certficateActions';
import moment from 'moment'
import { IconButton, Typography } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import GestionCertficates from '../../elements/GestionCertficites/gestionCertficates';
import ConfirmDialog from '../../elements/confirmAlert/ConfirmDialogs';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import { toast } from 'react-toastify';
import { Helmet, HelmetProvider } from "react-helmet-async";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AT01ByBrenco from '../../elements/AT01-template/AT01ByBrenco';

const typeAtt = [{ value: 'AT01', label: 'Attestation de formation' }, { value: "AT02", label: "Attestation d'incubation" }, { value: "AT03", label: 'Attestation de participation' }, { value: 'AT04', label: 'Attestation de travail' }, { value: "CR01", label: "Compte Rendu d'Analyse Epidémiologique" }, { value: "IN01", label: 'Invitation Evènement' }, { value: "CT01", label: "Attestation d'adhésion" }, { value: "AT05", label: "Certificat de Labélisation" }]

function RenderPage(props) {

  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const [openCheckDelete, setCheckDelete] = useState(false)
  const [openExport, setOpenExport] = useState(false)
  const [certificateId, setCertifictesId] = useState()
  const [selectedOption, setSelectedOption] = useState(null);
  const [value, setValue] = useState(null);
  const [valueEnd, setValueEnd] = useState(null);
  const [title, setTitle] = useState("");
  const ref = useRef()

  console.log(ref);
  const openPopPassword = () => {
    setCheckDelete(oldValue => !oldValue)
  }

  const submitDelete = () => {

    dispatch(deleteCertificates(certificateId))
    setCheckDelete(oldValue => !oldValue)

  }

  const openExportMenu = () => {

    setOpenExport(oldValue => !oldValue)

  }


  const certificates = useSelector(state => state.certificate.getAllCertficates)

  useEffect(() => {

    if (!props.selectValueToDashBoard) {
      dispatch(getOneOrg(user.orgKey))
    } else {
      dispatch(getOneOrg(props.selectValueToDashBoard.value))
    }

  }, [props, user, dispatch])


  const orgInfo = useSelector(state => state.user.org)

  const notifySingatire = (id) => {

    dispatch(notifySignataires(id))

  }

  const Column = [
    {
      Header: 'ID',
      accessor: 'certificateId'
    },
    {
      Header: 'Type de document',
      accessor: type => {
        switch (type.typeDeDocument) {
          case 'AT01':
            return 'Attestation de formation';
          case 'AT02':
            return "Attestation d'incubation";
          case 'AT03':
            return 'Attestation de participation';
          case 'AT04':
            return 'Attestation de travail';
          case 'CR01':
            return "Compte Rendu d'Analyse Epidémiologique";
          case 'IN01':
            return 'Invitation Evènement';
          case 'AT05':
            return "Certificat de Labélisation ";
          case 'CT01':
            return "Attestation d'adhésion";
          default:
            break;
        }
      }

    },
    {
      Header: 'Email',
      accessor: data => {
        return <p> {data.emailCertificates} </p>
      }
    },
    {
      Header: 'Email status',
      accessor: data => {
        return <div className={classes.emailStatus}>
          {data.emailStatus === "Envoyé" ? <CheckCircleIcon style={{ color: '#07bc0c' }} />
            : <ErrorIcon style={{ color: '#e74c3c' }} />}
          <p> {data.emailStatus} </p>
        </div>
      }
    },
    {
      Header: 'Envoyer',
      accessor: data => {
        return <div className={classes.notify}>
          <MaterialBtn onClick={() => dispatch(sendCertificateToEmailForOne( data.certificateId, data.certificateId, data.emailCertificates))} className={classes.btn}> Envoyer </MaterialBtn>
          {/* <MaterialBtn onClick={() => notifySingatire(data.certificateId)} className={classes.btn}> Envoyer </MaterialBtn> */}
        </div>
      }
    },
    {
      Header: 'Creé par',
      accessor: 'createdBy'
    },
    {
      Header: 'Date',
      accessor: date => {
        return moment(date.createdAt).format('YYYY-MM-DD')
      }
    },
    {
      Header: 'Gestion',
      accessor: data => {
        return <GestionCertficates forwardedRef={ref} openDownLoadCert={props.openDownLoadCert} id={data.certificateId} uploadedImage={data.uploadedImage} certificate={data} getCertificatesId={setCertifictesId} openAlertConfirm={openPopPassword} />
      }
    },

  ]

  useEffect(() => {
    const fetchData = async () => {

      // When the data "fetching" process is complete, we will set the title and content
      setTitle("Dashboard");
    };

    fetchData();
  }, []);


  const data = useMemo(() => certificates, [certificates])
  const columns = useMemo(() => Column, [])



  const tableInstance = useTable({
    columns,
    data,
    initialState: {
      sortBy: [
          {
              id: 'Date',
              desc: true
          }
      ]
  }
  }, useGlobalFilter, useSortBy, usePagination)

  const { getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, setPageSize, canNextPage, gotoPage, pageCount, canPreviousPage, prepareRow, state, setGlobalFilter } = tableInstance
  const { globalFilter, pageIndex, pageSize } = state

  const exportToExcel = () => {

    if (!selectedOption?.value) {
      return toast.warn('Selectionner un type de documents ')
    }
    if (!value || !valueEnd) {
      return toast.warn('Selectionner une date ')

    }
    dispatch(exportCertificatesToExcel(selectedOption.value, moment(value).format('yyyy-MM-DD'), moment(valueEnd).format('yyyy-MM-DD')))

  }

  return <div className={classes.renderPage}>
    <HelmetProvider> <Helmet> <title> {title} </title> </Helmet> </HelmetProvider>
    {openExport &&
      <div className={classes.exportMenu} >
        <div className={classes.exportOption}>
          <div className={classes.closebtn}>
            <IconButton onClick={openExportMenu} className={classes.closeIconBox} ><CloseIcon className={classes.closeIcon} /></IconButton>
          </div>
          <div className={classes.selectBox} > <p>Type de Attestation</p>  <Select className={classes.select}
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={typeAtt.map(att => ({ value: att.value, label: att.label }))}
          /></div>
          <div className={classes.dateBox}>
            <div className={classes.date}>
              <label> Debut:</label>
              <DatePicker className={classes.picker} onChange={setValue} format="y-MM-dd" value={value} />
            </div>
            <div className={classes.date}>
              <label>Fin:</label>
              <DatePicker className={classes.picker} onChange={setValueEnd} format="y-MM-dd" value={valueEnd} />
            </div>
          </div>
          <div className={classes.button}>
            <MaterialBtn onClick={exportToExcel} className={classes.MatrialUiBtnbutton}>Export</MaterialBtn>
          </div>
        </div>

      </div>
    }
    {props.closeSettingMenuValue && <Notify />}
    <ConfirmDialog openConfirm={openCheckDelete} submitDelete={submitDelete} close={openPopPassword} />
    {props.addUserPopUpValue && <AddUserPopUp orgKey={props.selectValueToDashBoard?.value} closeMenu={props.addUserPopUpFunc} />}
    <div>
    </div>
    <div className={classes.static} >
      <div className={classes.top} >
        <div> <p> Consomation de QrCode </p>
          <IconButton className={classes.export} onClick={openExportMenu} >   <label htmlFor='export' > <p> Export </p> <FileDownloadIcon />  </label> </IconButton>
        </div>
      </div>
      <div className={classes.insideStatic} >
        <div>
          <p>CERTIFICATS</p>
          <h3>{certificates.length}</h3>
        </div>
        <EqualizerIcon className={classes.icone} sx={{ width: '50px', height: '50px', color: '#DDDFEB' }} />
      </div>
      <div className={classes.insideStatic} >
        <div>
          <p>RESTANT</p>
          <h3>{orgInfo?.availableQrCodes}</h3>
        </div>
        <EqualizerIcon className={classes.icone} sx={{ width: '50px', height: '50px', color: '#DDDFEB' }} />
      </div>
    </div>
    <div className={classes.table} >
      <div className={classes.top} >
        <div> <p> Liste des documents délivrées </p> </div>
        <div></div>
      </div>
      <div className={classes.insideTable} >
        <div className={classes.userlist} >

          <div className={classes.tableBox}>

            <div className={classes.search} >
              <div className={classes.select} >
                <p>Afficher</p>
                <select value={pageSize} onChange={((e) => setPageSize(Number(e.target.value)))} >
                  {[15, 30, 45, 60].map(page => (
                    <option key={page} value={page} > {page} </option>
                  ))}
                </select>
                <p>éléments</p>
              </div>
              <div className={classes.searchBar} >
                <p>Rechercher:</p>
                <input value={globalFilter} onChange={((e) => setGlobalFilter(e.target.value))} />
              </div>
            </div>
            <div className={classes.wrapTable} >

              <table  {...getTableProps} className={classes.tb}>
                <thead className={classes.head} >
                  {headerGroups.map(head => (
                    <tr {...head.getHeaderGroupProps()} >
                      {head.headers.map(colum => (
                        <th className={classes.Trhead} {...colum.getFooterProps(colum.getSortByToggleProps())} > {colum.render('Header')}
                          <span>{colum.isSorted ? (colum.isSortedDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />) : ''}</span>
                        </th>
                      ))}
                    </tr>
                  ))}

                </thead>
                {certificates.length > 0 ? <tbody className={classes.body} {...getTableBodyProps} >
                  {page.map(row => {
                    prepareRow(row)
                    return (
                      <tr className={classes.Trbody} {...row.getRowProps()} >
                        {
                          row.cells.map(cell => {
                            return <td className={classes.Tdbody} {...cell.getCellProps()} > {cell.render('Cell')} </td>
                          })
                        }
                      </tr>
                    )
                  })}

                </tbody> : <tbody className={classes.message} >
                  <tr  >
                    <td>
                      Pas encore de documents
                    </td>
                  </tr>
                </tbody>}
              </table>

            </div>



            <div className={classes.button}>
              <div className={classes.page} >
                <p>Affichage de l'élement {pageIndex + 1} à {pageSize} sur {pageCount} éléments</p>
              </div>
              <div className={classes.pagination} >
                <Button onClick={(() => gotoPage(0))} disabled={!canPreviousPage} >Premier</Button>
                <Button disabled={!canPreviousPage} onClick={(() => previousPage())} >Précédent</Button>
                <Button>{pageIndex + 1} </Button>
                <Button disabled={!canNextPage} onClick={(() => nextPage())} >Suivant</Button>
                <Button onClick={(() => gotoPage(pageCount - 1))} disabled={!canNextPage}  >Dernier</Button>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>;
}

export default RenderPage;
