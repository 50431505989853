import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { certificateActions } from '../../store/certficate/certficateSlice';
import classes from './Links.module.scss'



function Links(props) {

     const dispatch = useDispatch()

     const resetAdd = ()=>{
          dispatch(certificateActions.switchSteps(1))
     }
  return <div  className={classes.Links} >
       <Link to={props.linkOne} className={classes.Link} >
       <div onClick={resetAdd}  >
            {props.Dashboard}
            <h3>{props.textOne}</h3>
        </div>
       </Link>
       {props.isSuperAdmin && <Link to={props.linkThree} className={classes.Link} >
       <div onClick={resetAdd}  >
            {props.listeUser}
            <h3>{props.textlisteUser}</h3>
        </div>
       </Link>}
       <Link to={props.linkTwo} className={classes.Link} >
       <div onClick={resetAdd} >
            {props.Add}
            <h3>{props.textTwo}</h3>
        </div>
       </Link>

  </div>;
}


export default Links;
