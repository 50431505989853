import { Button, Input } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InviteUserOrg } from '../../store/user/userActions'
import classes from './AddAdmin.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { inviteNewAdmin } from '../../store/superAdmin/superAdminActions'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


function AddAdmin(props) {

    const dispatch = useDispatch()
    const [showPassword , setShowPassword] = useState(false)

    const [data , setData] = useState({
        email:'',
        nom:'',
        prenom:'',
        password:''
    })


    const dataHandler = (e) => {
        let {name , value} = e.target
        setData(oldValue => {
            return {
                ...oldValue,
                [name]:value
            }
        })

    }


    const submitInvite = ()=>{
        
        dispatch(inviteNewAdmin(data))
    }

  return (
    <div className={classes.background} >
        <div className={classes.addBox}>
        <IconButton onClick={props.closeMenu} className={classes.closeIconBox} ><CloseIcon className={classes.closeIcon} /></IconButton>

            <div className={classes.title}>
                <h3>Ajouter un super admin</h3>
                <hr/>
            </div>
            <div className={classes.inputs} >
                <div onChange={dataHandler} className={classes.insideInputs}>
                        <div className={classes.emailInput}>
                            <label>Prénom</label>
                            <Input name='prenom' type='text'  />
                        </div>
                        <div className={classes.emailInput}>
                            <label>Nom</label>
                            <Input name='nom'  type='text'  />
                        </div>
                        <div className={classes.emailInput}>
                            <label>E-mail</label>
                            <Input name='email'  type='email'  />
                        </div>
                        <div className={classes.emailInput}>
                            <label>Mot de passe</label>
                            <div className={classes.password}>
                                <Input className={classes.input} name='password'  type={showPassword ? 'text' :'password'}  />
                                {!showPassword?<IconButton  onClick={()=> setShowPassword(oldValue => !oldValue)}> <VisibilityIcon />  </IconButton> : <IconButton onClick={()=> setShowPassword(oldValue => !oldValue)} > <VisibilityOffIcon/> </IconButton> } 
                            </div>
                        </div>
                </div>
                
            </div>
            <div className={classes.button} >
                <Button onClick={submitInvite} > Ajouter </Button>
            </div>
        </div>
    </div>
  )
}

export default AddAdmin