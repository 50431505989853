import React, { useEffect, useState } from 'react'
import Background from '../../../elements/background-login/Background';
import Button from '../../../elements/button/Button';
import InputForm from '../../../elements/inputForm/InputForm';
import classes from '../Login.module.scss'
import { useHistory} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { acceptInvitation } from '../../../store/user/userActions';

function JoinOrg() {


    const dispatch = useDispatch()
    const history = useHistory()
    const isJoinSuccess = useSelector(state => state.user.inviteAccepted)

    console.log(isJoinSuccess);
    let url = window.location.href
    url = url.split('/')
    console.log(url);
       const [data , setData ]= useState({
        email:'' || url[4],
        password:'',
        orgKey:"" || url[5],
        role:url[6]
       })
    
    useEffect(()=>{

        if (isJoinSuccess) {
            history.push('/login')
        }

    },[isJoinSuccess , history])

    const dataHandler = (e)=>{
        let {name , value} = e.target
        setData(oldValue => {
            return {
                ...oldValue,
                [name]:value
            }
        })
    }

    const acceptInvition = () => {
        console.log('goo');
        dispatch(acceptInvitation(data))
    }


  return (

    

    <div className={classes.AuthPage}>
        <HelmetProvider> <Helmet> <title> Uphawu | join </title> </Helmet> </HelmetProvider>
     <Background/>
 <div className={classes.auth} >
     <div className={classes.authBox} >
     <div className={classes.h2} >
     <h2  >Rejoindre le organisation</h2>
     </div>
     <div    className={classes.authForm}>
     <div onChange={dataHandler} className={classes.authInput} >
     <InputForm title='Adresse Email:' value={data.email} name="email"  type='email'  />
     <InputForm title='Mot de passe:' name='password' type='password'  />
     <InputForm title='Organisation clé:' value={data.orgKey} name='orgKey' type='orgKey' />
     
     </div>
     <div className={classes.button}>
     <Button onClick={acceptInvition} textColor={'white'}  color={'#0071BC'} btName='Rejoindre' />
     </div>
     </div>
     </div>
   
   
 </div>
 
 </div> 
  )
}

export default JoinOrg