import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    
    isSuperAdmin:false,
    allUser:[],
    allOrg:[],
    allOrders:[],
    allCert:[],
    user:[],
    passwordIsChecked:false ,
    qrCodePrice:'',
    orgKey:'',
    validPayment:false

}

const superAdminSlice = createSlice({
    name:'superAdmin' ,
    initialState ,
    reducers : {
      
        setIsSuperAdmin(state , action){
            state.isSuperAdmin = action.payload
        },
        setAllUser(state , action){
            state.allUser = action.payload
        },
        setAllOrg(state , action){
            state.allOrg = action.payload
        },
        setAllOrders(state , action){
            state.allOrders = action.payload
        },
        setOrgKeyForValid(state , action){
            state.orgKey = action.payload
        },
        getUser(state  , action){
            state.user = action.payload
        },
        setPasswordIsChecked (state , action) {
            state.passwordIsChecked = action.payload 
        },
        setPrice(state , action) {
            state.qrCodePrice = action.payload
        },
        getAllCert(state , action){
            state.allCert = action.payload
        },
        setValidPayment(state ,action){
            state.validPayment = action.payload
        }
       
    }
})

export const superAdminActions = superAdminSlice.actions
export default superAdminSlice