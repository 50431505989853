import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Background from '../../../elements/background-login/Background';
import InputForm from '../../../elements/inputForm/InputForm';
import { sendCode, verifyCode } from '../../../store/auth/authActions';
import { authActions } from '../../../store/auth/authSlice';
import classes from './Steps.module.scss'
import { Rings } from  'react-loader-spinner'
import Button from '../../../elements/button/Button';





function StepTwo({ codeVerfication, email }) {

  const dispatch = useDispatch()
  const isSuccess = useSelector(state => state.steps.isStepTwoSuccess)

  const previous = ()=>{
    dispatch(authActions.switchStep(1))
  }

  const codeVerficated = ()=> { 
    console.log('codeVerification', codeVerfication)
    dispatch(verifyCode(email.value , codeVerfication.value))
  }

  const sendNewCode = () => {
    dispatch(sendCode(email.value))
  }
 

  return ( <div className={classes.SignUpSteps}>
    <Background/>
  <div className={classes.SignUp} >
    <div className={classes.SignUpBox} >
    <div className={classes.h2}>
    <h2  >
    Vérifier le code contenu dans votre E-mail 
  </h2>
    </div>
    <div  className={classes.signUpForm}>
    <div className={classes.inputBox} >
  <InputForm  title='Saisir le code:' name='codeVerfication' {...codeVerfication} required={true} />
      <div className={classes.send} >
      <p onClick={sendNewCode}> Envoyer un nouveau code </p>
      </div>
  { isSuccess && <div className={classes.loading} >
        <Rings style color="#F28E1F" height={80} width={80} />
        </div>}
  </div>
  
    <div className={classes.button}>
    <Button onClick={codeVerficated} textColor={'white'}  color={'#0071BC'} > Continuer</Button> 
    <Button onClick={previous} textColor={'white'}  color={'#343a40'} > Retour</Button>
    </div>
    </div>
    </div>
  </div>
  </div> );
}

export default StepTwo;
