import { Button, Input } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AT01ByBrenco from "../../elements/AT01-template/AT01ByBrenco";
import classes from "./ViewCert.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CertfcatesUploaded from "../../elements/Certficte/CertfcatesUploaded";
import DownloadIcon from "@mui/icons-material/Download";
import {
  getOneCertificate,
  uploadFile,
} from "../../store/certficate/certficateActions";
import { Link } from "react-router-dom";
import domtoimage from "dom-to-image";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { Document, Page, pdfjs } from "react-pdf";
import url from "../../url";
import Notify from "../../elements/notification/Notify";
import AddUserPopUp from "../../elements/addUserPopUp/AddUserPopUp";
import QrCodePopup from "../../elements/QrCodePopup/QrCodePopup";
import { Helmet, HelmetProvider } from "react-helmet-async";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ViewCert(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const isSTEP = props.isSTEP;
  const forwardedRef = useRef(null);

  const dispatch = useDispatch();

  const certficate = useSelector((state) => state.certificate.certificates);
  const documentType = useSelector((state) => state.certificate.documentType);

  console.log(certficate, documentType);

  useEffect(() => {
    if (isSTEP) {
      dispatch(getOneCertificate(certficate?.certificateId));
    }
  }, [isSTEP, dispatch, certficate?.certificateId]);

  const switchCert = () => {
    switch (certficate?.typeDeDocument) {
      case "AT01":
        return (
          <div className={classes.info}>
            <p>ID :{certficate?.certificateId}</p>
            <p>Nom formation : {documentType?.nom_formation} </p>
            <p>Nom formateur : {documentType?.nom_formateur} </p>
            <p>Nom organisation : {documentType?.nom_organisme} </p>
          </div>
        );

      case "AT02":
        return (
          <div className={classes.info}>
            <p>ID :{certficate?.certificateId}</p>
            <p>Nom de CEO: {documentType?.nom_ceo} </p>
            <p>Nom de la startup: {documentType?.nom_startup} </p>
            <p>Nom de l'incubateur : {documentType?.nom_incubateur} </p>
          </div>
        );

      case "AT03":
        return (
          <div className={classes.info}>
            <p>ID :{certficate?.certificateId}</p>
            <p>Nom de l'evenement: {documentType?.nom_evenement} </p>
            <p>Lieu de l'evenement : {documentType?.lieu_evenement} </p>
          </div>
        );
      case "AT04":
        return (
          <div className={classes.info}>
            <p>ID :{certficate?.certificateId}</p>
            <p>Nom du salarié : {documentType?.nom} </p>
            <p>Prénom du salarié : {documentType?.prenom} </p>
            <p>Nom de société : {documentType?.nom_societe} </p>
          </div>
        );
      case "AT05":
        return (
          <div className={classes.info}>
            <p>ID :{certficate?.certificateId}</p>
            <p>Nom CEO: {documentType?.nom_ceo} </p>
            <p>Type de projet : {documentType?.type_projet} </p>
            <p>Nom de projet : {documentType?.nom_projet} </p>
          </div>
        );

      case "CR01":
        return (
          <div className={classes.info}>
            <p>ID :{certficate?.certificateId}</p>
            <p>Nom : {documentType?.nom} </p>
            <p>Prénom : {documentType?.prenom} </p>
            <p>Nom du laboratoire: {documentType?.nom_labo} </p>
          </div>
        );
      case "IN01":
        return (
          <div className={classes.info}>
            <p>ID :{certficate?.certificateId}</p>
            <p>Nom de l'evenement : {documentType?.nom_evenement} </p>
            <p>Lieu de l'evenement : {documentType?.lieu_evenement} </p>
            <p>Nom de l'organisateur : {documentType?.nom_organisateur} </p>
          </div>
        );
      case "CT01":
        return (
          <div className={classes.info}>
            <p>ID :{certficate?.certificateId}</p>
            <p>Nom : {documentType?.nom} </p>
            <p>
              Nom de l'organisme d'adhésion : {documentType?.nom_oragnsime}{" "}
            </p>
            <p>Type de contribution : {documentType?.type_contribution} </p>
          </div>
        );

      default:
        break;
    }
  };

  const downloadCertficate = async () => {
    if (!forwardedRef.current) {
      return;
    }
    domtoimage.toJpeg(forwardedRef.current).then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = `Certificates-${certficate?.certificateId}`;
      link.href = dataUrl;
      link.click();
    });
  };

  const UploadFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  console.log(file);

  const uploadFiles = () => {
    let formData = new FormData();
    formData.append("certImage", file);

    dispatch(uploadFile(formData, certficate?.certificateId));
  };

  return (
    <div className={classes.view}>
      <HelmetProvider>
        {" "}
        <Helmet>
          {" "}
          <title> Certificates </title>{" "}
        </Helmet>{" "}
      </HelmetProvider>
      {props.closeSettingMenuValue && <Notify />}
      {props.addUserPopUpValue && (
        <AddUserPopUp
          orgKey={props.selectValueToDashBoard?.value}
          closeMenu={props.addUserPopUpFunc}
        />
      )}
      {props.closeMenuValue && <QrCodePopup closeMenu={props.closeMenu} />}

      <div className={classes.detail} >
        <div className={classes.topBar}>
          <p>Détails du certificate</p>
        </div>
        {switchCert()}
        <hr />
        <div className={classes.button}>
          <p>
            Date de creation :{" "}
            {moment(certficate?.createdAt).format("YYYY-MM-DD")}{" "}
          </p>
          <Link
            to={"/Dashboard/edit/" + certficate?.certificateId}
            className={classes.btn}
          >
            {" "}
            <EditIcon /> Modifier Certificate
          </Link>
          <Link
            to={"/certificates/" + certficate?.certificateId}
            className={classes.btn}
          >
            {" "}
            <FileOpenIcon /> Consulter page public du certificate
          </Link>
        </div>
      </div>
      <div className={classes.button}>
        {certficate?.uploadedImage ? (
          <Button
            onClick={
              forwardedRef.current
                ? downloadCertficate
                : () => props.openDownLoadCert(false)
            }
            className={classes.btn}
          >
            {" "}
            <DownloadIcon />{" "}
            {certficate.templateId === "T01"
              ? "Telecharger le certificat"
              : "Signer le certificat avec QrCode"}
          </Button>
        ) : (
          ""
        )}
      </div>
      <div className={classes.edit} onChange={UploadFileChange}>
              <label htmlFor='contained-button-file'>
                <Input
                  className={classes.input}
                  id='contained-button-file'
                  name='images'
                  type='file'
                />
              </label>
              <Button
                onClick={uploadFiles}
                variant='contained'
                component='span'
                disabled={!file}
              >
                Upload
              </Button>
            </div>{" "}
      <div className={classes.certficate}>
        {certficate?.uploadedImage &&
        certficate.uploadedImage.endsWith(".pdf") ? (
          <div className={classes.pdf}>
            <Document
              className={classes.pdf}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(err) => console.log(err)}
              file={url + "/images/Certificates/" + certficate.uploadedImage}
            >
              <Page
                scale={1}
                className={classes.pdfFile}
                pageNumber={pageNumber}
              />
            </Document>{" "}
            
          </div>
        ) : !certficate?.templateId ? (
          <CertfcatesUploaded />
        ) : (
          <AT01ByBrenco forwardedRef={forwardedRef} isNotStep={true} />
        )}
      </div>
    </div>
  );
}

export default ViewCert;
