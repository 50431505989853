import React from 'react'
import classes from './FullLoading.module.scss'
function FullLoading() {
  return (
    <div className={classes.background}>
        <div className={classes.skChase}>
  <div className={classes.skChaseDot}></div>
  <div className={classes.skChaseDot}></div>
  <div className={classes.skChaseDot}></div>
  <div className={classes.skChaseDot}></div>
  <div className={classes.skChaseDot}></div>
  <div className={classes.skChaseDot}></div>
</div>
    </div>
  )
}

export default FullLoading