import { Button, Input } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InviteUserOrg } from '../../store/user/userActions'
import classes from './OrgMenu.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { addOrgansiation, inviteNewAdmin } from '../../store/superAdmin/superAdminActions'
import DocumentsCheckbox from '../checkbox/documentsCheckBox'


const select = [{ id:"AT01",name:"Attestation de formation" } ,{id:'AT02',name:"Attestation d'incubation" }, {id:'AT03',name:"Attestation de participation" } , {id:'AT04',name:"Attestation de travail"} ,{id:'CR01',name:"Compte Rendu d'Analyse Eplogique" },{id:'IN01',name:"Invitation Evènement" },{ id:'CT01',name:"Certificat de Labélisation" },{id:'AT05',name:"Attestation d'adhésion" }]


function AddOrg(props) {

    const dispatch = useDispatch()

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);


    useEffect(()=>{
        setList(select)
    },[])

    const [data , setData] = useState({
        OrgName:'',
        LinkedIn:'',
        email:'',
    })

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
          setIsCheck([]);
        }
      };

    console.log(data);

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
          setIsCheck(isCheck.filter(item => item !== id));
        }
      };

    const dataHandler = (e) => {
        let {name , value} = e.target
        setData(oldValue => {
            return {
                ...oldValue,
                [name]:value
            }
        })

    }


    const submitInvite = ()=>{
        console.log('clicked');
        dispatch(addOrgansiation(data , isCheck))
    }

  return (
    <div className={classes.background} >
        <div className={classes.addBox}>
        <IconButton onClick={props.closeMenu} className={classes.closeIconBox} ><CloseIcon className={classes.closeIcon} /></IconButton>

            <div className={classes.title}>
                <h3>Ajouter une Organisation</h3>
                <hr/>
            </div>
            <div className={classes.inputs} >
                <div onChange={dataHandler} className={classes.insideInputs}>
                        <div className={classes.emailInput}>
                            <label>Nom de Organisation</label>
                            <Input name='OrgName' type='text'  />
                        </div>
                        <div className={classes.emailInput}>
                            <label>LinkedIn</label>
                            <Input name='LinkedIn'  type='text'  />
                        </div>
                        <div className={classes.emailInput}>
                            <label>Email du Utilisateur</label>
                            <Input name='email'  type='email'  />
                        </div>
            </div>
                <div className={classes.emailInput}>
                            <DocumentsCheckbox   
                            type="checkbox"
                            name="selectAll"
                            id="selectAll"
                            handleClick={handleSelectAll}
                            isChecked={isCheckAll}
                            disabled={true}
                            />
                            {list.map(({id , name})=> (<> <DocumentsCheckbox   key={name}
                              type="checkbox"
                              name={name}
                              id={id}
                              handleClick={handleClick}
                              isChecked={isCheck.includes(id)}
                              disabled={true}
                              />
                            
                              </>
                            ) )}
                        </div>
                
            </div>
            <div className={classes.button} >
                <Button onClick={submitInvite} > Ajouter </Button>
            </div>
        </div>
    </div>
  )
}

export default AddOrg