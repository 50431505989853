import React from "react";
import Background from "../../../elements/background-login/Background";
import InputForm from "../../../elements/inputForm/InputForm";
import classes from "./Steps.module.scss";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendCode } from "../../../store/auth/authActions";
import { Rings } from "react-loader-spinner";
import { useSelector } from "react-redux";
import isEmail from "validator/lib/isEmail";
import { toast } from "react-toastify";
import Button from "../../../elements/button/Button";

function StepOne({ email }) {
  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.steps.isStepOneSuccess);

  const SendCode = () => {
    if (isEmail(email.value)) {
      dispatch(sendCode(email.value));
    } else {
      toast.warn("Email pas valid");
    }
  };

  return (
    <div className={classes.SignUpSteps}>
      <Background />
      <div className={classes.SignUp}>
        <div className={classes.SignUpBox}>
          <div className={classes.h2}>
            {" "}
            <h2>Inscrivez-vous maintenant et commencer à utiliser UPHAWU</h2>
          </div>
          <div className={classes.signUpForm}>
            <div className={classes.inputBox}>
              <InputForm
                {...email}
                title='Address email:'
                name='email'
                required={true}
              />
              {isSuccess && (
                <div className={classes.loading}>
                  <Rings style color='#F28E1F' height={80} width={80} />
                </div>
              )}
            </div>

            <div className={classes.button}>
              <Button onClick={SendCode} textColor={"white"} color={"#0071BC"}>
                {" "}
                Continuer
              </Button>
              <Link to={"/login"} className={classes.Link}>
                <Button textColor={"white"} color={"#343a40"}>
                  {" "}
                  Se Connecter
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepOne;
