import { toast } from "react-toastify";
import instance from "../../axios";
import { superAdminActions } from "./superAdminSlice";
import FileDownload from 'js-file-download'
import { certificateActions } from "../certficate/certficateSlice";

export const createSuperAdmin = ()=> async (dispatch) => {
    try {
        await instance.post('/superAdmin' )
    } catch (error) {
        console.log(error.response.data.message);
    }
}

export const getAllUsers = ()=> async (dispatch)=>{

    try {
        const res = await instance.get('/users' , {headers : {Authorization : localStorage.getItem('token')}})
        if (res.data.success) {
            dispatch(superAdminActions.setAllUser(res.data.allUser))
        }
    } catch (error) {
        console.log(error);
    }

}

export const getAllOrg = ()=> async (dispatch)=>{

    try {

        const  res = await instance.get('/organisation/all' , {headers : {Authorization : localStorage.getItem('token')}} )

        if (res.data.success) {
            dispatch(superAdminActions.setAllOrg(res.data.allOrg))
        }
    } catch (error) {
        console.log(error);
    }

}

export const getAllOrders = ()=> async (dispatch) =>  {

    try {
        const res = await instance.get('/orders' , {headers : {Authorization : localStorage.getItem('token')}} )
        if (res.data.success) {
            dispatch(superAdminActions.setAllOrders(res.data.allOrders))
        }
    } catch (error) {
        console.log(error);
    }

}

export const checkPayment = (id) => async(dispatch) => {

    try {
        const res = await instance.get('/orders/checkPayment/'+id , {headers : {Authorization : localStorage.getItem('token')}})
            if(res.data.success){
                dispatch(superAdminActions.setValidPayment(res.data.success))
            }
            console.log(res);
    } catch (error) {
            if (!error.response.data.success) {
                console.log(error.response.data.message);
            }
    }
}

export const validPayment = (id , data , orgKey)=> async (dispatch) => {
    
    let Data = {
        data
    }
        dispatch(superAdminActions.setOrgKeyForValid(orgKey))
    try {
        const res = await instance.put(`/orders/valid/${id}` , Data , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res.data);
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }
    }

}

export const invalidPayment = (id , data)=> async (dispatch) => {
    console.log(id);
    let Data = {
        data
    }
    try {
        const res = await instance.put(`/orders/invalid/${id}`, Data , {headers : {Authorization : localStorage.getItem('token')}})
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
    } catch (error) {
        console.log(error.response.data);
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }
    }

}


export const inviteNewAdmin = (data) => async (dispatch) => {
    console.log(data);
    try {
        const res = await instance.post('/superAdmin/add' , data , {headers : {Authorization : localStorage.getItem('token')}})
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }
    }


} 


export const updateAdmin = (data) => async (dispatch) => {

    try {
        
        const res = await instance.put('/superAdmin/edit' , data , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
        
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }

    } catch (error) {
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }
    }

}

export const deleteSuperAdmin = (id) => async (dispatch)=>{


    console.log(id);
    try {
        const res = await instance.delete('/superAdmin/delete/'+id , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
        
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
        
    } catch (error) {
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }
    }

}

export const addOrgansiation = (data , list) =>  async (dispatch) => {

    let allData = {
        OrgName:data.OrgName,
        LinkedIn:data.LinkedIn,
        email:data.email,
        documentsType:list
    }

    try {
        const res = await instance.post('/superAdmin/add/org' , allData , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }
    }

}

export const updateOragnsiation = (data , list , orgKey) => async (dispatch) => {

    let allData = {
        orgKey,
        orgName:data.OrgName,
        linkedInId:data.LinkedIn,
        documentsType:list
    }

    try {
        const res = await instance.put('/superAdmin/edit/org' , allData , {headers : {Authorization : localStorage.getItem('token')}})
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }
    }

}


export const deleteOragnisation = (orgKey) => async (dispatch) => {

    console.log(orgKey);

    try {
        const res = await instance.delete('/superAdmin/delete/org/'+orgKey , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
    } catch (error) {
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }
    }

} 


export const addUser = (data , roles , orgKey) => async (dispatch) => {

    let allData = {
        firstName : data.firstName ,
         lastName : data.lastName , 
         email :data.email,
        password:data.password , 
        orgKey : orgKey, 
        roles:roles
    }


    try {
        const res = await instance.post('/superAdmin/add/user' , allData , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }

    } catch (error) {

        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }

    }

}

export const getUserForUpdate = (id) => async (dispatch) => {

    try {

        const res = await instance.get('/superAdmin/get/user/'+id ,{headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
        if (res.data.success) {
            dispatch(superAdminActions.getUser(res.data.user))
        }
    } catch (error) {

        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }

    }

}

export const editUser = (id ,data , roles , user ,orgKeys) => async (dispatch) =>{


    const allData ={

         firstName:data.firstName || user.firstName ,
         lastName:data.lastName || user.lastName,
         email:data.email || user.email , 
         password:data.password || user.password ,  
         orgKey:orgKeys,
         roles

    }
    
    
    try {
        const res = await instance.put('/superAdmin/update/user/'+id , allData ,{headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        } 
    } catch (error) {
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }
    }

}

export const deleteUser =  (id) => async (dispatch) =>{

    console.log(id);
    try {
        const res = await instance.delete('/superAdmin/delete/user/'+id ,  {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
    } catch (error) {
        console.log(error.response.data.message);
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }
    }

}

export const checkPassword = (password) => async (dispatch) =>{

    let data = {
        password
    }
    try {
        
        const res = await instance.post('/superAdmin/checkPassword' , data , {headers : {Authorization : localStorage.getItem('token')}})
        if (res.data.success) {
            toast.success(res.data.message)
            dispatch(superAdminActions.setPasswordIsChecked(true))
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
    } catch (error) {
        console.log(error.response.data.message);
        if (!error.response.data.success) {
            toast.warning(error.response.data.message)
        }
    }

}


export const exportUserToExcel = (debut , fin) => async (dispatch) => {

    let options = {
        responseType: 'blob',

        headers: {
          'Authorization': localStorage.getItem('token'),
        }

      }
      try {
      const res = await instance.get(`/superAdmin/export/${debut}/${fin}` , options)
      console.log(res);
        FileDownload(res.data, 'users.xlsx')
  
      } catch (error) {
        console.log(error.response.data);
      }    
}

export const exportOrgToExcel = (debut , fin) => async (dispatch) => {

    let options = {
        responseType: 'blob',

        headers: {
          'Authorization': localStorage.getItem('token'),
        }

      }
      try {
      const res = await instance.get(`/superAdmin/export/org/${debut}/${fin}` , options)
      console.log(res);
        FileDownload(res.data, 'Organisation.xlsx')
  
      } catch (error) {
        console.log(error.response.data);
      }   
} 

export const updatePriceQrCode = (price) => async (dispatch) =>{
        console.log(price);
    let QrPrice = {
        qrCodePrice:price
    }
    try {
        const res = await instance.post('/superAdmin/updatePrice' , QrPrice , {headers : {Authorization : localStorage.getItem('token')}} )
        if (res.data.success) {
            toast.success(res.data.message)
            setTimeout(()=>{
                window.location.reload()
            },3000)
        }
    } catch (error) {
        console.log(error.response.data);
    }
}


export const getAllCertficates = () => async (dispatch) => {

    try {
        const res = await instance.get('/superAdmin/certificates' , {headers : {Authorization : localStorage.getItem('token')}})
        console.log(res);
        if (res.data.success) {
            dispatch(superAdminActions.getAllCert(res.data.certificates))
        }
    } catch (error) {
        console.log(error.response.data);
    }

}