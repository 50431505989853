import React from 'react';
import classes from './Background.module.scss'
import Logo from '../../assets/logo.png'
function Background() {
  return <div className={classes.bg} > 
  <img className={classes.logo} alt='logo' src={Logo}></img>
  </div>;
}

export default Background;
