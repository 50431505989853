import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    
    isStepOneSuccess:false,
    isStepTwoSuccess:false,
    isStepThreeSuccess:false,


}

const stepSlice = createSlice({
    name:'steps' ,
    initialState,
    reducers : { 

        setStepOneSuccess(state , action){
            state.isStepOneSuccess = action.payload
        },
        setStepTwoSuccess(state , action){
            state.isStepTwoSuccess = action.payload

        },
        setStepThreeSuccess(state , action){
            state.isStepThreeSuccess = action.payload

        },

    }

})


export const stepActions = stepSlice.actions
export default stepSlice