import React , { useMemo, useState} from 'react';
import { useTable  , useGlobalFilter , useSortBy , usePagination } from 'react-table';
import classes from './RenderListeUsers.module.scss'
import { IconButton, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import QrCodePopup from '../../elements/QrCodePopup/QrCodePopup';
import Notify from '../../elements/notification/Notify';
import Button from '../../elements/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ListeDesUserButton from '../../elements/TablesButton/ListeDesUsersButton';
import AddUser from '../../elements/addUser(superAdmin)/AddUser';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditUser from '../../elements/addUser(superAdmin)/EditUser';
import { deleteUser, exportUserToExcel, getUserForUpdate } from '../../store/superAdmin/superAdminActions';
import ConfirmDialog from '../../elements/confirmAlert/ConfirmDialogs';
import MenuPopupState from '../../elements/dropDown/dropDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExportPopUp from '../../elements/exportPopUp/ExportPopUp';
import { Helmet, HelmetProvider } from "react-helmet-async";






function RenderListeUsers(props) {

  const [orgKey , setAdminId] = useState()
  const [userId , setUserID] = useState()
  const [openExport , setOpenExport] = useState(false)

  const dispatch = useDispatch()

  const [openOrgAdmin , setOpenUser] = useState(false)
  const [openEditOrg , setEditUser] = useState(false)
  const [openDialog , setOpenDialog] = useState(false)

  const openExportFunc = ()=>{
    setOpenExport(oldValue => !oldValue)
  }

  const openMenu = (id)=>{
    setOpenUser(oldValue => !oldValue)
  }
  
  const openDialogFunc = ()=>{
    setOpenDialog(oldValue => !oldValue)
  }

  const submitDelete = ()=>{
    dispatch(deleteUser(userId))
  }
  
  
  const openEditMenu = (Key)=> {
    if (isNaN(Key)) {
      setEditUser(oldValue => !oldValue)
      setAdminId(Key)
      return
    } else{
      dispatch(getUserForUpdate(Key))
      setAdminId(Key)
      setEditUser(oldValue => !oldValue)
    }
   
  }

  const allUsers = useSelector(state => state.superAdmin.allUser)
  const filteredUser = allUsers.filter(user => !user.roles.includes('ADM001_super'))


  const Column = [
    {
      Header : '#',
      accessor : 'id'
    },
    {
      Header : 'Nom',
      accessor : 'lastName'
  
    },
    {
      Header : 'Prénom',
      accessor : 'firstName'
    },
    {
      Header : 'email',
      accessor : 'email',
    },
    {
      Header : 'orgKeys',
      accessor : user => {
        return <MenuPopupState orgKeys={user.orgKeys}/>
      },
    },
    {
      Header : 'Date de Création',
      accessor : user => {
        return moment(user.createdAt).format('YYYY MM DD H:MM')
      },
    },
    {
      Header : 'Modifier',
      accessor : user => {
        return <ListeDesUserButton id={user.id} openDialogFunc={openDialogFunc} setUserID={setUserID} OpenMenu={openEditMenu}/>
      },
    },
  
  ]


  const data = useMemo(()=> filteredUser , [allUsers])
  const columns = useMemo(()=> Column ,[])

  const tableInstance = useTable({
    columns ,
    data
  } , useGlobalFilter  , useSortBy ,usePagination  ) 

  const {getTableProps , getTableBodyProps , headerGroups , page , nextPage , previousPage , pageOptions , setPageSize ,canNextPage , gotoPage , pageCount , canPreviousPage , prepareRow , state , setGlobalFilter  } = tableInstance



 

  const {globalFilter , pageIndex , pageSize} = state 

  return <div className={classes.userlist} >
    <HelmetProvider> <Helmet> <title> Liste des utilisateur </title> </Helmet> </HelmetProvider>
        {openExport && <ExportPopUp openExportFunc={openExportFunc} isUsers={true} />}
        {props.closeSettingMenuValue && <Notify/>}
        <ConfirmDialog openConfirm={openDialog} submitDelete={submitDelete} close={openDialogFunc} />
        {openOrgAdmin &&  <AddUser  orgKey={orgKey} closeMenu={openMenu} />}
        {openEditOrg && <EditUser  orgKey={orgKey} closeMenu={openEditMenu} /> }
       {props.closeMenuValue &&  <QrCodePopup  closeMenu={props.closeMenu} />}
    
    <div className={classes.tableBox}>
    <div className={classes.AddButton} >
                  <label> Ajouter Utilisateur</label>
                  <IconButton onClick={openMenu} className={classes.button}> <GroupAddIcon className={classes.color} />  </IconButton>
    </div>
    <IconButton onClick={openExportFunc} className={classes.export} >   <Typography> Export </Typography> <FileDownloadIcon/>   </IconButton> 


      <div  className={classes.search} >
            <div className={classes.select} >
                <p>Afficher</p>
                <select value={pageSize} onChange={((e)=> setPageSize(Number(e.target.value)))} >
                {[15 , 30 ,45 , 60 ].map(page => (
                  <option key={page} value={page} > {page} </option>
                ))}
                </select>
                <p>éléments</p>
            </div>
            <div className={classes.searchBar} >
                <p>Rechercher:</p>
                <input value={globalFilter || ''} onChange={(e => setGlobalFilter(e.target.value))} />
            </div>
      </div>
    <div className={classes.wrapTable} >
    <table {...getTableProps}  className={classes.table}>
            <thead  className={classes.head} >
              {headerGroups.map(head => (
                 <tr {...head.getHeaderGroupProps()} >
                      {head.headers.map(colum => (
                            <th className={classes.Trhead} {...colum.getFooterProps(colum.getSortByToggleProps())} > {colum.render('Header')}
                              <span>{colum.isSorted ? (colum.isSortedDesc ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> ) : '' }</span>
                             </th>
                             
                      ))}
                </tr>
              ))}
             
            </thead>
           {filteredUser.length > 0 ? <tbody className={classes.body} {...getTableBodyProps} >
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr className={classes.Trbody} {...row.getRowProps()} >
                      {
                        row.cells.map(cell => {
                     return   <td className={classes.Tdbody} {...cell.getCellProps()} > {cell.render('Cell')} </td>
                        } )
                      }
                  </tr>
                )
              } )}
              
            </tbody> : <tbody className={classes.message} >
                <tr  >
                    <td>
                         Pas encore de utilisateur
                    </td>
              </tr>
            </tbody> }
      </table>
              
    </div>
      <div>

      </div>
      <div  className={classes.button}>
          <div className={classes.page} >
              <p>Affichage de l'élement {pageIndex + 1} à {pageSize} sur {pageOptions.length} éléments</p>
          </div>
          <div className={classes.pagination} >
            <Button onClick={(()=> gotoPage(0))} className={!canPreviousPage && classes.onClick} disabled={!canPreviousPage} >Premier</Button>
            <Button disabled={!canPreviousPage} className={!canPreviousPage && classes.onClick} onClick={(()=> previousPage())} >Précédent</Button>
            <Button>{pageIndex + 1} </Button>
            <Button disabled={!canNextPage} className={!canNextPage && classes.onClick}  onClick={(()=> nextPage() )} >Suivant</Button>
            <Button onClick={(()=> gotoPage(pageCount - 1))} disabled={!canNextPage} className={!canNextPage && classes.onClick} >Dernier</Button>
          </div>
      </div>

    </div>
    
    </div>;
}

export default RenderListeUsers;
