import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Background from "../../../elements/background-login/Background";
import InputForm from "../../../elements/inputForm/InputForm";
import { verifyOrgKey } from "../../../store/auth/authActions";
import { authActions } from "../../../store/auth/authSlice";
import classes from "./Steps.module.scss";
import { Rings } from "react-loader-spinner";
import LinkdIn from "../../../assets/linkedin_org_id.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../elements/button/Button";
import { toast } from "react-toastify";

function StepFour({ createOrJoin, orgKey, orgName, linkIn, radioBox }) {
  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.auth.isStepsSuccess);
  const isOrgKeyValid = useSelector((state) => state.auth.isOrgKeyValid);
  const orgaName = useSelector((state) => state.auth.orgName);
  const [openLinkIdImage, setOpenLinkIdImage] = useState(false);

  const ShowImage = () => {
    setOpenLinkIdImage((oldValue) => !oldValue);
  };

  const Verfier = () => {
    if (createOrJoin.value === "Rejoindre une organisation") {
      dispatch(verifyOrgKey(orgKey.value));
    }
  };

  const next = () => {
    if (isOrgKeyValid) {
      dispatch(authActions.switchStep(6));
      return;
    } else if (orgName.value) {
      dispatch(authActions.switchStep(5));
    }
    // if (!isOrgKeyValid) {
    //   toast.warn("Voulez vous saissier nom de organisation");
    // }
  };
  const previous = () => {
    dispatch(authActions.switchStep(3));
  };

  return (
    <div className={classes.SignUpSteps}>
      <Background />
      {openLinkIdImage && (
        <div className={classes.linkInImg}>
          <IconButton className={classes.closeBtn} onClick={ShowImage}>
            <CloseIcon />{" "}
          </IconButton>
          <img src={LinkdIn} alt='linkid' />
        </div>
      )}
      <div className={classes.SignUp}>
        <div className={classes.SignUpBox}>
          <div className={classes.h2}>
            <h2>
              {radioBox === "Créer une organisation"
                ? "Informations sur votre organisation"
                : "Rejoindre une organisation"}
            </h2>
          </div>
          <div className={classes.signUpForm}>
            <div className={classes.inputBox}>
              {radioBox === "Créer une organisation" ? (
                <InputForm
                  title="Nom de l'organisation:"
                  name='orgName'
                  {...orgName}
                  required={true}
                />
              ) : (
                <InputForm
                  title="Organisation key de l'entreprise :"
                  {...orgKey}
                  name='orgKey'
                  required={true}
                />
              )}
              {radioBox === "Créer une organisation" && (
                <InputForm
                  title="LinkedIn ID de l'organisation: (Facultatif)"
                  {...linkIn}
                  name='linkIn'
                />
              )}
              {radioBox !== "Créer une organisation" && (
                <button onClick={Verfier}>Verfier </button>
              )}
              {radioBox === "Créer une organisation" && (
                <label className={classes.label} onClick={ShowImage}>
                  Comment trouver votre ID ?
                </label>
              )}
              {isSuccess && (
                <div className={classes.loading}>
                  <p>Loading...</p>
                  <Rings style color='#F28E1F' height={80} width={80} />
                </div>
              )}
              {isOrgKeyValid && (
                <div>
                  <p>Nom de Organisation : {orgaName}</p>
                </div>
              )}
            </div>

            <div className={classes.button}>
              <Button onClick={next} textColor={"white"} color={"#0071BC"}>
                {" "}
                Continuer
              </Button>
              <Button onClick={previous} textColor={"white"} color={"#343a40"}>
                {" "}
                Retour
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepFour;
