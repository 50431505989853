import React, { useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import classes from './CheckValid.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { checkPayment } from '../../store/superAdmin/superAdminActions';

function CheckValidPayments(props) {

    const [open , setOpen] = useState(false)

    const openNotify = ()=>{
        setOpen(oldValue => !oldValue)
    }

    const dispatch = useDispatch()



  return (
    <div className={classes.checkIf}> 
         <p> {props.order.orderId} </p>
    </div>
  )
}

export default CheckValidPayments