import React , { useEffect, useMemo, useState} from 'react';
import { useTable  , useGlobalFilter , useSortBy , usePagination } from 'react-table';
import classes from './RenderUsers.module.scss'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Notify from '../../elements/notification/Notify';
import Button from '../../elements/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import AddUserPopUp from '../../elements/addUserPopUp/AddUserPopUp';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from 'react-router-dom';
import { getUserOne } from '../../store/user/userActions';








function RenderUsers(props) {

  const dispatch = useDispatch()
  const USERS = useSelector(state => state.user.allUsers)
  const [title, setTitle] = useState("");

  const sendUserInfo = (id) => {
    dispatch(getUserOne(id))
  }

  const Column = [
    {
      Header : 'Email',
      accessor : 'email'
    },
    {
      Header : 'Nom',
      accessor : 'lastName'
    },
    {
      Header : 'Dernière connexion',
      accessor : date => {
        return moment(date.lastLogin).format('DD-MM-YYYY hh:mm')
      }
  
    },
    {
      Header : 'Date de création',
      accessor : date => {
        return moment(date.createdAt).format('DD-MM-YYYY hh:mm')
      },
    },
    {
      Header : 'Modifier',
      accessor : user => {
        return <IconButton onClick={() => sendUserInfo(user.id)}  > <Link to={'/Dashboard/users/'+user.id}  >  <ModeEditIcon sx={{color:'#0071BC'}}/>   </Link></IconButton>
      },
    },
  
  ]

  const data = useMemo(()=> USERS , [USERS])
  const columns = useMemo(()=> Column ,[])

  const tableInstance = useTable({
    columns ,
    data
  } , useGlobalFilter  , useSortBy ,usePagination  ) 

  const {getTableProps , getTableBodyProps , headerGroups , page , nextPage , previousPage , pageOptions , setPageSize ,canNextPage , gotoPage , pageCount , canPreviousPage , prepareRow , state , setGlobalFilter  } = tableInstance


  useEffect(() => {
    const fetchData = async () => {

      // When the data "fetching" process is complete, we will set the title and content
      setTitle("Liste des utilisateurs");
    };

    fetchData();
  }, []);


  const {globalFilter , pageIndex , pageSize} = state 

  return <div className={classes.userlist} >
    <HelmetProvider> <Helmet> <title>{title} </title></Helmet> </HelmetProvider>
        { props.closeSettingMenuValue &&  <Notify/>}
        {props.addUserPopUpValue &&  <AddUserPopUp  closeMenu={props.addUserPopUpFunc} />}
  
    <div className={classes.tableBox}>
      <div  className={classes.search} >
            <div className={classes.select} >
                <p>Afficher</p>
                <select value={pageSize} onChange={((e)=> setPageSize(Number(e.target.value)))} >
                {[15 , 30 ,45 , 60 ].map(page => (
                  <option key={page} value={page} > {page} </option>
                ))}
                </select>
                <p>éléments</p>
            </div>
            <div className={classes.searchBar} >
                <p>Rechercher:</p>
                <input value={globalFilter || ''} onChange={(e => setGlobalFilter(e.target.value))} />
            </div>
      </div>
    <div className={classes.wrapTable} >
    <table {...getTableProps}  className={classes.table}>
            <thead  className={classes.head} >
              {headerGroups.map(head => (
                 <tr {...head.getHeaderGroupProps()} >
                      {head.headers.map(colum => (
                            <th className={classes.Trhead} {...colum.getFooterProps(colum.getSortByToggleProps())} > {colum.render('Header')}
                              <span>{colum.isSorted ? (colum.isSortedDesc ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> ) : '' }</span>
                             </th>
                             
                      ))}
                </tr>
              ))}
             
            </thead>
           {USERS.length > 0 ? <tbody className={classes.body} {...getTableBodyProps} >
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr className={classes.Trbody} {...row.getRowProps()} >
                      {
                        row.cells.map(cell => {
                     return   <td className={classes.Tdbody} {...cell.getCellProps()} > {cell.render('Cell')} </td>
                        } )
                      }
                  </tr>
                )
              } )}
              
            </tbody> : <div className={classes.message} >
            <p >Pas encore de QrCode</p>
            </div>}
      </table>
              
    </div>
      <div>

      </div>
      <div  className={classes.button}>
          <div className={classes.page} >
              <p>Affichage de l'élement {pageIndex + 1} à {pageSize} sur {pageOptions.length} éléments</p>
          </div>
          <div className={classes.pagination} >
            <Button onClick={(()=> gotoPage(0))} className={!canPreviousPage && classes.onClick} disabled={!canPreviousPage} >Premier</Button>
            <Button disabled={!canPreviousPage} className={!canPreviousPage && classes.onClick} onClick={(()=> previousPage())} >Précédent</Button>
            <Button>{pageIndex + 1} </Button>
            <Button disabled={!canNextPage} className={!canNextPage && classes.onClick}  onClick={(()=> nextPage() )} >Suivant</Button>
            <Button onClick={(()=> gotoPage(pageCount - 1))} disabled={!canNextPage} className={!canNextPage && classes.onClick} >Dernier</Button>
          </div>
      </div>

    </div>
    
    </div>;
}

export default RenderUsers;
