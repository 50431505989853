import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable , useSortBy ,useGlobalFilter, usePagination } from 'react-table';
import classes from './Steps.module.scss'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MatrialUiBtn from '@mui/material/Button';
import {Rings} from 'react-loader-spinner'
import Button from '../../../elements/button/Button';
import moment from 'moment';
import { sendCertificateToEmail, sendCertificateToEmailForOne, uploaderLesImages } from '../../../store/certficate/certficateActions';
import { certificateActions } from '../../../store/certficate/certficateSlice';
import LoadingRing from '../../../elements/loadingRing/loadingRing';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';


function StepFive(props) {
    const Attestation = useSelector(state => state.certificate.getTypeDocuments)
    const certificate = useSelector(state => state.certificate.certificates)
    const dispatch = useDispatch()


    const email = Attestation.map(att => att.email)
    const certficateIds = Attestation.map(att => att.certificateId)

    console.log(certficateIds);
    useEffect(()=>{
      if (certificate.uploadedImage) {
        props.openDownLoadCert(true)
      }
    },[certificate.uploadedImage])

    const sendCertForOne = (data)=> {
      const orgKey = props.selectValueToAdd?.value
      dispatch(sendCertificateToEmailForOne( certficateIds  , data.certificateId ,data.email , orgKey))
    }
      const Column = [
        {
          Header : 'ID',
          accessor : 'certificateId'
        },
        {
            Header : 'Nom & Prénom',
            accessor : data => {
                return <p> {`${data.nom || data.nom_ceo} ${data.prenom || data.nom_startup || data.nom_projet}`} </p>
              }
        },
        {
          Header : 'Email',
          accessor : 'email'
        },
        {
          Header : 'Action',
          accessor : date => {
            return <div    style={{display:'flex' , alignItems:'center' , justifyContent:'center' ,width:'100%' , height:'100%' }}>
          
            <button onClick={()=> sendCertForOne(date)}  htmlFor='upload'style={{cursor:'pointer' , border:'none', display:'flex' , alignItems:'center' , justifyContent:'center' , backgroundColor:'#0071BC' , color:'white' , width:'90%' ,height:'100%'}}> Envoyer </button>
        </div>
          }
        },
        {
            Header : 'Etat envoi',
            accessor : date => {
              console.log(date.emailStatus , "data");
    return  <div style={{display:'flex' , alignItems:"center", justifyContent:'center'}} >

    {date.emailStatus !== "Non Envoyé" ? <CheckCircleIcon  style={{color:'#07bc0c'}} />
              : <ErrorIcon style={{color:'#e74c3c'}} />}
  </div>
    
            }
        },
      
       
      ]
    

    const notifySignataires = ()=> {
        const orgKey = props.selectValueToAdd?.value
      
        dispatch(sendCertificateToEmail( certficateIds ,email , orgKey))
    }
  const data = useMemo(()=> Attestation, [Attestation])
  const columns = useMemo(()=> Column ,[])

  const tableInstance = useTable({
    columns ,
    data
  }, useGlobalFilter , useSortBy , usePagination)

  const {getTableProps , getTableBodyProps , headerGroups , page , nextPage , previousPage  , setPageSize ,canNextPage , gotoPage , pageCount , canPreviousPage , prepareRow , state , setGlobalFilter } = tableInstance
  const {globalFilter , pageIndex , pageSize} = state 



  const next = ()=>{
    window.location.reload()
  }
  const back = () =>{
    dispatch(certificateActions.switchSteps(4))
  }
    return (
        <div className={classes.uploaderImagesTable}>
            <div className={classes.box}>
            <div className={classes.title} ><h3>Envoi des emails</h3></div>
            <div className={classes.sendAllBtn}>
                <MatrialUiBtn onClick={notifySignataires} className={classes.MatrialUiBtn}   > Envoyer a tous </MatrialUiBtn>
            </div>
            <div className={classes.tableBox}>

<div  className={classes.search} >
      <div className={classes.select} >
          <p>Afficher</p>
          <select value={pageSize} onChange={((e)=>setPageSize(Number(e.target.value)))} >
          {[15 , 30 ,45 , 60 ].map(page => (
            <option key={page} value={page} > {page} </option>
          ))}
          </select>
          <p>éléments</p>
      </div>
      <div className={classes.searchBar} >
          <p>Rechercher:</p>
          <input value={globalFilter} onChange={((e)=> setGlobalFilter(e.target.value))} />
      </div>
</div>
<div className={classes.wrapTable} >

<table  {...getTableProps}  className={classes.tb}>
      <thead  className={classes.head} >
        {headerGroups.map(head => (
           <tr {...head.getHeaderGroupProps()} >
                {head.headers.map(colum => (
                      <th className={classes.Trhead} {...colum.getFooterProps(colum.getSortByToggleProps())} > {colum.render('Header')} 
                        <span>{colum.isSorted ? (colum.isSortedDesc ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> ) : '' }</span>
                      </th>
                ))}
          </tr>
        ))}
       
      </thead>
     {Attestation.length > 0 ? <tbody className={classes.body} {...getTableBodyProps} >
        {page.map(row => {
          prepareRow(row)
          return (
            <tr className={classes.Trbody} {...row.getRowProps()} >
                {
                  row.cells.map(cell => {
               return  <td className={classes.Tdbody} {...cell.getCellProps()} > {cell.render('Cell')} </td>
                  } )
                }
            </tr>
          )
        } )}
        
      </tbody> : <tbody className={classes.message} >
          <tr  >
              <td>
                   Pas encore de documents
              </td>
        </tr>
      </tbody>}
</table>
      
</div>



<div  className={classes.button}>
    <div className={classes.page} >
      <p>Affichage de l'élement {pageIndex + 1} à {pageSize} sur {pageCount} éléments</p>
    </div>
    <div className={classes.pagination} >
      <Button onClick={(()=> gotoPage(0))} disabled={!canPreviousPage} >Premier</Button>
      <Button disabled={!canPreviousPage}  onClick={(()=> previousPage())} >Précédent</Button>
      <Button>{pageIndex + 1} </Button>
      <Button disabled={!canNextPage}   onClick={(()=> nextPage() )} >Suivant</Button>
      <Button onClick={(()=> gotoPage(pageCount - 1))} disabled={!canNextPage}  >Dernier</Button>
    </div>
</div>
<div className={classes.buttonSuivant} >
      <MatrialUiBtn onClick={back}  className={classes.MatrialUiBtnbutton} >Retour</MatrialUiBtn>
      <MatrialUiBtn onClick={next}  className={classes.MatrialUiBtnbutton} >Terminer</MatrialUiBtn>
</div>
</div>
        </div>
</div>
    )
}

export default StepFive