import React, { useEffect, useState } from 'react'
import classes from './AdminDashboard.module.scss'
import EqualizerIcon from '@mui/icons-material/Equalizer';
import {  useSelector } from 'react-redux';
import Notify from '../../elements/notification/Notify';
// import ReactHTMLTableToExcel  from 'react-html-table-to-excel'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconButton, Typography } from '@mui/material';
import { Helmet, HelmetProvider } from "react-helmet-async";

function RenderAdminDashboard(props) {

  const [title, setTitle] = useState("");
  const allUser = useSelector(state => state.superAdmin.allUser)
  const admin = allUser.filter(user => user.roles.includes('ADM001_super'))
  const allOrg = useSelector(state => state.superAdmin.allOrg)
  const allOrders = useSelector(state => state.superAdmin.allOrders)
  const ValidOrders = allOrders.filter(order => order.paymentStatus === 'validé')
  const TotalQeCode = ValidOrders.map(order => order.number)
  const Chiffer = ValidOrders.map(order => order.totalPrice)
  const certificates = useSelector(state => state.superAdmin.allCert)

  console.log(certificates);

  const sum = TotalQeCode.reduce((partial , a)=> partial + a , 0)
  const chifferAffaire = Chiffer.reduce((partial , a )=> partial + a , 0)


  useEffect(() => {
    const fetchData = async () => {

      // When the data "fetching" process is complete, we will set the title and content
      setTitle("Dashboard");
    };

    fetchData();
  }, []);


  return (
    <div className={classes.admin} >
      <HelmetProvider> <Helmet> <title> {title} </title> </Helmet> </HelmetProvider>
    {props.closeSettingMenuValue && <Notify/>}

      <table className={classes.table} id='stat' >
        <thead>
          <tr>
                <td>UTILISATEURS</td>
                <td>ADMINS</td>
                <td>ORGANISATIONS ENREGISTRÉES</td>
                <td>TOTAL DEMANDES</td>
                <td>DEMANDES AVEC PAIEMENT VALIDÉ</td>
                <td>DOCUMENTS CERTIFIÉS</td>
                <td>TOTAL QRCODES DÉLIVRÉS</td>
                <td>CHIFFRE D'AFFAIRE</td>
          </tr>
        </thead>
        <tbody>
              <tr>
               <td>{allUser.length}</td>
                <td>{admin.length}</td>
                <td>{allOrg.length}</td>
                <td>{allOrders.length}</td>
                <td>{ValidOrders.length}</td>
                <td>{certificates.length}</td>
                <td>{sum}</td>
                <td>{chifferAffaire} DA</td>
              </tr>
        </tbody>
      </table>
      {/* <ReactHTMLTableToExcel table='stat' id='export'  className={classes.displayNone} filename='stat of uphawu' sheet="Sheet" buttonText="Export" />  */}

       <div className={classes.static} >
    <div className={classes.top} >
          
          <div> <p> Statistiques Globals </p> 
          <IconButton className={classes.export} >   <label htmlFor='export' > <Typography> Export </Typography> <FileDownloadIcon/>  </label> </IconButton> 
          </div>
    </div>
      <div className={classes.insideStatic} >
          <p>UTILISATEURS</p>
          <div>
            <h3>{allUser.length}</h3>
          </div>
          <EqualizerIcon className={classes.icone} sx={{width:'50px' , height:'50px' , color:'#DDDFEB'}} />
      </div>
      <div className={classes.insideStatic} >
       <p>ADMINS</p>
          <div>
            <h3>{admin.length}</h3>
          </div>
          <EqualizerIcon className={classes.icone} sx={{width:'50px' , height:'50px' , color:'#DDDFEB'}} />
      </div>
      <div className={classes.insideStatic} >
      <p>ORGANISATIONS <span>ENREGISTRÉES</span> </p>
          <div>
            <h3>{allOrg.length}</h3>
          </div>
          <EqualizerIcon className={classes.icone} sx={{width:'50px' , height:'50px' , color:'#DDDFEB'}} />
      </div>

    </div>
    <div className={classes.static} >
    <div className={classes.top} >
          <div> <p> Statistiques QRCodes </p> </div>
    </div>
      <div className={classes.insideStatic} >
      <p>TOTAL DEMANDES</p>

          <div>
            <h3>{allOrders.length}</h3>
          </div>
          <EqualizerIcon className={classes.icone} sx={{width:'50px' , height:'50px' , color:'#DDDFEB'}} />
      </div>
      <div className={classes.insideStatic} >
      <p>DEMANDES <span>AVEC PAIEMENT </span> VALIDÉ</p>

          <div>
            <h3>{ValidOrders.length}</h3>
          </div>
          <EqualizerIcon className={classes.icone} sx={{width:'50px' , height:'50px' , color:'#DDDFEB'}} />
      </div>

    </div>
    <div className={classes.static} >
    <div className={classes.top} >
          <div> <p> Statistiques  </p> </div>
    </div>
      <div className={classes.insideStatic} >
      <p>DOCUMENTS <span>CERTIFIÉS</span> </p>

          <div>
            <h3>{certificates.length}</h3>
          </div>
          <EqualizerIcon className={classes.icone} sx={{width:'50px' , height:'50px' , color:'#DDDFEB'}} />
      </div>
      <div className={classes.insideStatic} >
      <p> <span>TOTAL</span> QRCODES DÉLIVRÉS</p>

          <div>
            <h3>{sum}</h3>
          </div>
          <EqualizerIcon className={classes.icone} sx={{width:'50px' , height:'50px' , color:'#DDDFEB'}} />
      </div>
      <div className={classes.insideStatic} >
      <p>CHIFFRE D'AFFAIRE</p>

          <div>
            <h3>{chifferAffaire} DA</h3>
          </div>
          <EqualizerIcon className={classes.icone} sx={{width:'50px' , height:'50px' , color:'#DDDFEB'}} />
      </div>
    </div>
    </div>
  )
}

export default RenderAdminDashboard