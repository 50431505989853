import { Checkbox, Input } from '@mui/material'
import React, { useEffect, useState } from 'react'
import classes from './Inputs.module.scss'
import Select from 'react-select';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Gender = ['Mr' , 'Mme']
const typeProject = ['Startup' , 'Project innovant' ,'Incubateur']
const dorganisme = ['Institution publique national' , 'Institution publique international' ,'Enterprise publique', 'Enterprise privée','ONG/Association Nationale', 'ONG/Association international' , 'Personnalité publique' , 'Personnalité Politique', 'Influenceur', 'Université','Laboratoire de recherche',"Autre"]
const contribution = ['Expert' , 'Chercheur' ,'Entrepreneur' ,'Startupper' , "Manager d'incubateur" , "Manager club estudiantin", "Représentant d'une organisation" ,'Personnalité publique / acteur écosystéme' , "Représentant d'une NGO internationale" , "Représentant d'une NGO nationale"]


function InputsAddCert(props) {

  const isEditMode = props?.isEditMode
  const usersIds = useSelector(state => state.certificate.selectedUserIds)
  const cert = useSelector(state => state.certificate.certificates)
  const certificate = useSelector(state => state.certificate.documentType)

  

  const orgKey = props.orgKey?.value
  const typeDocuments = props.typeDoc
  

  const [selectGender , setSelectGender ] = useState(null || certificate.genre)
  const [selectTypeProject , setSelectType] = useState(null || certificate.type_projet)
  const [selectTypeOrganisme , setSelectTypeOrganisme] = useState(null || certificate.type_organsime)
  const [ExperyDate , setCheckHasExperyDate] = useState(false || cert.hasExpiryDate)
  const [selectTypeContribution , setSelectTypeContribution] = useState(null || certificate.type_contribution)

  let duree =  certificate?.duree
  duree = duree?.split(' ')


 
  const [data , setData] = useState({
    nom:"" || certificate.nom  ,
    prenom:"" ||  certificate.prenom ,
    email:"" ||  certificate.email ,
    nom_formation:"" || certificate.nom_formation ,
    nom_formateur:"" || certificate.nom_formateur ,
    nom_organisme:""|| certificate.nom_organisme   ,
    nom_incubateur:""|| certificate.nom_incubateur,
    num_incubateur:""  || certificate.num_incubateur,
    nom_organisateur:""|| certificate.nom_organisateur ,
    nom_evenement:""|| certificate.nom_evenement ,
    lieu_evenement:"" || certificate.lieu_evenement  ,
    poste:"" || certificate.poste ,
    nom_societe:"" || certificate.nom_societe ,
    typeDeMembre:"" || certificate.typeDeMembre ,
    nom_projet:"" || certificate.nom_projet ,
    nom_labo: "" || certificate.nom_labo ,
    num_label:"" || certificate.num_label ,
    num_agreement:"" || certificate.num_agreement ,
    nom_oragnsime : "" || certificate.nom_oragnsime ,
    nom_startup :"" || certificate.nom_startup ,
    nom_ceo:"" || certificate.nom_ceo,
    age:"" || certificate.age ,
    date_fin:"" || moment(certificate.date_fin).format('YYYY-MM-DD'),
    date_debut:'' || moment(certificate.date_debut).format('YYYY-MM-DD'),
    date_evenement:'' || moment(certificate.date_evenement).format('YYYY-MM-DD'),
    date_prelevement: ""|| moment(certificate.date_prelevement).format('YYYY-MM-DD'),
    date_adhesion:''|| moment(certificate.date_adhesion).format('YYYY-MM-DD'),
    date_obtention_label:'' ||  moment(certificate.date_obtention_label).format('YYYY-MM-DD'),
    hours:'' || (certificate.duree && duree[2]?.split('-')[0]),
    participant:'' || certificate.participant ,
    type_participation:''|| certificate.type_participation ,
    months:'' || (certificate.duree && duree[0]?.split('-')[0]),
    days:'' || (certificate.duree && duree[1]?.split('-')[0]),
    expiryDate:"" || moment(cert.expiryDate)?.format('YYYY-MM-DD'),
    heure_debut:"" || certificate.heure_debut,
    num_employeur:'' || certificate.num_employeur,
    email_labo:""|| certificate.email_labo,
    email_societe:'' || certificate.email_societe ,
    nom_medecin:'' || certificate.nom_medecin ,
    num_tel_societe:'' || certificate.num_tel_societe,
    num_tel_labo:'' || certificate.num_tel_labo,
    date_naissance:'' || moment(certificate.date_naissance).format('YYYY-MM-DD'),
    type_test:'' || certificate.type_test,
    maladie_testee:'' || certificate.maladie_testee,
    resultat:''|| (certificate && certificate.resultat),
    matricule:'' || certificate.matricule
  })
  useEffect(()=>{
    
    props.getData(data)
    props.gender(selectGender)
    props.expiryDate(ExperyDate)
    if (props.CT01) {
    props.typeProject(selectTypeProject)
    }
    if (props.AT05) {
      props.typeOrgisme(selectTypeOrganisme)
      props.typeContribution(selectTypeContribution)
    }
    
  },[props , typeDocuments , selectGender , ExperyDate , data , selectTypeProject ,selectTypeOrganisme ,selectTypeContribution])

  const checkHasExperyDate = (e) => {
    setCheckHasExperyDate(e.target.checked)
  }

  console.log(typeDocuments);

  const datahandler = (e)=>{
    let {name , value} = e.target
    setData(oldValue => {
     return {
      ...oldValue,
      [name]:value
     }
    })
  
  }

  return (
    <div onChange={datahandler} className={classes.boxInputs}>
                          <div  className={classes.select} >
                          <label> Genre </label>
                          <Select   defaultValue={selectGender}
                          onChange={setSelectGender}
                          options={Gender.map(gen => ({value:gen , label:gen}))} />
                          </div>
                          <div  className={classes.select} >
                          <label> {props.inputOne} </label>
                          <Input  name={(props.CT01 || props.AT02 ? "nom_ceo" : '') || 'nom'} value={(props.CT01 || props.AT02  ? data.nom_ceo : '') || data.nom}/>
                          </div>
                          { !props.CT01 && !props.AT02 && <div  className={classes.select} >
                          <label> {props.inputTwo} </label>
                          <Input  name={'prenom'} value={data.prenom} />
                          </div> }
          
                          <div  className={classes.select} >
                          <label> { props.inputThree} </label>
                          {props.CT01 ? <Select defaultValue={selectTypeProject}
                          onChange={setSelectType}
                          options={typeProject?.map(gen => ({value:gen , label:gen}))}   /> : <Input value={ (props.CR01 && data.age) || data.email} name={(props.CR01 && 'age') || 'email'}  />}
                          </div>
                          {props.CT01 && <div  className={classes.select} >
                          <label> Email </label>
                          <Input  name='email' value={data.email} />
                          </div>}
                          {!props.CR01 &&  <div  className={classes.select} >
                          <label> {props.inputFour} </label>
                          <Input  name={ (props.IN01 &&  'nom_evenement') || (props.CT01 && 'nom_projet') || (props.AT02 && 'nom_incubateur') || (props.AT04 && 'poste') || (props.AT03 && 'nom_evenement') || (props.AT05 && 'typeDeMembre') || 'nom_formation'} value={ (props.CT01 && data.nom_projet) || (props.AT05 && data.typeDeMembre) || (props.IN01 && data.nom_evenement) || (props.AT02 && data.nom_incubateur ) || (props.AT04 && data.poste) || (props.AT03 && data.nom_evenement) || data.nom_formation} />
                          </div>}
                          <div  className={classes.select} >
                          <label> {props.inputFive} </label>
                          <Input  name={(props.IN01 && 'lieu_evenement') || (props.AT02 && 'num_incubateur') || (props.CT01 && 'num_label') || (props.AT05 &&  'nom_oragnsime')  || (props.CR01 && 'nom_labo') || (props.AT04 && 'nom_societe') || (props.AT03 && 'lieu_evenement') || 'nom_formateur' } value={ (props.CT01 && data.num_label) || (props.AT05 && data.nom_oragnsime)  || (props.IN01 && data.lieu_evenement) || (props.CR01 && data.nom_labo) || (props.AT02 && data.num_incubateur) || (props.AT04 && data.nom_societe) || (props.AT03 && data.lieu_evenement) || data.nom_formateur} />
                          </div>
                          {props.AT02  && 
                           <div  className={classes.select} >
                          <label> Nom de la startup </label>
                          <Input  name={'nom_startup'}value={data.nom_startup} />
                          </div>}
                          {props.AT05 && <>
                            <div  className={classes.select} >
                          <label> {props.selectTypeOrganisme} </label>
                          <Select  defaultValue={selectTypeOrganisme}
                          onChange={setSelectTypeOrganisme}
                          options={dorganisme?.map(gen => ({value:gen , label:gen}))} />
                          </div>
                          <div  className={classes.select} >
                          <label> {props.selectContribution} </label>
                          <Select defaultValue={selectTypeContribution}
                          onChange={setSelectTypeContribution}
                          options={contribution?.map(gen => ({value:gen , label:gen}))}  />
                          </div>
                          </>
                          }
                          {props.AT04 && 
                            <div  className={classes.select} >
                            <label> Matricule </label>
                            <Input name='matricule' value={data.matricule} />
                            </div>
                          }
                         {props.AT01 || props.AT03 || props.CR01 || props.IN01 ? <div  className={classes.select} >
                          <label>{props.inputSix}</label>
                          <Input name={ (props.CR01 && 'num_agreement') || (props.AT03 || props.IN01 ? 'nom_organisateur' : '') || 'nom_organisme'} value={(props.CR01 && data.num_agreement) || (props.AT03 || props.IN01 ? data.nom_organisateur : '') || data.nom_organisme} />
                          </div> : ''}
                         {props.AT03 || props.IN01 ? <div  className={classes.select} >
                          <label> {props.participant} </label>
                          <Input name={ 'type_participation'} value={data.type_participation} />
                          </div> : ''}
                          {props.IN01 &&  <div  className={classes.select} >
                          <label> {props.hours} </label>
                          <Input type={'time'} name="heure_debut" value={data.heure_debut} />
                          </div>}
                      {props.AT04  || props.CR01  ? <> <div  className={classes.select} >
                          <label> {props.NumberCNAS} </label>
                          <Input name={(props.CR01 && 'email_labo') ||'num_employeur'} value={(props.CR01 && data.email_labo) || data.num_employeur} />
                          </div>
                          <div  className={classes.select} >
                          <label> {props.emailContact} </label>
                          <Input  name={(props.CR01 && 'nom_medecin') || 'email_societe'} value={ (props.CR01 && data.nom_medecin) || data.email_societe} />
                          </div>
                          <div  className={classes.select} >
                          <label> {props.NumberTelephone} </label>
                          <Input name={(props.CR01 && 'num_tel_labo') || 'num_tel_societe'} value={(props.CR01 && data.num_tel_labo) || data.num_tel_societe} />
                          </div>
                          <div  className={classes.select} >
                          <label> {props.BrithDay}</label>
                          <Input type='date' name='date_naissance' value={data.date_naissance} />
                          </div>
                          </> : ''}
                          {
                            props.CR01 ?   <>
                            <div  className={classes.select} >
                            <label> {props.Typedutest} </label>
                            <Input name='type_test' value={data.type_test} />
                            </div> 
                            <div  className={classes.select} >
                            <label> {props.Maladie} </label>
                            <Input name='maladie_testee' value={data.maladie_testee} />
                            </div> 
                            <div  className={classes.select} >
                            <label> {props.Résultat} </label>
                            <Input name='resultat' value={data.resultat} />
                            </div> 
                            </>:''
                          }
                          <div  className={classes.select} >
                          <label> {props.inputSeven}</label>
                          <Input type='date' name={ (props.AT04 || props.AT02  ? 'date_debut' : '') || (props.CR01 && 'date_prelevement') || (props.AT03 && 'date_evenement') || (props.IN01 && 'date_evenement') || (props.AT05 && 'date_adhesion') || (props.CT01 && 'date_obtention_label') ||  'date_fin'} value={(props.AT05 && data.date_adhesion) || (props.CR01 && data.date_prelevement) || (props.AT02  || props.AT04 ? data.date_debut  : '') || (props.CT01 && data.date_obtention_label) || (props.IN01 && data.date_evenement) ||(props.AT03 && data.date_evenement) || data.date_fin} />
                          </div>
                         {props.AT01 && <div  className={classes.select} >
                          <label>Durée de la formation</label>
                            <div className={classes.Input} >
                                <label>Mois:</label>
                                <Input className={classes.inputDurée} name='months' inputProps={{min:0 , max :12}} minRows={'0'} type='number' value={data.months} />
                                <label>Jours:</label>
                                <Input className={classes.inputDurée} name='days' inputProps={{min:0 , max :31}} minRows={'0'} type='number' value={data.days } />
                                <label>Heures:</label>
                                <Input className={classes.inputDurée} name='hours' inputProps={{min:0 , max :24}} min='0' type='number' value={data.hours } />
                            </div>
                          </div>}
                          <div  className={classes.select} >
                          <label> {props.inputNine} </label>
                          <Input type='date' name='expiryDate' value={data.expiryDate} />
                          <div className={classes.checkBox}>
                            <input onChange={checkHasExperyDate} checked={ExperyDate ? true : false} type={'checkbox'}/>
                            <label>Ce document n'a pas de date de fin de validité</label>
                          </div>
                          </div>
               </div>
  )
}

export default InputsAddCert