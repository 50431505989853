import { Button } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InviteUserOrg } from '../../store/user/userActions'
import classes from './AddUser.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


function AddUserPopUp(props) {

    const user = useSelector(state => state.auth.user)
    const orgKey = props.orgKey

    const dispatch = useDispatch()

    const email = useRef()
   
    const [selectRole , setSelectRoles] = useState(`${orgKey}_admin`)

    console.log(selectRole);
    const rolesHandler = (e)=>{
        setSelectRoles(e.target.value)
    }

    const submitInvite = ()=>{     
        let info = {
            email:email.current.value,
            role:selectRole,
            orgKeys:orgKey
        }
        dispatch(InviteUserOrg(info))
    }

  return (
    <div className={classes.background}>
      <div className={classes.addBox}>
        <IconButton onClick={props.closeMenu} className={classes.closeIconBox}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>

        <div className={classes.title}>
          <h3>Ajouter un utilisateur</h3>
          <hr />
        </div>
        <div className={classes.inputs}>
          <div className={classes.insideInputs}>
            <div className={classes.emailInput}>
              <label>Email de l'utilisateur à inviter</label>
              <input name='email' ref={email} type='email' />
            </div>
            <div onChange={rolesHandler} className={classes.selectInputs}>
              <label>Role(s) à attribuer:</label>
              <div>
                <input
                  readOnly
                  checked={selectRole === `${user.orgKey}_admin` ? true : false}
                  value={`${user.orgKey}_admin`}
                  type='radio'
                />
                <label> Admin </label>
              </div>
              <div>
                <input
                  readOnly
                  checked={
                    selectRole === `${user.orgKey}_member` ? true : false
                  }
                  value={`${user.orgKey}_member`}
                  type='radio'
                />
                <label> Membre </label>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.button}>
          <Button onClick={()=> props.closeMenu()} > Annuler </Button>
          <Button onClick={submitInvite}> Ajouter </Button>
        </div>
      </div>
    </div>
  );
}

export default AddUserPopUp