import { toast } from "react-toastify";

export function checkPassword(password, confirmPassword) {
  const minLength = 8;
  if (password !== confirmPassword) {
    toast.warn("le mot de passe ne correspond pas à la confirmation");
    return false;
  }
  if (password.length < minLength) {
    toast.warn(
      "Le mot de passe est trop court. Il doit comporter au moins 8 caractères."
    );
    return false;
  }

  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasDigit = /\d/.test(password);

  if (!hasUppercase) {
    toast.warn("Le mot de passe doit contenir au moins une lettre majuscule.");
    return false;
  }

  if (!hasLowercase) {
    toast.warn("Le mot de passe doit contenir au moins une lettre minuscule.");
    return false;
  }

  if (!hasDigit) {
    toast.warn("Le mot de passe doit contenir au moins un chiffre.");
    return false;
  }

  const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

  if (!hasSpecialCharacter) {
    toast.warn("Le mot de passe doit contenir au moins un caractère spécial.");
    return false;
  }

  toast.success("Mot de passe valide!");
  return true;
}


export function compareFn() {

}