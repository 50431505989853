import axios from "axios";


const instance = axios.create({

    // baseURL:'http://localhost:4500/'
     // baseURL:'https://api.uphawu.tk/'
     //baseURL:'http://api.uphawu.ml/'
    baseURL:'https://api.uphawu.com/'  
})




export default instance
