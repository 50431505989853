import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

export default function MenuPopupState(props) {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button sx={{background:'#0071BC'}} variant="contained" {...bindTrigger(popupState)}>
            Voir
          </Button>
          <Menu {...bindMenu(popupState)}>
       {props.orgKeys.map(org => <MenuItem key={org} onClick={popupState.close}>{org}</MenuItem>)     }
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}