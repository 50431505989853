import { Button, Input } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import home from '../../assets/homepage_image.jpeg'
import { saveQrCodePos, uploadFile } from '../../store/certficate/certficateActions'
import classes from './CertficatesUploaded.module.scss'
import notFound from '../../assets/imageNotFound.jpg'
import url from '../../url'

function CertfcatesUploaded(props) {

  const QrCodeOn = props.QrCodeOn

  console.log(QrCodeOn);
  const certficate = useSelector(state => state.certificate.certificates)
  


  

  const dispatch = useDispatch()
  const [image, setImage] = useState(null);

  

  // const handleFileChange = (e) => {
  //   setImage(e.target.files[0])
  // }



  const uploadFiles = (e) => {

    e.preventDefault()
    let formData = new FormData()
    formData.append('certImage',image)
    // formData.append('certImage',image)


    dispatch(uploadFile(formData , certficate.certificateId))

}

  return (
    <div className={classes.cert}>
        <div  className={classes.certfictes}>
             
        <div>
            <img src={certficate?.uploadedImage ? url+'/images/Certificates/'+certficate.uploadedImage  : notFound } alt='certficate' className={classes.certImages} />

        </div>
          
        </div>

        <div className={classes.edit}>
                <label htmlFor="contained-button-file">
                <Input className={classes.input} id="contained-button-file" name='images'  type="file" 
                onChange={(e) =>{
                  setImage(e.target.files[0])
                }}
                />
                  </label>
                <Button onClick={uploadFiles} variant="contained" component="span" disabled={!image}>
                  Upload
                </Button>
        </div>
    </div>
  )
}

export default CertfcatesUploaded