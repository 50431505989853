import React, { useEffect, useState } from 'react';
import QrCodePopup from '../../elements/QrCodePopup/QrCodePopup';
import { Helmet, HelmetProvider } from "react-helmet-async";
import AddInfoCCP from '../../elements/AddInfoCCP/AddInfoCCP';
import classes from './Demander.module.scss'
import { useDispatch } from 'react-redux';
import { userActions } from '../../store/user/userSlice';

function RenderDemander(props) {

  const [title, setTitle] = useState("");
  const [openCcp , setOpenCcp] = useState(false)
  const [data , setData] = useState({})
  const [isCcp , setIsCcp] = useState(false)
  const [CAPTCHA , setCAPTCHA] = useState('')
  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      setTitle("Demander");
    };

    fetchData();
  }, []);


  const OpenCcpFunc = (isCcp) => {
    setIsCcp(isCcp)
    setOpenCcp(oldValue => !oldValue)

  }
  return <>  <HelmetProvider> <Helmet> <title> {title} </title> </Helmet></HelmetProvider> <div className={classes.postion}>
 {openCcp && <AddInfoCCP isCcp={isCcp} CAPTCHA={CAPTCHA} closeCcpFunc={OpenCcpFunc}  data={data} /> } <QrCodePopup CAPTCHA={setCAPTCHA} OpenCcpFunc={OpenCcpFunc} getData={setData} notPop={true} orgKey={props.selectValueToOrder} closeSettingMenuValue={props.closeSettingMenuValue}  closeQrCodeMenuValue={props.closeMenuValue} />     
     </div></> 
}

export default RenderDemander;
