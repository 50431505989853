import React  from 'react';
import AddUserPopUp from '../../elements/addUserPopUp/AddUserPopUp';

import Notify from '../../elements/notification/Notify';
import ProfileElement from '../../elements/profile/ProfileElement';
import QrCodePopup from '../../elements/QrCodePopup/QrCodePopup';
import classes from './RenderProfile.module.scss'

function RenderProfile(props) {
console.log('here', props.setCloseSettingMenu);
  
  return <div className={classes.RenderProfile} >
      {props.closeSettingMenuValue && <Notify  setCloseSettingMenu={props.setCloseSettingMenu}/>}
      {props.addUserPopUpValue &&  <AddUserPopUp  closeMenu={props.addUserPopUpFunc} />}
      {props.closeMenuValue &&  <QrCodePopup  closeMenu={props.closeMenu} />}
    <ProfileElement isSuperAdmin={props.isSuperAdmin} className={classes.ProfileBox} />
  </div>;
}

export default RenderProfile;
