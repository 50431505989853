import React , {useEffect, useMemo, useState} from 'react';
import classes from './Renderlist.module.scss'
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { useTable , useSortBy ,useGlobalFilter, usePagination} from 'react-table';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import QrCodePopup from '../../elements/QrCodePopup/QrCodePopup';
import Notify from '../../elements/notification/Notify';
import Button from '../../elements/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getAllOrders } from '../../store/user/userActions';
import AddUserPopUp from '../../elements/addUserPopUp/AddUserPopUp';
import { Helmet, HelmetProvider } from "react-helmet-async";
import MatrialUiBtnbutton from '@mui/material/Button';
import UploadRecu from '../../elements/uploadRecu/uploadRecu';


const Column = [
  {
    Header: "ID",
    accessor: (data) => {
      return <p> {data.orderId} </p>;
    },
  },
  {
    Header: "Date de demande",
    accessor: (date) => {
      return moment(date.createdAt).format("DD-MM-YYYY");
    },
  },
  {
    Header: "Nombre",
    accessor: "number",
  },
  {
    Header: "Etat",
    accessor: (etat) => {
      return (
        <div className={classes.etat}>
          {" "}
          <p
            className={
              etat.status === "refuser"
                ? classes.error
                : etat.status === "En attente"
                ? classes.waiting
                : classes.success
            }
          >
            {" "}
            {etat.status.toUpperCase()}{" "}
          </p>{" "}
        </div>
      );
    },
  },
  {
    Header: "Etat de Paiement",
    accessor: (etat) => {
      return (
        <div className={classes.etat}>
          {" "}
          <p
            className={
              etat.paymentStatus === "Non valide"
                ? classes.error
                : classes.success
            }
          >
            {" "}
            {etat.paymentStatus.toUpperCase()}{" "}
          </p>{" "}
        </div>
      );
    },
  },
  {
    Header: "Date de traitement",
    accessor: (etat) => {
      return (
        <p>
          {!etat.processedAt
            ? "Pas Encore"
            : moment(etat.processedAt).format("YYYY MM DD hh:mm")}
        </p>
      );
    },
  },
  {
    Header: "Confirme le payment(cas de chaque/ccp)",
    accessor: (etat) => {
      return <UploadRecu etat={etat} />;
    },
  },
];




function RenderList(props) {

  
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()
  const [title, setTitle] = useState("");

  useEffect(() => {
    const fetchData = async () => {

      // When the data "fetching" process is complete, we will set the title and content
      setTitle("Liste des demandes");
    };
    
    fetchData();
  }, []);

  useEffect(()=>{
    if (!props.selectValueToList) {
      dispatch(getAllOrders(user.orgKey))
    }else{
      dispatch(getAllOrders(props.selectValueToList.value))
    }
  }, [ user.orgKey,props.selectValueToList , dispatch])
  
  const ORDERS = useSelector(state => state.user.allOrder)
  
  console.log(ORDERS);
   const data = useMemo(() => ORDERS, [ORDERS]);
   const columns = useMemo(()=> Column ,[])
   
   
  console.log(ORDERS);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  
  const {getTableProps , getTableBodyProps , headerGroups , page , nextPage , previousPage  , setPageSize ,canNextPage , gotoPage , pageCount , canPreviousPage , prepareRow , state , setGlobalFilter } = tableInstance
  
  const {globalFilter , pageIndex , pageSize} = state 
  
  console.log(data)
  
  
  return <div className={classes.renderPage}>
    <HelmetProvider> <Helmet> <title> {title}</title> </Helmet> </HelmetProvider>
  {props.closeSettingMenuValue &&  <Notify/>}
  {props.addUserPopUpValue &&  <AddUserPopUp  closeMenu={props.addUserPopUpFunc} />}
  {props.closeMenuValue &&  <QrCodePopup closeMenu={props.closeMenu}  />}
  <div className={classes.static} >
  <div className={classes.top} >
        <div> <p> Statistiques </p> </div>
  </div>
    <div className={classes.insideStaticListDeDemande} >
        <div>
          <p>NOMBRE DE DEMANDES</p>
          <h3>{ORDERS.length}</h3>
        </div>
        <EqualizerIcon className={classes.staticLogo} sx={{width:'100px' , height:'100px' , color:'#DDDFEB'}} />
    </div>
  </div>
  <div className={classes.table} >
  <div className={classes.top} >
        <div> <p> La liste des demandes </p> </div>
        <div></div>
  </div>

  <div className={classes.insideTable} >
  <div className={classes.userlist} >
    
    <div className={classes.tableBox}>

      <div  className={classes.search} >
            <div className={classes.select} >
                <p>Afficher</p>
                <select value={pageSize} onChange={((e)=>setPageSize(Number(e.target.value)))} >
                {[15 , 30 ,45 , 60 ].map(page => (
                  <option key={page} value={page} > {page} </option>
                ))}
                </select>
                <p>éléments</p>
            </div>
            <div className={classes.searchBar} >
                <p>Rechercher:</p>
                <input value={globalFilter} onChange={((e)=> setGlobalFilter(e.target.value))} />
            </div>
      </div>
    <div className={classes.wrapTable} >
    <table {...getTableProps}  className={classes.tb}>
            <thead  className={classes.head} >
              {headerGroups.map(head => (
                 <tr {...head.getHeaderGroupProps()} >
                      {head.headers.map(colum => (
                            <th className={classes.Trhead} {...colum.getFooterProps(colum.getSortByToggleProps())} > {colum.render('Header')} 
                              <span>{colum.isSorted ? (colum.isSortedDesc ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> ) : '' }</span>
                            </th>
                          
                      ))}
                </tr>
              ))}
             
            </thead>
           {ORDERS.length > 0 ? <tbody className={classes.body} {...getTableBodyProps} >
              {page.map(row => {
                prepareRow(row)
                console.log(row);
                return (
                  <tr className={classes.Trbody} {...row.getRowProps()} >
                      {
                        row.cells.map(cell => {
            
                     return   <td className={classes.Tdbody} {...cell.getCellProps()} > {cell.render('Cell')} </td>
                        } )
                      }
                  </tr>
                )
              } )}
              
            </tbody> : <tbody className={classes.message} >
                <tr  >
                    <td>
                         Pas encore de demande
                    </td>
              </tr>
            </tbody>}
      </table>

    </div>
      


      <div  className={classes.button}>
          <div className={classes.page} >
              <p>Affichage de l'élement {pageIndex + 1} à {pageSize} sur {pageCount} éléments</p>
          </div>
          <div className={classes.pagination} >
            <Button onClick={(()=> gotoPage(0))} className={!canPreviousPage && classes.onClick} disabled={!canPreviousPage} >Premier</Button>
            <Button disabled={!canPreviousPage} className={!canPreviousPage && classes.onClick} onClick={(()=> previousPage())} >Précédent</Button>
            <Button>{pageIndex + 1} </Button>
            <Button disabled={!canNextPage} className={!canNextPage && classes.onClick}  onClick={(()=> nextPage() )} >Suivant</Button>
            <Button onClick={(()=> gotoPage(pageCount - 1))} disabled={!canNextPage} className={!canNextPage && classes.onClick} >Dernier</Button>
          </div>
      </div>

    </div>
    
    </div>
  </div>
  </div>
</div>;
}

export default RenderList;
