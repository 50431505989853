// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Accordion_accordion__\\+ifFd {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 80%; }
  .Accordion_accordion__\\+ifFd .Accordion_acco__fVYmp {
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    color: white;
    border-bottom: white; }

@media (max-width: 768px) {
  .Accordion_accordion__\\+ifFd {
    display: none; } }
`, "",{"version":3,"sources":["webpack://./src/elements/sideBar accordion/Accordion.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;EACR,WAAW;EACX,WAAW,EAAA;EAPf;IASQ,UAAU;IACV,2CAA2C;IAC3C,YAAY;IACZ,oBAAqB,EAAA;;AAM7B;EACI;IACI,aAAa,EAAA,EAChB","sourcesContent":[".accordion{\n    margin-top: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 5px;\n    width: 100%;\n    height: 80%;\n    .acco{\n        width: 90%;\n        background-color: rgba(255, 255, 255, 0.13);\n        color: white;\n        border-bottom: white ;\n    } \n \n}\n\n\n@media (max-width:768px) {\n    .accordion{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": `Accordion_accordion__+ifFd`,
	"acco": `Accordion_acco__fVYmp`
};
export default ___CSS_LOADER_EXPORT___;
