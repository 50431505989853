import { IconButton } from '@mui/material'
import React from 'react'
import classes from './ButtonLists.module.scss'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { deleteUser } from '../../store/superAdmin/superAdminActions';

function ListeDesUserButton(props) {

  const deleteOrg = ()=>{
    props.setUserID(props.id)
    props.openDialogFunc()
  }

  return (
    <div className={classes.button}>
       <IconButton onClick={() => props.OpenMenu(props.id) } >  <EditIcon className={classes.color}/> </IconButton>
        <IconButton onClick={deleteOrg} > <DeleteIcon className={classes.color} /> </IconButton>
    </div>
  )
}

export default ListeDesUserButton