import { IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect, useState } from 'react'
import classes from './ValidPayemnts.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { invalidPayment, validPayment } from '../../store/superAdmin/superAdminActions';

function ValidPayments(props) {

   
    const dispatch = useDispatch()
    const date = new Date().toISOString('fr-FR')

 
    const data = {
      status:'refuser',
        paymentStatus:'Non valide',
        processedAt:null
    }
    const validData = {
      status:'accepte',
      paymentStatus:'valide',
      processedAt:date,
      availableQrCodes:props.pay.number,
      orgKey:props.pay.orgKey
    }

    console.log(props.pay.orderId);
    console.log(props.pay.paymentStatus);
    
    let result = <></>

    if(props.pay.status === 'refuser'){
      result = <div className={classes.invalid} > Refuser </div>
    }
    else if (props.pay.paymentStatus === 'Non valide'){
      result = (<><div className={classes.invalid} > <p>Non Valid</p> </div>
      <IconButton onClick={()=> dispatch(validPayment(props.pay.orderId , validData , props.pay.orgKey))} > <CheckCircleOutlineIcon className={classes.icon} />  </IconButton>
      <IconButton  onClick={()=> dispatch(invalidPayment(props.pay.id , data))} > <CancelIcon className={classes.icon} />   </IconButton>  </>)
    }
    else if(props.pay.paymentStatus === 'valide'){
      result = <div className={classes.valid} > <p>Valide</p> </div>
    }
    else{
      result = <>error</>
    }


  return (
  <div className={classes.div}>
   {/* {props.pay.status === 'refuser' ?  <div className={classes.invalid} > Refuser </div> : props.pay.paymentStatus === 'Non valide' ? <><div className={classes.invalid} > <p>Non Valid</p> </div>
    <IconButton onClick={()=> dispatch(validPayment(props.pay.orderId , validData , props.pay.orgKey))} > <CheckCircleOutlineIcon className={classes.icon} />  </IconButton>
    <IconButton  onClick={()=> dispatch(invalidPayment(props.pay.id , data))} > <CancelIcon className={classes.icon} />   </IconButton>  </>   : <div className={classes.valid} > <p>Valid</p> </div>} */}
    {result}
  </div> 
  )
}

export default ValidPayments