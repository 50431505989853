import { Button } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { getOneOrders } from '../../store/user/userActions'
import classes from './ViewRecu.module.scss'
function ViewRecu(props) {

    console.log(props , 'props');
    const dispatch = useDispatch()
    const getRecuImage = ()=>{
        props.openRecuFunc()
        dispatch(getOneOrders(props.id))
    }


  return (
    <div className={classes.view}>
       {props.data.paymentType === 'CCP' || props.data.paymentType === 'CHEQUE' ? <Button onClick={getRecuImage} className={classes.btn}> View </Button> : <p> N/A </p>}
    </div>
  )
}

export default ViewRecu