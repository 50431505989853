import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classes from './Accordion.module.scss'
import Links from '../links/Links';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import GroupIcon from '@mui/icons-material/Group';
import ViewListIcon from '@mui/icons-material/ViewList';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useDispatch } from 'react-redux';
import { certificateActions } from '../../store/certficate/certficateSlice';

function AccordionBox(props) {



  
  return <div className={classes.accordion} >
      <Accordion defaultExpanded className={classes.acco} >
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon  sx={{color:'white'}} />}
          color='white'
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontSize:'0.7rem'}} >{props.isSuperAdmin ? 'Gestion des admin' : 'Gestion des documents' }</Typography>
        </AccordionSummary>
        <AccordionDetails   sx={{width:'90%' }} >
            <Links  Dashboard={<DashboardIcon className={classes.icone} />} Add={props.isSuperAdmin ?<ViewListIcon className={classes.icone} /> : <CreateNewFolderIcon className={classes.icone} />} 
            linkOne={props.isSuperAdmin ? '/Dashboard' :'/Dashboard'} linkTwo={props.isSuperAdmin ?'/Dashboard/admin-list' :'/Dashboard/add'} 
            textOne={'Tableau de bord'} textTwo={props.isSuperAdmin ? 'Liste des admins':'Ajouter document'} />
        </AccordionDetails>
      </Accordion>
      <Accordion  defaultExpanded className={classes.acco}  >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon  sx={{color:'white'}} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{fontSize:'0.7rem'}} >{props.isSuperAdmin? 'Gestion des organisations ': 'Commande QrCode'} </Typography>
        </AccordionSummary>
        <AccordionDetails>
           <Links isSuperAdmin={props.isSuperAdmin} linkThree={props.isSuperAdmin && '/Dashboard/users-list'} listeUser={<PeopleAltIcon className={classes.icone} />} textlisteUser={'Liste des utilisateur'}  Dashboard={props.isSuperAdmin ? <ViewListIcon className={classes.icone} />: <FactCheckIcon className={classes.icone} />} Add={<ShoppingCartIcon className={classes.icone} />} 
           linkOne={props.isSuperAdmin ? '/Dashboard/organisation-list' :'/Dashboard/demands'} linkTwo={props.isSuperAdmin ? '/Dashboard/demandes-list' : '/Dashboard/renew'} 
          textOne={props.isSuperAdmin ? 'Liste des organisations':'Liste des demandes'} textTwo={props.isSuperAdmin ?'Liste des demandes' :'Demander'} />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded  className={classes.acco} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'white'}} />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography sx={{fontSize:'0.7rem'}} >{props.isSuperAdmin ? 'Gestion paiement ':"Gestion de l'organisation"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <Links Dashboard={<InfoIcon className={classes.icone} />} Add={!props.isSuperAdmin && <GroupIcon className={classes.icone} />}  linkOne={props.isSuperAdmin ? '/Dashboard/Paramétrage':'/Dashboard/organisation'} linkTwo={'/Dashboard/users'}  textOne={props.isSuperAdmin ?"Paramétrage": "Informations de l'organisation"} textTwo={!props.isSuperAdmin && 'Liste des utilisateurs'} />
        </AccordionDetails>
      </Accordion>
  </div>;
}

export default AccordionBox;
