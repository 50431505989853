import React, { useRef } from 'react';
import Background from '../../../elements/background-login/Background';
import Button from '../../../elements/button/Button';
import InputForm from '../../../elements/inputForm/InputForm';
import classes from './passwordForget.module.scss'
import {Link} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { forgetPassword } from '../../../store/auth/authActions';
import { Helmet, HelmetProvider } from "react-helmet-async";


function PasswordForget() {


    const dispatch = useDispatch()

    const emailInput = useRef()
    const orgKeyInput = useRef()

    const sendRestLink = (e)=>{
        e.preventDefault()
        const email = emailInput.current.value
        const orgKey = orgKeyInput.current.value.toUpperCase()

        const info = {
            email,
            orgKey
        }

        dispatch(forgetPassword(info))
       
    }

  


    return (<div className={classes.AuthPage}>
        <HelmetProvider> <Helmet> <title> Uphawu | forget password </title> </Helmet> </HelmetProvider>
        <Background/>
    <div className={classes.auth} >
        <div className={classes.authBox} >
            <div className={classes.h2} >
            <h2  >Réinitialisation du mot de pass</h2>
            </div>
        <form  className={classes.authForm}>
        <div className={classes.authInput} >
        <InputForm inRef={emailInput} title='Adresse Email:' name="email" type='email'  />
        <InputForm inRef={orgKeyInput} title='Organisation Key:' name="orgKey" type='text'  />
        </div>
        <div className={classes.button}>
        <Button onClick={sendRestLink} textColor={'white'} color={'#0071BC'} btName='Envoyer le lien' />
        <Link to='/login' className={classes.link} ><Button textColor={'white'} color={'#343a40'} btName='Retour' /></Link> 
        </div>
        <>
        </>
        </form>
        </div>
      
      
    </div>
    
    </div>);
}

export default PasswordForget;
