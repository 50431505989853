// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__Fs2F3 {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.08em;
  outline: none;
  border: none; }

@media (max-width: 823px) {
  .Button_button__Fs2F3 {
    padding: 10px 5px;
    font-size: 14px; } }
`, "",{"version":3,"sources":["webpack://./src/elements/button/Button.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,uBAAsB;EACtB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,sBAAsB;EACtB,aAAa;EACb,YAAY,EAAA;;AAGhB;EACI;IACI,iBAAiB;IACjB,eAAe,EAAA,EAClB","sourcesContent":[".button{\n    display: flex;\n    align-items: center;\n    justify-content:center;\n    cursor: pointer;\n    width: 100%;\n    height: 100%;\n    padding: 15px 20px;\n    border-radius: 5px ;\n    width: 100%;\n    font-weight: 500;\n    font-size: 20px;\n    letter-spacing: 0.08em;\n    outline: none;\n    border: none;\n}\n\n@media (max-width:823px) {\n    .button{\n        padding: 10px 5px;\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__Fs2F3`
};
export default ___CSS_LOADER_EXPORT___;
