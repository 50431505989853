
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable , useSortBy ,useGlobalFilter, usePagination } from 'react-table';
import classes from './Steps.module.scss'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MatrialUiBtn from '@mui/material/Button';
import {Rings} from 'react-loader-spinner'
import Button from '../../../elements/button/Button';
import moment from 'moment';
import { uploaderLesImages } from '../../../store/certficate/certficateActions';
import { certificateActions } from '../../../store/certficate/certficateSlice';
import LoadingRing from '../../../elements/loadingRing/loadingRing';
import DownloadCertficates from '../../../elements/downloadCertfiactes/DownloadCertficates';



function StepFour(props) {

    const certificates = useSelector(state => state.certificate.getTypeDocuments)
    const dispatch = useDispatch()
    const [file , setFile] = useState(null)
    const [certficateId , setCertificteId] = useState('')


    const certId = certificates?.map(cert => cert.certificateId)
    const idHandler = (id)=>{
      setCertificteId(id.certificateId)
    }

   

 

    const uploadImages = ( e , id )=>{

        setFile(e.target.files[0])
    }

    console.log(certficateId);
 
      useEffect(()=>{
        if (certficateId && file) {
          dispatch(uploaderLesImages(file , certficateId , certId))
        }
      },[file , dispatch])

      const Column = [
        {
          Header : 'ID',
          accessor : 'certificateId'
        },
        {
          Header : 'Nom & Prénom',
          accessor : data => {
            return <p> {`${data.nom || data.nom_ceo} ${data.prenom || data.nom_startup || data.nom_projet}`} </p>
          }
        },
        {
          Header : 'Email',
          accessor : 'email'
        },
      
        {
          Header : 'Uploader',
          accessor : date => {
            return <div  onChange={(e) => uploadImages(e , date)}  style={{display:'flex' , alignItems:'center' , justifyContent:'center' ,width:'100%' , height:'100%' }}>
                <input  style={{display:'none'}} id='upload' type={'file'} />
                <label onClick={()=> idHandler(date)} htmlFor='upload'style={{cursor:'pointer', display:'flex' , alignItems:'center' , justifyContent:'center' , backgroundColor:'#0071BC' , color:'white' , width:'90%' ,height:'100%'}}> uploader </label>
            </div>
          }
        },
        {
            Header : 'Image uploadée',
            accessor : date => {
    return <LoadingRing  certificates={date} file={file} />
    
            }
        },
      
       
      ]
    

  const data = useMemo(()=> certificates, [certificates])
  const columns = useMemo(()=> Column ,[])

  const tableInstance = useTable({
    columns ,
    data
  }, useGlobalFilter , useSortBy , usePagination)

  const {getTableProps , getTableBodyProps , headerGroups , page , nextPage , previousPage  , setPageSize ,canNextPage , gotoPage , pageCount , canPreviousPage , prepareRow , state , setGlobalFilter } = tableInstance
  const {globalFilter , pageIndex , pageSize} = state 



  const next = ()=>{
    dispatch(certificateActions.switchSteps(5))
  }

    return (
        <div className={classes.uploaderImagesTable}>
            <div className={classes.box}>
            <div className={classes.title} ><h3>Uploader les images des documents</h3></div>
            <div className={classes.tableBox}>

<div  className={classes.search} >
      <div className={classes.select} >
          <p>Afficher</p>
          <select value={pageSize} onChange={((e)=>setPageSize(Number(e.target.value)))} >
          {[15 , 30 ,45 , 60 ].map(page => (
            <option key={page} value={page} > {page} </option>
          ))}
          </select>
          <p>éléments</p>
      </div>
      <div className={classes.searchBar} >
          <p>Rechercher:</p>
          <input value={globalFilter} onChange={((e)=> setGlobalFilter(e.target.value))} />
      </div>
</div>
<div className={classes.wrapTable} >

<table  {...getTableProps}  className={classes.tb}>
      <thead  className={classes.head} >
        {headerGroups.map(head => (
           <tr {...head.getHeaderGroupProps()} >
                {head.headers.map(colum => (
                      <th className={classes.Trhead} {...colum.getFooterProps(colum.getSortByToggleProps())} > {colum.render('Header')} 
                        <span>{colum.isSorted ? (colum.isSortedDesc ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> ) : '' }</span>
                      </th>
                ))}
          </tr>
        ))}
       
      </thead>
     {certificates.length > 0 ? <tbody className={classes.body} {...getTableBodyProps} >
        {page.map(row => {
          prepareRow(row)
          return (
            <tr className={classes.Trbody} {...row.getRowProps()} >
                {
                  row.cells.map(cell => {
               return  <td className={classes.Tdbody} {...cell.getCellProps()} > {cell.render('Cell')} </td>
                  } )
                }
            </tr>
          )
        } )}
        
      </tbody> : <tbody className={classes.message} >
          <tr  >
              <td>
                   Pas encore de documents
              </td>
        </tr>
      </tbody>}
</table>
      
</div>



<div  className={classes.button}>
    <div className={classes.page} >
      <p>Affichage de l'élement {pageIndex + 1} à {pageSize} sur {pageCount} éléments</p>
    </div>
    <div className={classes.pagination} >
      <Button onClick={(()=> gotoPage(0))} disabled={!canPreviousPage} >Premier</Button>
      <Button disabled={!canPreviousPage}  onClick={(()=> previousPage())} >Précédent</Button>
      <Button>{pageIndex + 1} </Button>
      <Button disabled={!canNextPage}   onClick={(()=> nextPage() )} >Suivant</Button>
      <Button onClick={(()=> gotoPage(pageCount - 1))} disabled={!canNextPage}  >Dernier</Button>
    </div>
</div>
<div className={classes.buttonSuivant} >
     <MatrialUiBtn onClick={next}  className={classes.MatrialUiBtnbutton} >Suivant</MatrialUiBtn>
</div>
</div>
        </div>
</div>
    )
}

export default StepFour;
