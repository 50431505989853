// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RenderProfile_RenderProfile__peKAc {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }

@media (max-width: 768px) {
  .RenderProfile_RenderProfile__peKAc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; } }
`, "",{"version":3,"sources":["webpack://./src/components/profile/RenderProfile.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW,EAAA;;AAGf;EACI;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW,EAAA,EACd","sourcesContent":[".RenderProfile{\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n}\n\n@media (max-width:768px) {\n    .RenderProfile{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RenderProfile": `RenderProfile_RenderProfile__peKAc`
};
export default ___CSS_LOADER_EXPORT___;
