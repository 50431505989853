import React, { useEffect, useRef } from 'react';
import Background from '../../elements/background-login/Background';
import Button from '../../elements/button/Button';
import InputForm from '../../elements/inputForm/InputForm';
import classes from './Login.module.scss'
import {Link} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/auth/authActions';
import FullLoading from '../../elements/full-loading-page/FullLoading';
import { Helmet, HelmetProvider } from "react-helmet-async";


function Login() {
  
  const dispatch = useDispatch()
  let LoginEmail =  useRef()
  let LoginPassword =  useRef()
  let LoginOrgKey =  useRef()

  const isLoading = useSelector(state => state.auth.isLoading)
  const resetEmail = useSelector(state => state.auth.resetEmail)

  const loginHandler = async (e)=>{
    e.preventDefault()
    
    const email   = LoginEmail.current.value
    const password   = LoginPassword.current.value
    const orgKey   = LoginOrgKey.current.value.toUpperCase()

    let newInfo = {
      email,password,orgKey
    }
    dispatch(login(newInfo))
  }
 

  //<Link className={classes.link} to='/forget-orgKey' ><p className={classes.moreSetting} >Organisation clé oublié</p></Link> 

  return (
  <div className={classes.AuthPage}>
    <HelmetProvider> <Helmet><title>Uphawu | login</title></Helmet> </HelmetProvider>
     {isLoading && <FullLoading/>}
      <Background/>
  <div className={classes.auth} >
      <div className={classes.authBox} >
      <div className={classes.h2} >
      <h2  >S'authentifier</h2>
      </div>
      <form onSubmit={loginHandler}  className={classes.authForm}>
      <div className={classes.authInput} >
      <InputForm title='Adresse Email:' name="email" value={resetEmail ? resetEmail : undefined} type='email' inRef={LoginEmail} />
      <InputForm title='Mot de passe:' name='password' type='password' inRef={LoginPassword} />
      <InputForm title='Organisation clé:' name='orgKey' type='text' inRef={LoginOrgKey} />
      <Link className={classes.link} to='/forget-password' ><p className={classes.moreSetting}>Mot de pass oublié</p></Link> 
      
      </div>
      <div className={classes.button}>
      <Button textColor={'white'} notify={loginHandler} color={'#0071BC'} btName='Se Connecter' />
      <Link className={classes.link} to='/signup' ><Button textColor={'white'} color={'#343a40'} btName="Vous n'avez pas de compte ? Inscrivez-vous!" /></Link> 
      </div>
      </form>
      </div>
    
    
  </div>
  
  </div> 
 )
}




export default Login;
