// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0; }

.App_app__GuJBs {
  position: relative;
  width: 100%;
  height: 100%; }

@media (max-width: 768px) {
  .App_app__GuJBs {
    width: 100%;
    height: 100%; } }
`, "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACI,SAAS;EACT,UAAU,EAAA;;AAGd;EACI,kBAAkB;EAClB,WAAW;EACX,YAAY,EAAA;;AAGhB;EACE;IACE,WAAW;IACX,YAAY,EAAA,EACb","sourcesContent":["*{\n    margin: 0;\n    padding: 0;\n}\n\n.app{\n    position: relative;\n    width: 100%;\n    height: 100%;\n    \n}\n@media (max-width:768px) {\n  .app{\n    width: 100%;\n    height: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `App_app__GuJBs`
};
export default ___CSS_LOADER_EXPORT___;
