// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Links_Links__cCX6D {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  width: 100%; }
  .Links_Links__cCX6D .Links_Link__911sx {
    text-decoration: none;
    color: white; }
  .Links_Links__cCX6D div {
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    gap: 10px; }

@media (max-width: 768px) {
  .Links_Links__cCX6D {
    width: 80%; }
    .Links_Links__cCX6D .Links_Link__911sx {
      width: 80%; }
    .Links_Links__cCX6D div {
      font-size: 0.5rem;
      gap: 2px; } }
`, "",{"version":3,"sources":["webpack://./src/elements/links/Links.module.scss"],"names":[],"mappings":"AACA;EACI,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,SAAS;EACT,WAAW,EAAA;EALf;IAOQ,qBAAqB;IACrB,YAAY,EAAA;EARpB;IAWQ,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,SAAS,EAAA;;AAKjB;EAEA;IAEI,UAAU,EAAA;IAFd;MAIQ,UAAU,EAAA;IAJlB;MAQQ,iBAAiB;MACjB,QAAQ,EAAA,EACX","sourcesContent":["\n.Links{\n    display: flex;\n    align-items: flex-start;\n    flex-direction: column;\n    gap: 30px;\n    width: 100%;\n    .Link{\n        text-decoration: none;\n        color: white;\n    }\n    div{\n        justify-content: space-between;\n        display: flex;\n        align-items: center;\n        font-size: 0.7rem;\n        gap: 10px;\n    }\n    \n}\n\n@media (max-width:768px) {\n    \n.Links{\n \n    width: 80%;\n    .Link{\n        width: 80%;\n\n    }\n    div{\n        font-size: 0.5rem;\n        gap: 2px;\n    }\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Links": `Links_Links__cCX6D`,
	"Link": `Links_Link__911sx`
};
export default ___CSS_LOADER_EXPORT___;
