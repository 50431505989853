import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AlertDialog from '../../elements/dialogsAlert/DialogsAlert';
import { getOneOrg } from '../../store/user/userActions';
import ViewCert from '../viewCert/ViewCert';
import StepFive from './addCertficateSteps/StepFive';
import StepFour from './addCertficateSteps/StepFour';
import StepOneAdd from './addCertficateSteps/StepOne';
import StepThree from './addCertficateSteps/StepThree';
import StepTwoAdd from './addCertficateSteps/StepTwo';
import StepTwoAddMore from './addCertficateSteps/StepTwoAddMore';
import { Helmet, HelmetProvider } from "react-helmet-async";

function RenderAdd(props) {

  const dispatch = useDispatch()
  const steps = useSelector(state => state.certificate.currentStep)
  const isAddOne = useSelector(state => state.certificate.addOne)
  const user = useSelector(state => state.auth.user)
  const [selectedOption , setSelectedOption] = useState()
  const [isStepThree , setIsStepThree] = useState()
  const [data , setData] = useState({})
  const [ExperyDate , setExperyDate] = useState()
  const [title, setTitle] = useState("");


  useEffect(() => {
    const fetchData = async () => {
      setTitle("Add certificates");
    };

    fetchData();
  }, []);

  
  useEffect(()=>{

    if (!props.selectValueToAdd) {
      dispatch(getOneOrg(user.orgKey))
    } else{
      dispatch(getOneOrg(props.selectValueToAdd.value))
    }

  }, [props , user, dispatch])



  useEffect(()=>{
    props.isStepThreeNavBar(isStepThree)
  },[props , isStepThree])

  switch (steps) {
    case 1:
      return ( <> <HelmetProvider> <Helmet> <title> {title}  </title> </Helmet> </HelmetProvider> <StepOneAdd {...props}/></> )
    case 2:
      if (isAddOne) {
        return <StepTwoAdd  {...props}/>
      }else{
        return <StepTwoAddMore ExperyDate={setExperyDate} data={setData} selectedOption={setSelectedOption} {...props}/>
      }
    case 3:
      if (isAddOne) {
        return <ViewCert isSTEP={true}  {...props} /> 
      } else{
        console.log("i am here !");
        return <StepThree ExperyDate={ExperyDate} data={data} typeDocument={selectedOption?.value} {...props}/>
      }
    case 4:
        return <StepFour  {...props}/>
    case 5:
        return <StepFive {...props} />
    default:
      break;
  }
}

export default RenderAdd