import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { uploadRecu } from '../../store/user/userActions'
import classes from './uploadRecu.module.scss'

function UploadRecu(props) {

    const dispatch = useDispatch()
    const [orderId , setOrderId] = useState(null)
    const [file , setFile ] = useState(null)
  
    

    const sumbitRecu = (e) => {
      
        setFile(e.target.files[0])
    }
    console.log(orderId);

    const getId = () =>{
      const id = props.etat.id
      console.log(id);
      let formData = new FormData()
      formData.append('recu' , file)

      if (file) {
        dispatch(uploadRecu(formData , id))
      }else{
        return toast.warn('Selectionner un fichier')
      }

    }
  return (
    <div className={classes.upload}>
       {props.etat.paymentType === 'CHEQUE' || props.etat.paymentType === 'CCP' ?<> <input onChange={sumbitRecu}  id='file' type={'file'}  ></input>
        <Button onClick={getId}  className={classes.btnUpload} htmlFor='file'> Upload </Button> </> : <p> N/A </p>}
      </div>
  )
}

export default UploadRecu