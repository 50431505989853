// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CertficatesUploaded_cert__ipyqB {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  height: 100%; }
  .CertficatesUploaded_cert__ipyqB .CertficatesUploaded_certfictes__3Nk-2 {
    position: relative;
    width: 90%;
    height: 90%;
    overflow: hidden;
    border: 1px solid #0e0505; }
    .CertficatesUploaded_cert__ipyqB .CertficatesUploaded_certfictes__3Nk-2 div {
      position: relative;
      width: 100%;
      height: 100%; }
      .CertficatesUploaded_cert__ipyqB .CertficatesUploaded_certfictes__3Nk-2 div img {
        width: 100%;
        height: 100%; }
      .CertficatesUploaded_cert__ipyqB .CertficatesUploaded_certfictes__3Nk-2 div .CertficatesUploaded_qrCode__eH0Gr {
        width: 100%;
        height: 100%;
        z-index: 100136; }
  .CertficatesUploaded_cert__ipyqB .CertficatesUploaded_edit__q70is {
    display: flex;
    align-items: center;
    gap: 10px; }
`, "",{"version":3,"sources":["webpack://./src/elements/Certficte/CertficatesUploaded.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,sBAAsB;EACtB,WAAW;EACX,YAAY,EAAA;EANhB;IAQQ,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,yBAA+B,EAAA;IAZvC;MAcY,kBAAkB;MAClB,WAAW;MACX,YAAY,EAAA;MAhBxB;QAmBgB,WAAY;QACZ,YAAY,EAAA;MApB5B;QAuBgB,WAAW;QACX,YAAY;QACZ,eAAe,EAAA;EAzB/B;IAgCQ,aAAa;IACb,mBAAmB;IACnB,SAAS,EAAA","sourcesContent":[".cert{\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    .certfictes{\n        position: relative;\n        width: 90%;\n        height: 90%;   \n        overflow: hidden;\n        border: 1px solid rgb(14, 5, 5);\n        div{\n            position: relative;\n            width: 100%;\n            height: 100%;\n\n            img{\n                width: 100% ;\n                height: 100%;\n            }\n            .qrCode{\n                width: 100%;\n                height: 100%;\n                z-index: 100136;\n             \n            }\n        }\n        \n    }\n    .edit{\n        display: flex;\n        align-items: center;\n        gap: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cert": `CertficatesUploaded_cert__ipyqB`,
	"certfictes": `CertficatesUploaded_certfictes__3Nk-2`,
	"qrCode": `CertficatesUploaded_qrCode__eH0Gr`,
	"edit": `CertficatesUploaded_edit__q70is`
};
export default ___CSS_LOADER_EXPORT___;
