import React from 'react';
import classes from './inputForm.module.scss'

function InputForm(props) {

  return <div  className={classes.input}  >
    <p>{props.title}</p>
    <input name={props.name} type={props.type} ref={props.inRef} required  value={props.value} onChange={props.onChange}/>
    </div>
}
 
export default InputForm;
