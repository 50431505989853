import { configureStore } from '@reduxjs/toolkit'
import authSlice from './auth/authSlice'
import stepSlice from './auth/stepsSlice'
import certificateSlice from './certficate/certficateSlice'
import superAdminSlice from './superAdmin/superAdminSlice'
import userSlice from './user/userSlice'


export const store = configureStore({
    reducer : {auth:authSlice.reducer , steps:stepSlice.reducer  , user:userSlice.reducer , certificate:certificateSlice.reducer , superAdmin:superAdminSlice.reducer}
})