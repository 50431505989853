import { Button, Input } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classes from './OrgMenu.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { inviteNewAdmin, updateOragnsiation } from '../../store/superAdmin/superAdminActions'

import DocumentsCheckbox from '../checkbox/documentsCheckBox'
import { getOneOrg } from '../../store/user/userActions';


const select = [{ id:"AT01",name:"Attestation de formation" } ,{id:'AT02',name:"Attestation d'incubation" }, {id:'AT03',name:"Attestation de participation" } , {id:'AT04',name:"Attestation de travail"} ,{id:'CR01',name:"Compte Rendu d'Analyse Eplogique" },{id:'IN01',name:"Invitation Evènement" },{ id:'CT01',name:"Certificat de Labélisation" },{id:'AT05',name:"Attestation d'adhésion" }]


function EditOrg(props) {

    const orgKey = props.orgKey
    const dispatch = useDispatch()

    const [canEdit , setCanEdit] = useState(false)

    useEffect(()=>{
        if (orgKey) {

         dispatch(getOneOrg(orgKey))

        }
    },[dispatch , orgKey])

    const canEditFunc = ()=>{
        setCanEdit(oldValue => !oldValue)
    }


    const org = useSelector(state => state.user.org)

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState(org?.documentsType);
    const [list, setList] = useState([]);


    useEffect(()=>{
        setList(select)
    },[])

    const [data , setData] = useState({
        OrgName:org.name,
        LinkedIn:org.linkedInId ,
    })

    console.log(data);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
          setIsCheck([]);
        }
      };
    

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
          setIsCheck(isCheck.filter(item => item !== id));
        }
      };

    const dataHandler = (e) => {
        let {name , value} = e.target
        setData(oldValue => {
            return {
                ...oldValue,
                [name]:value
            }
        })

    }


    const submitInvite = ()=>{
        
        dispatch(updateOragnsiation(data , isCheck , orgKey))
    }

  return (
    <div className={classes.background} >
        <div className={classes.addBox}>
        <IconButton onClick={props.closeMenu} className={classes.closeIconBox} ><CloseIcon className={classes.closeIcon} /></IconButton>

            <div className={classes.title}>
                <h3>Modifier une Organisation</h3>
                <hr/>
            </div>
            <div className={classes.inputs} >
                <div onChange={dataHandler} className={classes.insideInputs}>
                        <div className={classes.emailInput}>
                            <label>Nom de Organisation</label>
                            <Input name='OrgName' type='text' disabled={canEdit ? false : true}  value={canEdit ? null : org.name}/>
                        </div>
                        <div className={classes.emailInput}>
                            <label>LinkedIn</label>
                            <Input name='LinkedIn'  type='text' disabled={canEdit ? false : true}  value={canEdit ? null : org.linkedInId} />
                        </div>

                     
                </div>
                <div className={classes.emailInput}>
                            <DocumentsCheckbox 
                              
                            type="checkbox"
                            name="selectAll"
                            id="selectAll"
                            handleClick={handleSelectAll}
                            isChecked={isCheckAll}/>
                            {list.map(({id , name})=> (<> <DocumentsCheckbox   key={name}
                              disabled={canEdit}
                              type="checkbox"
                              name={name}
                              id={id}
                              handleClick={handleClick}
                              isChecked={isCheck?.includes(id)}/>
                              </>
                            ) )}
                </div>
            </div>
            <div className={classes.button} >
             {canEdit ? <Button onClick={submitInvite} > Envoyer </Button>    :<Button onClick={canEditFunc}> Modifier </Button>}
            </div>
        </div>
    </div>
  )
}

export default EditOrg