import React , {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import Background from '../../../elements/background-login/Background';
import Button from '../../../elements/button/Button';
import CheckBox from '../../../elements/checkbox/CheckBox';
import { authActions } from '../../../store/auth/authSlice';
import classes from './Steps.module.scss'



const select = [{ id:"AT01",value:"Attestation de formation" } ,{id:'AT02',value:"Attestation d'incubation" }, {id:'AT03',value:"Attestation de participation" } , {id:'AT04',value:"Attestation de travail"} ,{id:'CR01',value:"Compte Rendu d'Analyse Eplogique" },{id:'IN01',value:"Invitation Evènement" },{ id:'CT01',value:"Certificat de Labélisation" },{id:'AT05',value:"Attestation d'adhésion" }]

function StepFive({ SetCheckBoxId }) {

  const [checkBoxState , setCheckBox] = useState([])
  const [checkIsTrue , setCheckIsTrue ] = useState(false)


  useEffect(()=>{
    SetCheckBoxId(checkBoxState)
  },[checkBoxState ,SetCheckBoxId ])

  console.log(checkBoxState);

  const handelChange = (e)=>{
    let {name , checked } = e.target
    if (name === "allSelect") {
      if (checked) {
       for (let i = 0; i < select.length; i++) {
         const element = select[i];
         setCheckBox(oldValue =>  [...oldValue , element.id ]);
         setCheckIsTrue(true)
       }
      }else{
        for (let i = 0; i < select.length; i++) {
          const element = select[i];
         setCheckBox(oldValue => oldValue.filter((_,i) => i !== element.id.length - 4));
         setCheckIsTrue(false)

        }
      }
    }else{
      if (checked) {
        setCheckBox(oldValue =>  [...oldValue , e.target.value]);
      }else{
      setCheckBox(oldValue => oldValue.filter((_,i) => i !== checkBoxState.length - 1));
      }
    }
  
  }

  const dispatch = useDispatch()

  const next = ()=>{
    dispatch(authActions.switchStep(6))
  }

  const previous = ()=>{
    dispatch(authActions.switchStep(4))
  }

  

  return ( <div className={classes.SignUpSteps}>
    <Background/>
  <div className={classes.SignUp} >
    <div className={classes.SignUpBox} >
      <div className={classes.h2}  >
      <h2  >
      Quel type de document voulez vous signez actuellement?
  </h2>
      </div>
    <div  className={classes.signUpForm}>
    <div className={classes.inputBox} >
    <div className={classes.checkbox} >
      <p>Type de documents:</p>
        <div>
        <input name="allSelect" type={'checkbox'} onChange={handelChange}  />
        <p>tout selectionné</p>
        </div>
      <hr/>
    </div>
    <div className={classes.checkbox} >
      {
        select.map(check => <CheckBox onChange={handelChange}  key={check.id} name={check.value} onChecked={checkIsTrue} value={check.id} >{check.value}</CheckBox>)
      }
    </div>
  </div>
    <div className={classes.button}>
    <Button onClick={next} textColor={'white'}   color={'#0071BC'} > Continuer</Button> 
    <Button onClick={previous} textColor={'white'}   color={'#343a40'} > Retour </Button>
    </div>
    </div>
    </div>
  </div>
  </div> );
}

export default StepFive;
