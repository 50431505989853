import React, { useEffect } from "react";
import Background from "../../../elements/background-login/Background";
import classes from "./Steps.module.scss";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth/authSlice";
import Button from "../../../elements/button/Button";

function StepThree({ createOrJoin, setRadioBox, radioBox }) {
  useEffect(() => {
    createOrJoin.onChange({ target: { value: "Créer une organisation" } });
  }, []);

  const dispatch = useDispatch();

  const next = () => {
    dispatch(authActions.switchStep(4));
  };
  const previous = () => {
    dispatch(authActions.switchStep(2));
  };

  useEffect(() => {
    if (createOrJoin.value === "Rejoindre une organisation") {
      dispatch(authActions.willCreate(false));
    } else {
      dispatch(authActions.willCreate(true));
    }
  }, [createOrJoin, dispatch]);

  return (
    <div className={classes.SignUpSteps}>
      <Background />
      <div className={classes.SignUp}>
        <div className={classes.SignUpBox}>
          <div className={classes.h2}>
            <h2>Que souhaitez vous faire ?</h2>
          </div>
          <div className={classes.signUpForm}>
            <div
              onChange={(e) => setRadioBox(e.target.value)}
              className={classes.checkbox}
            >
              <div>
                <input
                  type='radio'
                  checked={radioBox === "Créer une organisation" ? true : false}
                  onChange={createOrJoin.onChange}
                  name='createOrJoin'
                  id='Create'
                  value='Créer une organisation'
                />
                <label htmlFor='Create'>Créer une organisation</label>
              </div>
              <div>
                <input
                  type='radio'
                  checked={
                    radioBox === "Rejoindre une organisation" ? true : false
                  }
                  onChange={createOrJoin.onChange}
                  name='createOrJoin'
                  id='join'
                  value='Rejoindre une organisation'
                />
                <label htmlFor='join'>Rejoindre une organisation</label>
              </div>
            </div>
            <div className={classes.button}>
              <Button onClick={next} textColor={"white"} color={"#0071BC"}>
                {" "}
                Continuer
              </Button>
              <Button onClick={previous} textColor={"white"} color={"#343a40"}>
                {" "}
                Quitter l'inscription
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepThree;
