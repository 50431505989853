import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useRowSelect,
} from "react-table";
import classes from "./Steps.module.scss";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MatrialUiBtn from "@mui/material/Button";
import Notify from "../../../elements/notification/Notify";
import AddUserPopUp from "../../../elements/addUserPopUp/AddUserPopUp";
import Button from "../../../elements/button/Button";
import CheckBoxForTableSelect from "../../../elements/checkbox/checkBoxForTableSelect";
import { certificateActions } from "../../../store/certficate/certficateSlice";
import { toast } from "react-toastify";
import AlertDialog from "../../../elements/dialogsAlert/DialogsAlert";

const Column = [
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Nom",
    accessor: "lastName",
  },
];

function StepOneAdd(props) {
  const [open, setOpenDialogs] = useState(false);
  const orgInfo = useSelector((state) => state.user.org);

  const USERS = useSelector((state) => state.user.allUsers);
  const dispatch = useDispatch();

  const data = useMemo(() => USERS, [USERS]);
  const columns = useMemo(() => Column, []);

  useEffect(() => {
    if (orgInfo?.availableQrCodes === 0) {
      setOpenDialogs(true);
    } else {
      setOpenDialogs(false);
    }
  }, [orgInfo]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBoxForTableSelect {...getToggleAllRowsSelectedProps} />
            ),
            Cell: ({ row }) => (
              <CheckBoxForTableSelect {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    canNextPage,
    gotoPage,
    pageCount,
    canPreviousPage,
    prepareRow,
    state,
    selectedFlatRows,
  } = tableInstance;

  const [selectAddDoc, setSelectAddDoc] = useState("AddOne");

  const addHandler = (e) => {
    setSelectAddDoc(e.target.value);
  };
  useEffect(() => {
    if (selectAddDoc === "AddOne") {
      dispatch(certificateActions.setAddOne(true));
    } else if (selectAddDoc === "AddMore") {
      dispatch(certificateActions.setAddOne(false));
    }
  }, [selectAddDoc, dispatch]);
  const { pageIndex, pageSize } = state;

  const userIds = selectedFlatRows.map((row) => row.original.id);

  const next = () => {
    dispatch(certificateActions.setCertifictes([]));
    dispatch(certificateActions.setDocuments([]));

    if (!userIds.length) {
      toast.warn("Séléctionner un ou plusieurs signataires");
    } else {
      dispatch(certificateActions.switchSteps(2));
      dispatch(certificateActions.setSelectedUsersId(userIds));
    }
  };
  return (
    <div className={classes.add}>
      <AlertDialog
        open={open}
        messageOne='Pas assez de QrCode'
        messageTwo="Vous n'avez pas suffisament de Codes QR ! veuillez d'abord renouveler votre abonnement"
        messageThree='Acheter'
        close={setOpenDialogs}
      />
      {props.closeSettingMenuValue && <Notify />}
      {props.addUserPopUpValue && (
        <AddUserPopUp closeMenu={props.addUserPopUpFunc} />
      )}

      <div className={classes.addBox}>
        <div className={classes.option}>
          <div className={classes.title}>
            <h3>1- Ajouter un ou plusieurs documents</h3>
          </div>
          <div onChange={addHandler} className={classes.select}>
            <div>
              <input
                type='radio'
                checked={selectAddDoc === "AddOne" ? true : false}
                value='AddOne'
              />
              <label> Ajouter un documents</label>
            </div>
            <div>
              <input
                type='radio'
                checked={selectAddDoc === "AddMore" ? true : false}
                value='AddMore'
              />
              <label> Ajouter plusieurs documents </label>
            </div>
          </div>
          <div className={classes.title}>
            <h3>2- Séléctionner un ou plusieurs signataires</h3>
          </div>
        </div>
        <div className={classes.signataires}>
          <div className={classes.tableBox}>
            <div className={classes.wrapTable}>
              <table {...getTableProps} className={classes.table}>
                <thead className={classes.head}>
                  {headerGroups.map((head) => (
                    <tr {...head.getHeaderGroupProps()}>
                      {head.headers.map((colum) => (
                        <th
                          className={classes.Trhead}
                          {...colum.getFooterProps(
                            colum.getSortByToggleProps()
                          )}
                        >
                          {" "}
                          {colum.render("Header")}
                          <span>
                            {colum.isSorted ? (
                              colum.isSortedDesc ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className={classes.body} {...getTableBodyProps}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr className={classes.Trbody} {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              className={classes.Tdbody}
                              {...cell.getCellProps()}
                            >
                              {" "}
                              {cell.render("Cell")}{" "}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div></div>
            <div className={classes.buttonPagination}>
              <div className={classes.page}>
                <p>
                  Affichage de l'élement {pageIndex + 1} à {pageSize} sur{" "}
                  {pageOptions.length} éléments
                </p>
              </div>
              <div className={classes.pagination}>
                <Button
                  onClick={() => gotoPage(0)}
                  className={!canPreviousPage && classes.onClick}
                  disabled={!canPreviousPage}
                >
                  Premier
                </Button>
                <Button
                  disabled={!canPreviousPage}
                  className={!canPreviousPage && classes.onClick}
                  onClick={() => previousPage()}
                >
                  Précédent
                </Button>
                <Button> {pageIndex + 1} </Button>
                <Button
                  disabled={!canNextPage}
                  className={!canNextPage && classes.onClick}
                  onClick={() => nextPage()}
                >
                  Suivant
                </Button>
                <Button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className={!canNextPage && classes.onClick}
                >
                  Dernier
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.button}>
          <MatrialUiBtn onClick={next} className={classes.MatrialUiBtnbutton}>
            Suivant
          </MatrialUiBtn>
        </div>
      </div>
    </div>
  );
}

export default StepOneAdd;
