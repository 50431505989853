import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    
    selectedUserIds:[],
    currentStep:1,
    addOne:true,
    certificates:[],
    documentType:[],
    getAllCertficates:[],
    getDocumentsData:[],
    getCertificates:[],
    getTypeDocuments:[],
    imageIsUploading:false,
    emailSentSuccessfully:false
}

const certificateSlice = createSlice({
    name:'certificate' ,
    initialState ,
    reducers : {    
        switchSteps(state , action){
            state.currentStep = action.payload
        },
        setAddOne(state ,action){
            state.addOne = action.payload
        },
        setSelectedUsersId(state , action){
            state.selectedUserIds = action.payload
        },
        setCertifictes(state , action){
            state.certificates = action.payload
        },
        setDocuments(state , action) {
            state.documentType = action.payload
        },
        setGetAll(state , action){
            state.getAllCertficates = action.payload
        },
        setDocumentsData(state , action){
            state.getDocumentsData = action.payload
        },
        setCertifictesAddMultiple(state , action){
            state.getCertificates = action.payload
        },setTypeDocuments(state , action){
            state.getTypeDocuments = action.payload
        },setEmailSentSuccessfully(state , action){
            state.emailSentSuccessfully = action.payload
        },setImageIsUploading(state , action){
            state.imageIsUploading = action.payload
        }
    }
})

export const certificateActions = certificateSlice.actions
export default certificateSlice