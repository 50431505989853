import React from 'react'
import classes from './404.module.scss'
import { Helmet, HelmetProvider } from "react-helmet-async";

function Page404() {
  return (
	<div className={classes.notfound}>
    <HelmetProvider><Helmet><title>Not found</title></Helmet> </HelmetProvider>
    <div class={classes.notfoundDiv}>
        <div class={classes.notfound404}>
            <h1>Oops!</h1>
            <h2>404 - The Page can't be found</h2>
        </div>
        <a href="/">Go TO Homepage</a>
    </div>
</div>
  )
}

export default Page404