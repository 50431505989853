import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar/NavBar";
import RenderPage from "../../components/dashboard/RenderPage";
import SideBar from "../../components/sideBar/SideBar";
import DashboardIcon from "@mui/icons-material/Dashboard";
import classes from "./Dashboard.module.scss";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InfoIcon from "@mui/icons-material/Info";
import GroupIcon from "@mui/icons-material/Group";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Link, Route, Switch } from "react-router-dom";
import RenderAdd from "../../components/add/RenderAdd";
import RenderDemander from "../../components/demander/RenderDemander";
import RenderList from "../../components/listedemander/RenderList";
import RenderInfo from "../../components/info/RenderInfo";
import RenderUsers from "../../components/users/RenderUsers";
import RenderProfile from "../../components/profile/RenderProfile";
import { useSelector } from "react-redux";
import FullLoading from "../../elements/full-loading-page/FullLoading";
import Page404 from "../404Page/404";
import ViewCert from "../../components/viewCert/ViewCert";
import DownloadCertficates from "../../elements/downloadCertfiactes/DownloadCertficates";
import EditCertficates from "../../elements/editCertficates/EditCertficates";

function Dashboard(props) {
  const isLogout = useSelector((state) => state.auth.isLogout);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const inviteSent = useSelector((state) => state.user.inviteSent);

  const [closeMenu, setCloseMenu] = useState(false);
  const [isAddMoreOption, setIsAddMoreOption] = useState(false);
  const [closeMenuQrCode, setCloseMenuQrCode] = useState(false);
  const [closeSettingMenu, setCloseSettingMenu] = useState(false);
  const [closeAddUserPopUp, setCloseAddUserPopUp] = useState(false);
  const [selectValueDashboard, setSelectValue] = useState();
  const [viewCertficate, setViewCertficate] = useState();
  const [openCert, setOpenCert] = useState(false);

  useEffect(() => {
    if (inviteSent) {
      setCloseAddUserPopUp((oldValue) => !oldValue);
    }
  }, [inviteSent]);

  console.log(isAddMoreOption);
  useEffect(() => {
    props.selectValueFunc(selectValueDashboard);
  }, [selectValueDashboard, props]);

  function close() {
    setCloseMenu((oldValue) => !oldValue);
  }
  function QrCodeClose() {
    setCloseMenuQrCode((oldValue) => !oldValue);
  }
  function SettingsClose() {
    setCloseSettingMenu((oldValue) => !oldValue);
  }
  function AddUserPopUp() {
    setCloseAddUserPopUp((oldValue) => !oldValue);
  }
  const openDownLoadCert = (isAddOption) => {
    setIsAddMoreOption(isAddOption);
    setOpenCert((oldValue) => !oldValue);
  };

  return (
    <div className={classes.Page}>
      {openCert && (
        <DownloadCertficates
          isAddMoreOption={isAddMoreOption}
          openDownLoadCert={openDownLoadCert}
        />
      )}
      {isLogout || isLoading ? <FullLoading /> : ""}
      <div className={closeMenu ? classes.menu : classes.hide}>
        <IconButton onClick={close} className={classes.closeMenu}>
          <CloseIcon className={classes.closecon} sx={{ color: "white" }} />
        </IconButton>
        <ul>
          <Link to='/Dashboard' onClick={close} className={classes.link}>
            <div>
              <li>Dashboard</li>
              <DashboardIcon />
            </div>
          </Link>
          <Link to='/Dashboard/add' onClick={close} className={classes.link}>
            <div>
              <li>Ajouter document</li>
              <CreateNewFolderIcon />
            </div>
          </Link>
          <Link
            to='/Dashboard/demands'
            onClick={close}
            className={classes.link}
          >
            <div>
              <li>Liste des demandes</li>
              <FactCheckIcon />
            </div>
          </Link>
          <Link to='/Dashboard/renew' onClick={close} className={classes.link}>
            <div>
              <li>Demander</li>
              <ShoppingCartIcon />
            </div>
          </Link>
          <Link
            to='/Dashboard/organisation'
            onClick={close}
            className={classes.link}
          >
            <div>
              <li>Info</li>
              <InfoIcon />
            </div>
          </Link>
          <Link to='/Dashboard/users' onClick={close} className={classes.link}>
            <div>
              <li>Liste des Utilisateur</li>
              <GroupIcon />
            </div>
          </Link>
        </ul>
      </div>
      <SideBar closeMenu={close} />
      <div className={classes.SecondPage}>
        <Switch>
          <Route exact path={"/Dashboard"}>
            <NavBar
              page={"Dashboard"}
              selectValue={setSelectValue}
              closeMenu={QrCodeClose}
              closeSettingMenu={SettingsClose}
              addUserPopUpFunc={AddUserPopUp}
            />
            <RenderPage
              openDownLoadCert={openDownLoadCert}
              selectValueToDashBoard={selectValueDashboard}
              closeMenu={QrCodeClose}
              closeMenuValue={closeMenuQrCode}
              closeSettingMenu={SettingsClose}
              closeSettingMenuValue={closeSettingMenu}
              addUserPopUpFunc={AddUserPopUp}
              addUserPopUpValue={closeAddUserPopUp}
            />
          </Route>

          <Route path={"/Dashboard/certificates/:certificatesId"}>
            <NavBar
              page={"Certificates"}
              selectValue={setSelectValue}
              closeMenu={QrCodeClose}
              closeSettingMenu={SettingsClose}
              addUserPopUpFunc={AddUserPopUp}
            />
            <ViewCert
              openDownLoadCert={openDownLoadCert}
              openCert={openCert}
              selectValueToDashBoard={selectValueDashboard}
              closeMenu={QrCodeClose}
              closeMenuValue={closeMenuQrCode}
              closeSettingMenu={SettingsClose}
              closeSettingMenuValue={closeSettingMenu}
              addUserPopUpFunc={AddUserPopUp}
              addUserPopUpValue={closeAddUserPopUp}
            />
          </Route>
          <Route path={"/Dashboard/edit/:certificatesId/"}>
            <NavBar
              page={"Modifier"}
              selectValue={setSelectValue}
              closeMenu={QrCodeClose}
              closeSettingMenu={SettingsClose}
              addUserPopUpFunc={AddUserPopUp}
            />
            <EditCertficates
              openDownLoadCert={openDownLoadCert}
              openCert={openCert}
              orgKey={selectValueDashboard}
              closeMenu={QrCodeClose}
              closeMenuValue={closeMenuQrCode}
              closeSettingMenu={SettingsClose}
              closeSettingMenuValue={closeSettingMenu}
              addUserPopUpFunc={AddUserPopUp}
              addUserPopUpValue={closeAddUserPopUp}
            />
          </Route>
          <Route path={"/Dashboard/add"}>
            <NavBar
              page={
                viewCertficate ? "Modifier Certificat" : "Ajouter Certificat"
              }
              selectValue={setSelectValue}
              closeMenu={QrCodeClose}
              closeSettingMenu={SettingsClose}
              addUserPopUpFunc={AddUserPopUp}
            />
            <RenderAdd
              openDownLoadCert={openDownLoadCert}
              openCert={openCert}
              isStepThreeNavBar={setViewCertficate}
              selectValueToAdd={selectValueDashboard}
              closeMenu={QrCodeClose}
              closeMenuValue={closeMenuQrCode}
              closeSettingMenu={SettingsClose}
              closeSettingMenuValue={closeSettingMenu}
              addUserPopUpFunc={AddUserPopUp}
              addUserPopUpValue={closeAddUserPopUp}
            />
          </Route>
          <Route path={"/Dashboard/demands"}>
            <NavBar
              page={"Liste des demandes"}
              selectValue={setSelectValue}
              closeMenu={QrCodeClose}
              closeSettingMenu={SettingsClose}
              addUserPopUpFunc={AddUserPopUp}
            />
            <RenderList
              selectValueToList={selectValueDashboard}
              closeMenu={QrCodeClose}
              closeMenuValue={closeMenuQrCode}
              closeSettingMenu={SettingsClose}
              closeSettingMenuValue={closeSettingMenu}
              addUserPopUpFunc={AddUserPopUp}
              addUserPopUpValue={closeAddUserPopUp}
            />
          </Route>
          <Route path={"/Dashboard/renew"}>
            <NavBar
              page={"Demander"}
              notPop={true}
              selectValue={setSelectValue}
              closeMenu={QrCodeClose}
              closeSettingMenu={SettingsClose}
              addUserPopUpFunc={AddUserPopUp}
            />
            <RenderDemander
              selectValueToOrder={selectValueDashboard}
              closeMenu={QrCodeClose}
              closeMenuValue={closeMenuQrCode}
              closeSettingMenu={SettingsClose}
              closeSettingMenuValue={closeSettingMenu}
              addUserPopUpFunc={AddUserPopUp}
              addUserPopUpValue={closeAddUserPopUp}
            />
          </Route>
          <Route path={"/Dashboard/organisation"}>
            <NavBar
              page={"Organisation"}
              selectValue={setSelectValue}
              closeMenu={QrCodeClose}
              closeSettingMenu={SettingsClose}
              addUserPopUpFunc={AddUserPopUp}
            />
            <RenderInfo
              selectValueToOrgInfo={selectValueDashboard}
              closeMenu={QrCodeClose}
              closeMenuValue={closeMenuQrCode}
              closeSettingMenu={SettingsClose}
              closeSettingMenuValue={closeSettingMenu}
              addUserPopUpFunc={AddUserPopUp}
              addUserPopUpValue={closeAddUserPopUp}
            />
          </Route>
          <Route exact path={"/Dashboard/users"}>
            <NavBar
              page={"Utilisateurs"}
              selectValue={setSelectValue}
              closeMenu={QrCodeClose}
              closeSettingMenu={SettingsClose}
              addUserPopUpFunc={AddUserPopUp}
            />
            <RenderUsers
              closeMenu={QrCodeClose}
              closeMenuValue={closeMenuQrCode}
              closeSettingMenu={SettingsClose}
              closeSettingMenuValue={closeSettingMenu}
              addUserPopUpFunc={AddUserPopUp}
              addUserPopUpValue={closeAddUserPopUp}
            />
          </Route>
          <Route path={"/Dashboard/users/:userId"}>
            <NavBar
              page={"Utilisateurs"}
              selectValue={setSelectValue}
              closeMenu={QrCodeClose}
              closeSettingMenu={SettingsClose}
              addUserPopUpFunc={AddUserPopUp}
            />
            <RenderProfile
              closeMenu={QrCodeClose}
              closeMenuValue={closeMenuQrCode}
              closeSettingMenu={SettingsClose}
              closeSettingMenuValue={closeSettingMenu}
              addUserPopUpFunc={AddUserPopUp}
              addUserPopUpValue={closeAddUserPopUp}
              setCloseSettingMenu={SettingsClose}
            />
          </Route>
          <Route path={"/Dashboard/profile"}>
            <NavBar
              page={"Profile"}
              selectValue={setSelectValue}
              closeMenu={QrCodeClose}
              closeSettingMenu={SettingsClose}
              addUserPopUpFunc={AddUserPopUp}
            />
            <RenderProfile
              closeMenu={QrCodeClose}
              closeMenuValue={closeMenuQrCode}
              closeSettingMenu={SettingsClose}
              closeSettingMenuValue={closeSettingMenu}
              addUserPopUpFunc={AddUserPopUp}
              addUserPopUpValue={closeAddUserPopUp}
              setCloseSettingMenu={SettingsClose}
            />
          </Route>
          <Route path={"*"}>
            <Page404 />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Dashboard;
