import React, { useState } from 'react'
import classes from './Steps.module.scss'
import MatrialUiBtn from '@mui/material/Button';
import Notify from '../../../elements/notification/Notify';
import AddUserPopUp from '../../../elements/addUserPopUp/AddUserPopUp';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import InputsAddCert from '../../../elements/addInputs/InputsAddCert';
import AT01ByBrenco from '../../../elements/AT01-template/AT01ByBrenco';
import { addCertficates, addImages, uploadImages } from '../../../store/certficate/certficateActions';
import { certificateActions } from '../../../store/certficate/certficateSlice';
import { toast } from 'react-toastify';
import { Input } from '@mui/material';

function StepTwoAddMore(props) {


    const orgInfo = useSelector(state => state.user.org)
    const selectedUserIds = useSelector(state => state.certificate.selectedUserIds)
    const certficate = useSelector(state => state.certificate.certificates)

    const dispatch = useDispatch()

  const orgKey = props.selectValueToAdd?.value

  const [selectedOption, setSelectedOption] = useState(null);
  
  props.selectedOption(selectedOption)

  const [data , setData] = useState({
    nom_formation:'',
    nom_formateur:'',
    date_fin:'',
    nom_incubateur:'',
    num_incubateur:'',
    date_debut:'',
    nom_evenement:"",
    date_evenement:'',
    lieu_evenement:'',
    nom_organisateur:'',
    nom_societe:'',
    num_employeur:'',
    email_societe:'',
    num_tel_societe:'',
    nom_labo:'',
    num_agreement:'',
    email_labo:'',
    num_tel_labo:'',
    nom_medecin:'',
    type_test:'',
    maladie_testee:'',
    heure_debut:'',
    date_adhesion:'',
    duree_months:'',
    duree_days:'',
    duree_hours:''
  })

  props.data(data)
  const [ExperyDate , setCheckHasExperyDate] = useState(false)

  props.ExperyDate(ExperyDate)

  const labelFunc = (orgKey)=>{

    switch (orgKey) {
      case 'AT01':
        return 'Attestation de formation';
        case 'AT02':
          return "Attestation d'incubation";
          case 'AT03':
            return 'Attestation de participation';
            case 'AT04':
              return 'Attestation de travail';
              case 'CR01':
                return "Compte Rendu d'Analyse Epidémiologique";
                case 'IN01':
                  return 'Invitation Evènement';
                  case 'CT01':
                  return "Attestation d'adhésion";
                  case 'AT05':
                  return "Certificat de Labélisation";
      default:
        break;
    }
  }

  const checkHasExperyDate = (e) => {
    setCheckHasExperyDate(e.target.checked)
  }

  const dataHendler = (e) => {
    const {name , value} = e.target
    setData(oldValue => {
        return {
            ...oldValue,
            [name]:value
        }
    })
  }

  const next = ()=>{
    dispatch(certificateActions.switchSteps(3))

    // window.location.href='www.google.com'
  }


  console.log(data );

  return (
    <div className={classes.add} >
    {props.closeSettingMenuValue && <Notify/>}
    {props.addUserPopUpValue &&  <AddUserPopUp  closeMenu={props.addUserPopUpFunc} />}
    
       <div className={classes.addBox} >
       <div className={classes.title} ><h3>Choisir le type du document </h3></div>

             <div className={classes.option} >
               <div className={classes.boxOne}>
                          <div className={classes.title} ><h3>1- Saisir les données du document</h3></div>
                          <div  className={classes.select} >
                          <Select  defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={orgInfo?.documentsType?.map(orgKey => ({value:orgKey , label:labelFunc(orgKey)}))} />
                          </div>
               </div>
               <div onChange={dataHendler} className={classes.boxInputs}>
                   {/*        AT01         */}
                { selectedOption?.value === 'AT01'  && <div>   <div  className={classes.select} >
                          <label> Nom de la formation </label>
                          <Input   name='nom_formation' value={data.nom}/>
                    </div>
                    <div  className={classes.select} >
                          <label> Nom du formateur </label>
                          <Input  name='nom_formateur' value={data.nom}/>
                    </div>
                    <div  className={classes.select} >
                          <label> Nom de l'organisme de formation </label>
                          <Input  name='nom_organisme' value={data.nom}/>
                    </div>
                    <div  className={classes.select} >
                          <label> Date de la fin de formation </label>
                          <Input  name='date_fin' type='date' value={data.nom}/>
                    </div>
                    <div  className={classes.select} >
                          <label>Durée de la formation</label>
                            <div  className={classes.Input} >
                                <label>Mois:</label>
                                <Input className={classes.inputDurée} name='duree_months' inputProps={{min:0 , max :12}} minRows={'0'} type='number' value={data.months} />
                                <label>Jours:</label>
                                <Input className={classes.inputDurée} name='duree_days' inputProps={{min:0 , max :31}} minRows={'0'} type='number' value={data.days } />
                                <label>Heures:</label>
                                <Input className={classes.inputDurée} name='duree_hours' inputProps={{min:0 , max :24}} min='0' type='number' value={data.hours } />
                            </div>
                    </div>
                     </div>}
                    {/*        AT02         */}
                   {selectedOption?.value === "AT02" && <> 
                        <div  className={classes.select} >
                          <label> Nom de l'incubateur</label>
                          <Input  name='nom_incubateur' value={data.nom}/>
                        </div> 
                        <div  className={classes.select} >
                          <label> Numéro du label de l'incubateur </label>
                          <Input  name='num_incubateur' value={data.nom}/>
                        </div> 
                        <div  className={classes.select} >
                          <label> Date du début de l'incubation </label>
                          <Input  name='date_debut' type='date' value={data.nom}/>
                    </div>
                    </> }
                    {selectedOption?.value === "AT03" && <> 
                        <div  className={classes.select} >
                          <label>Nom de l'evenement </label>
                          <Input  name='nom_evenement' value={data.nom}/>
                        </div> 
                        <div  className={classes.select} >
                          <label> Date de début l'evenement </label>
                          <Input  name='date_evenement' type='date' value={data.nom}/>
                        </div>
                        <div  className={classes.select} >
                          <label> Lieu de l'evenement </label>
                          <Input  name='lieu_evenement' value={data.nom}/>
                        </div> 
                        <div  className={classes.select} >
                          <label> Nom de l'organisateur </label>
                          <Input  name='nom_organisateur'  value={data.nom}/>
                        </div>
                    </> }

                    {selectedOption?.value === "AT04" && <> 
                        <div  className={classes.select} >
                          <label> Nom de la societé </label>
                          <Input  name='nom_societe' value={data.nom}/>
                        </div> 
                        <div  className={classes.select} >
                          <label>Numero CNAS de l'employeur</label>
                          <Input  name='num_employeur' value={data.nom}/>
                        </div>
                        <div  className={classes.select} >
                          <label>Email contact de la societé</label>
                          <Input  name='email_societe' value={data.nom}/>
                        </div> 
                        <div  className={classes.select} >
                          <label> Numéro de téléphone de la societé </label>
                          <Input  name='num_tel_societe' value={data.nom}/>
                        </div>
                    </> }
                 
                    {selectedOption?.value === "CR01" && <> 
                        <div  className={classes.select} >
                          <label> Nom du laboratoire </label>
                          <Input  name='nom_labo' value={data.nom}/>
                        </div> 
                        <div  className={classes.select} >
                          <label> Numéro de l'agréement du ministère de la santé</label>
                          <Input  name='num_agreement'  value={data.nom}/>
                        </div>
                        <div  className={classes.select} >
                          <label> Email contact du laboratoire </label>
                          <Input  name='email_labo' value={data.nom}/>
                        </div> 
                        <div  className={classes.select} >
                          <label> Numéro téléphone du laboratoire </label>
                          <Input  name='num_tel_labo'  value={data.nom}/>
                        </div>
                        <div  className={classes.select} >
                          <label> Nom du médecin traitant </label>
                          <Input  name='nom_medecin'  value={data.nom}/>
                        </div>
                        <div  className={classes.select} >
                          <label> Type du test </label>
                          <Input  name='type_test'  value={data.nom}/>
                        </div>
                        <div  className={classes.select} >
                          <label> Maladie testee Epidémiologique </label>
                          <Input  name='maladie_testee'  value={data.nom}/>
                        </div>
                    </> }
                    {selectedOption?.value === "IN01" && <> 
                        <div  className={classes.select} >
                          <label> Nom de l'evenement </label>
                          <Input  name='nom_evenement' value={data.nom}/>
                        </div> 
                        <div  className={classes.select} >
                          <label> Date de l'evenement</label>
                          <Input  name='date_evenement' type='date' value={data.nom}/>
                        </div>
                        <div  className={classes.select} >
                          <label>Lieu de l'evenement</label>
                          <Input  name='lieu_evenement' value={data.nom}/>
                        </div> 
                        <div  className={classes.select} >
                          <label> Heure de début de l'evenement </label>
                          <Input  name='heure_debut' type='time' value={data.nom}/>
                        </div>
                        <div  className={classes.select} >
                          <label> Nom de l'organisateur </label>
                          <Input  name='nom_organisateur'  value={data.nom}/>
                        </div>
                    </> }
                   
                    {selectedOption?.value === "CT01" && <> 
                        <div  className={classes.select} >
                          <label> Date d'adhésion </label>
                          <Input  name='date_adhesion' type='date' value={data.nom}/>
                        </div> 
                    </> }
                    {selectedOption?.value  && <div  className={classes.select} >
                          <label> Date de fin de validité de document</label>
                          <Input type='date' name='expiryDate' value={data.expiryDate} />
                          <div className={classes.checkBox}>
                            <input onChange={checkHasExperyDate} checked={ExperyDate ? true : false} type={'checkbox'}/>
                            <label>Ce document n'a pas de date de fin de validité</label>
                          </div>
                    </div>}
               </div>
               

             </div>
         
             <div className={classes.button} >
             <MatrialUiBtn onClick={next}  className={classes.MatrialUiBtnbutton} >Suivant</MatrialUiBtn>
             </div>
       </div>
   </div>
  )
}

export default StepTwoAddMore