// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadRecu_upload__3sC5m {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .uploadRecu_upload__3sC5m .uploadRecu_btnUpload__MXaHw {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 35px;
    border-radius: 30px;
    background-color: #0071BC;
    color: white;
    font-size: 1rem; }
  .uploadRecu_upload__3sC5m .uploadRecu_btnUpload__MXaHw:hover {
    background-color: #0071bca2; }
`, "",{"version":3,"sources":["webpack://./src/elements/uploadRecu/uploadRecu.module.scss"],"names":[],"mappings":"AAAA;EAEI,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAA;EAN3B;IAQQ,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,eAAe,EAAA;EAjBvB;IAoBQ,2BAA2B,EAAA","sourcesContent":[".upload{\n \n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    .btnUpload{\n        cursor: pointer;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        width: 30%;\n        height: 35px;\n        border-radius: 30px;\n        background-color: #0071BC;\n        color: white;\n        font-size: 1rem;\n    }\n    .btnUpload:hover{\n        background-color: #0071bca2;\n    }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload": `uploadRecu_upload__3sC5m`,
	"btnUpload": `uploadRecu_btnUpload__MXaHw`
};
export default ___CSS_LOADER_EXPORT___;
